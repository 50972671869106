import { atom } from "recoil";
import { ActActiveUsers } from "../generated/graphql";

export const currentActivityActiveUserAtom = atom<ActActiveUsers | undefined>({
	key: "currentActivityActiveUser",
	default: undefined,
});

export const otherActivityActiveUserAtom = atom<ActActiveUsers[]>({
	key: "otherActivityActiveUser",
	default: [],
});
