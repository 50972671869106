import Env from "./types/Env";
import dotenv from "dotenv";
import path from "path";

dotenv.config({
	path: path.resolve(__dirname, "../.env"),
});

export enum EnvTypes {
	Production = "production",
	Staging = "staging",
	Development = "development",
}

let env: Env;

const init = () => {
	if (env) return;
	env = {} as any;

	switch (process.env.REACT_APP_ENV_TYPE) {
		case EnvTypes.Production:
			env.envType = EnvTypes.Production;
			break;
		case EnvTypes.Staging:
			env.envType = EnvTypes.Staging;
			break;
		case EnvTypes.Development:
			env.envType = EnvTypes.Development;
			break;
		default: {
			console.error("Invalid REACT_APP_ENV_TYPE value");
		}
	}

	const baseUrl = process.env.REACT_APP_BASE_URL;
	env.restAPIUrl = baseUrl + "/v1";
	env.graphQLUrl = baseUrl + "/graphql";
};

init();

export default env!;
