import FIFO from "fifo";
import { useRef, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { useRecoilState } from "recoil";
import {
	AddOrUpdateBidSheetProductMutationVariables,
	BidSheetProductInput,
	useAddOrUpdateBidSheetProductMutation,
} from "../../../generated/graphql";
import { bidSheetLastUpdatedAtom } from "../../../state/bidSheet.Recoil";

const useSaveBidSheetProductQueue = () => {
	const [isSaving, setIsSaving] = useState(false);
	const [addOrUpdateBidSheetProduct] = useAddOrUpdateBidSheetProductMutation();
	const [bidSheetStatus, setBidSheetStatus] = useRecoilState(bidSheetLastUpdatedAtom);

	const hadBeenCalled = useRef(false);
	const isQueueSaving = useRef(false);
	const saveQueue = useRef(new FIFO<AddOrUpdateBidSheetProductMutationVariables>());

	const _addOrUpdateBidSheetProduct = (variables: AddOrUpdateBidSheetProductMutationVariables) => {
		addOrUpdateBidSheetProduct({
			variables,
		})
			.then(() => {
				if (saveQueue.current.length !== 0) {
					// the library FIFO is bugged, it returns value when shifting instead of node
					_addOrUpdateBidSheetProduct(
						saveQueue.current.shift()! as unknown as AddOrUpdateBidSheetProductMutationVariables,
					);
				} else {
					setIsSaving(false);
					isQueueSaving.current = false;
				}
			})
			.catch((err: string) => {
				// try to save again
				if (String(err).includes("Failed to fetch")) {
					setBidSheetStatus({
						...bidSheetStatus,
						isUpdating: false,
						errorMsg: (
							<span>
								Failed to fetch{" "}
								<span
									className="cursor-pointer text-primary"
									onClick={() => {
										setBidSheetStatus({
											...bidSheetStatus,
											isUpdating: true,
											errorMsg: undefined,
										});
										_addOrUpdateBidSheetProduct(variables);
									}}
								>
									TRY AGAIN <AiOutlineReload className="h-5 w-5 inline-block" />
								</span>
							</span>
						),
					});
				} else {
					setBidSheetStatus({
						...bidSheetStatus,
						isUpdating: false,
						errorMsg: <>Unknown Error.</>,
					});
				}
				// _addOrUpdateBidSheetProduct(variables);
			});
	};

	const saveBidSheetProduct = (
		activityProductId: number,
		bidSheetProductInput: BidSheetProductInput,
	) => {
		hadBeenCalled.current = true;

		const variables = {
			activityProductId,
			bidSheetProductInput,
		};

		if (!isQueueSaving.current) {
			setIsSaving(true);
			isQueueSaving.current = true;

			_addOrUpdateBidSheetProduct(variables);
		} else {
			saveQueue.current.push(variables);
		}
	};

	return {
		saveBidSheetProduct,
		saveBidSheetProductRes: {
			hadBeenCalled: hadBeenCalled.current,
			isSaving,
		},
	};
};

export default useSaveBidSheetProductQueue;
