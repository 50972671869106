import { OrderBy } from "../generated/graphql";

export const pushToOrderBys = (orderBys: OrderBy[], by: string, isDesc: boolean): OrderBy[] => {
	const i = orderBys.findIndex((orderBy) => orderBy.by === by);
	const orderBysOut = [
		{
			by,
			isDesc,
		},
		...orderBys,
	];
	if (i !== -1) {
		orderBysOut.splice(i + 1, 1);
	}

	return orderBysOut;
};
