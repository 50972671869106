import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import React, { FormEvent, useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useUpdateActivityMutation } from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { modalRenderAtom } from "../../state/modal.recoil";

interface Columns {
	tier: boolean;
	cat: boolean;
	sub: boolean;
	type: boolean;
	milMicGauge: boolean;
	newCatColor: boolean;
	total: boolean;
	east: boolean;
	west: boolean;

	netDeliveredCaseWest: boolean;
	netDeliveredCaseEast: boolean;
	manufName: boolean;
	equivMfrNumber: boolean;
	bidTier: boolean;
	manufacturerProductDescription: boolean;
	brand: boolean;
	gtinNumber: boolean;
	material: boolean;
	gauge: boolean;
	rollOrFlatPack: boolean;
	packSize: boolean;
	isSepticSafe: boolean;
	numberOfSheetsPerCase: boolean;
	linearFtPerCase: boolean;
	netWeight: boolean;
	other: boolean;
	willProduceInCompanions: boolean;
	notes: boolean;
}

type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
};

const ActivityHiddenColumnsForm = () => {
	const [{ isStatusMessageError, statusMessage }, setState] = useStates<State>({
		isStatusMessageError: false,
	});
	const [columns, setColumns] = useState<Columns>({
		tier: false,
		cat: false,
		sub: false,
		type: false,
		milMicGauge: false,
		newCatColor: false,
		total: false,
		east: false,
		west: false,
		netDeliveredCaseWest: false,
		netDeliveredCaseEast: false,
		manufName: false,
		equivMfrNumber: false,
		bidTier: false,
		manufacturerProductDescription: false,
		brand: false,
		gtinNumber: false,
		material: false,
		gauge: false,
		rollOrFlatPack: false,
		packSize: false,
		isSepticSafe: false,
		numberOfSheetsPerCase: false,
		linearFtPerCase: false,
		netWeight: false,
		other: false,
		willProduceInCompanions: false,
		notes: false,
	});
	const columnLabels = {
		tier: "Tier",
		cat: "Cat",
		sub: "Sub",
		type: "Type",
		milMicGauge: "Mil/Mic/Gauge",
		newCatColor: "Color",
		total: "Total",
		east: "East",
		west: "West",
		netDeliveredCaseWest: "Net Delivered Case West",
		netDeliveredCaseEast: "Net Delivered Case East",
		manufName: "Manufacturer Namer",
		equivMfrNumber: "Equiv MFR Number",
		bidTier: "Bid Tier",
		manufacturerProductDescription: "Manucaturer Product Description",
		brand: "Brand",
		gtinNumber: "GTIN Number",
		material: "Material",
		gauge: "Gauge",
		rollOrFlatPack: "Roll or Flat Pack",
		packSize: "Pack Size",
		isSepticSafe: "Is Septic Safe",
		numberOfSheetsPerCase: "Number of Sheets Per Case",
		linearFtPerCase: "Linear Ft Per Case",
		netWeight: "Net Weight",
		other: "Other",
		willProduceInCompanions: "Will Produce in Companions",
		notes: "Notes",
	};
	const [activity, setActivity] = useRecoilState(activityAtom);
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);

	const [updateActivityProduct] = useUpdateActivityMutation();
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const submit = (e: FormEvent) => {
		e.preventDefault();

		setState({
			isStatusMessageError: false,
			statusMessage: undefined,
		});

		let hiddenColumns = "";
		let hiddenColumnsArr: any = [];

		Object.keys(columns).forEach((key) => {
			const col = key as keyof Columns;
			if (columns[col] === true) {
				hiddenColumnsArr.push(col);
			}
		});

		hiddenColumns = hiddenColumnsArr.join(",");

		setLoadingMessage("Updating Activity");
		if (activity) {
			updateActivityProduct({
				variables: {
					activityId: activity.id,
					activityInput: {
						name: activity.name,
						expiresAt: activity.expiresAt,
						hiddenColumns,
					},
				},
			})
				.then(() => {
					setActivity({ ...activity!, hiddenColumns });
					setState({
						isStatusMessageError: false,
						statusMessage: "Product updated successfully",
					});
					setTimeout(() => {
						setModalRender(undefined);
					}, 1000);
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to update product",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	useEffect(() => {
		if (activity && activity.hiddenColumns) {
			const hiddenColsArr = activity.hiddenColumns.split(",");
			const newCols = { ...columns };

			hiddenColsArr.forEach((key) => {
				const col = key as keyof Columns;
				newCols[col] = true;
			});
			setColumns(newCols);
		}
	}, []);

	return (
		<div>
			<div className="my-5 font-bold">
				Select Columns to <span className="text-primary">HIDE</span> from this Activity
			</div>
			<form className="flex flex-col" onSubmit={(e) => submit(e)}>
				{Object.keys(columns).map((key, i) => {
					const col = key as keyof Columns;
					return (
						<label
							className="flex justify-between items-center px-1 rounded cursor-pointer hover:bg-gray-200 "
							key={i}
						>
							<span>{columnLabels[col]}</span>
							<input
								onChange={(e) => {
									const newCols = { ...columns };
									newCols[col] = e.currentTarget.checked;
									setColumns(newCols);
								}}
								type="checkbox"
								checked={columns[col]}
								className="focus:ring-transparent focus:ring-offset-transparent h-4 w-4 text-primary border-gray-300 rounded ml-2"
							/>
						</label>
					);
				})}

				{/* Status messages */}
				{statusMessage &&
					(isStatusMessageError ? (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					) : (
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
							<CheckCircleIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					))}

				<button
					className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center mt-10 mb-5"
					type="submit"
				>
					Apply
				</button>
			</form>
		</div>
	);
};

export default ActivityHiddenColumnsForm;
