import { ChartBarIcon, CogIcon, CubeIcon, EyeIcon, TableIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { UserTypeNames } from "../../generated/graphql";
import { userAtom } from "../../state/user.recoil";
import CreateSurvey from "./CreateSurvey";
import ResultSurvey from "./ResultSurvey";
import { activityAtom } from "../../state/activityRecoil";

export enum SurveyTabsNames {
	Setup = "setup",
	Preview = "preview",
	Results = "results",
}

const SurveyTabs = () => {
	//state hooks
	const user = useRecoilValue(userAtom)!;
	const activity = useRecoilValue(activityAtom);
	const isAdmin = user!.typeUserType.name === UserTypeNames.Admin;
	const [tabSelected, setTabSelected] = useState(
		!isAdmin
			? SurveyTabsNames.Preview
			: activity?.activeAt
			? SurveyTabsNames.Results
			: SurveyTabsNames.Setup,
	);

	const tabClasses = (tab: SurveyTabsNames) => {
		let tabClasses = " tab ";
		if (tabSelected === tab) {
			tabClasses += " true ";
		}
		if (isAdmin && activity?.activeAt) {
			if (tab === SurveyTabsNames.Setup) {
				tabClasses += " disabled ";
			}
			if (tab === SurveyTabsNames.Preview) {
				tabClasses += " disabled ";
			}
		}
		return tabClasses;
	};

	return (
		<div className={tabSelected + " container mt-9"}>
			{isAdmin && (
				<div className="flex border-b border-gray-300 mb-10">
					<div
						className={tabClasses(SurveyTabsNames.Setup)}
						onClick={() => setTabSelected(SurveyTabsNames.Setup)}
					>
						<CogIcon className="w-5 h-5 mr-1" /> Setup
					</div>
					<div
						className={tabClasses(SurveyTabsNames.Preview)}
						onClick={() => setTabSelected(SurveyTabsNames.Preview)}
					>
						<EyeIcon className="w-5 h-5 mr-1" /> Preview
					</div>

					<div
						className={tabClasses(SurveyTabsNames.Results)}
						onClick={() => setTabSelected(SurveyTabsNames.Results)}
					>
						<ChartBarIcon className="w-5 h-5 mr-1" /> Results
					</div>
				</div>
			)}
			{tabSelected === SurveyTabsNames.Setup ? (
				<CreateSurvey isPreview={tabSelected} />
			) : tabSelected === SurveyTabsNames.Preview ? (
				<CreateSurvey isPreview={tabSelected} />
			) : (
				<ResultSurvey />
			)}
		</div>
	);
};

export default SurveyTabs;
