import { useReducer } from "react";

export const useFormState = <T>(defaultState: T): [T, React.Dispatch<Partial<T>>, () => void] => {
	const reducer = (prev: T, next: Partial<T>) => ({ ...prev, ...next });
	const [state, setState] = useReducer(reducer, defaultState);

	const resetState = () => {
		setState(defaultState);
	};

	return [state, setState, resetState];
};
