import { FormEvent } from "react";
import { Link, Redirect } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PathName } from "../../constants/PathName";
import { LoginCredentials } from "../../generated/graphql";
import useLogin from "../../hooks/useLogin";
import { useFormState } from "../../hooks/useFormState";
import { isLoggedInSelector, userAtom } from "../../state/user.recoil";
import { MailIcon, LockClosedIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/outline";
import useLoginWithLocalToken from "../../hooks/useLoginWithLocalToken";
import { UserTypeNames } from "../../generated/graphql";
import { LocalStorageKey } from "../../constants/LocalStorageKey";

type FormState = LoginCredentials;

const Login: React.FC = () => {
	const user = useRecoilValue(userAtom);
	const isLoggedIn = useRecoilValue(isLoggedInSelector);
	const [formState, setFormState] = useFormState<FormState>({
		username: "",
		password: "",
	});

	const { login, isActuallyLoggingIn, loginError } = useLogin();

	const isLoggingInWithLocalToken = useLoginWithLocalToken(isActuallyLoggingIn);

	const submit = (e: FormEvent) => {
		e.preventDefault();
		localStorage.removeItem(LocalStorageKey.TOKEN); // prevent sending expired token
		login({
			variables: {
				loginCredentials: formState,
			},
		}).catch(() => {});
	};
	const errorMsg = "Username or Password is incorrect";

	if (isLoggingInWithLocalToken) {
		return <div></div>;
	} else if (isLoggedIn) {
		if (user!.typeUserType.name === UserTypeNames.CompanyUser) {
			return <Redirect to={PathName.ACTIVITIES} />;
		} else {
			return <Redirect to={PathName.ADMIN_DASHBOARD} />;
		}
	} else {
		return (
			<div className="container flex flex-col mx-auto items-center">
				<div className="flex w-full max-w-lg my-14 gap-5">
					<div className="w-full">
						<img src="/img/unipro.svg" alt="" />
					</div>
					<div className="w-full">
						<img src="/img/tcm.jpg" alt="" />
					</div>
				</div>

				<div className="flex flex-col p-4 border border-gray-400 rounded max-w-md mx-auto w-full">
					<form className="flex flex-col" onSubmit={(e) => submit(e)}>
						<label className="text-gray-400">Username </label>
						<div className="relative w-full mb-3">
							<input
								className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-full"
								onChange={(e) => setFormState({ username: e.target.value })}
								value={formState.username}
							></input>
							<MailIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
						</div>

						<label className="text-gray-400">Password </label>
						<div className="relative w-full mb-3">
							<input
								className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-full"
								onChange={(e) => setFormState({ password: e.target.value })}
								value={formState.password}
								type="password"
							></input>
							<LockClosedIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
						</div>

						{loginError && (
							<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
								<ExclamationIcon className="h-5 w-5" />
								{errorMsg}
							</div>
						)}

						<Link
							to={PathName.FORGOT_PASSWORD}
							className="flex text-primary font-bold self-start items-center"
						>
							Forgot Password?
						</Link>
						<button
							className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center"
							type="submit"
						>
							{isActuallyLoggingIn && (
								<div className="rounded-2xl border-t-4 border-b-4 border-white animate-spin h-5 w-5 mr-3"></div>
							)}
							Login
						</button>
					</form>
				</div>
			</div>
		);
	}
};

export default Login;
