import axios from "axios";
import { LocalStorageKey } from "../constants/LocalStorageKey";

const useAuthAxios = () => {
	const token = localStorage.getItem(LocalStorageKey.TOKEN);

	const authAxios = axios.create();
	authAxios.defaults.headers["Authorization"] = token ? `Bearer ${token}` : "";

	return authAxios;
};

export default useAuthAxios;
