import React, { createRef, useEffect } from "react";
import ReactDataSheet from "react-datasheet";
import { useStates } from "../../../hooks/useStates";
import "react-datasheet/lib/react-datasheet.css";
import {
	BidSheetProductInput,
	useGetBidSheetQuery,
	UserTypeNames,
} from "../../../generated/graphql";
import { useRecoilState, useRecoilValue } from "recoil";
import { activityAtom } from "../../../state/activityRecoil";
import "./spreadsheet.scss";
import { bidSheetCountAtom, bidSheetLastUpdatedAtom } from "../../../state/bidSheet.Recoil";
import {
	ACTIVITY_PRODUCT_COLUMNS,
	BID_SHEET_PRODUCT_COLUMNS,
	Cell,
	HEADER_ROW,
	SUB_HEADER_ROW,
} from "../BidsheetGridSetup";
import { isNumber } from "../../../utils/utils";
import { userAtom } from "../../../state/user.recoil";
import useSaveBidSheetProductQueue from "./useSaveBidSheetProductQueue";

type State = {
	grid: Cell[][];
	isScrolling: "left" | "right" | null;
};

const Spreadsheet = () => {
	//state hooks
	const [state, setState] = useStates<State>({
		grid: [],
		isScrolling: null,
	});

	//recoils
	const activity = useRecoilValue(activityAtom);
	const user = useRecoilValue(userAtom);
	const isAdmin = user!.typeUserType.name === UserTypeNames.Admin;
	const [bidSheetStatus, setBidSheetStatus] = useRecoilState(bidSheetLastUpdatedAtom);
	const [bidSheetCount, setBidSheetCount] = useRecoilState(bidSheetCountAtom);

	//custom hooks
	const { saveBidSheetProduct, saveBidSheetProductRes } = useSaveBidSheetProductQueue();

	const getBidSheetRes = useGetBidSheetQuery({
		variables: !isAdmin
			? {
					activityId: activity!.id,
					companyId: user!.userCompanyUser!.company.id,
			  }
			: undefined,
		fetchPolicy: "network-only",
		skip: isAdmin,
	});

	//local variables
	let interval: any = undefined;
	const gridParentRef = createRef<HTMLDivElement>();
	const hiddenColsArr = (activity?.hiddenColumns && activity?.hiddenColumns.split(",")) || [];

	//functions
	const toCellValue = (value: any) => {
		const type = typeof value;
		if (type === "boolean") {
			return value ? "Y" : "N";
		}

		return value;
	};

	const getCellStatus = (cell: Cell) => {
		if (cell.value === undefined || cell.value === null || cell.value === "") {
			if (cell.isRequired) {
				return "warning";
			}
			return "valid";
		}
		if (
			cell.type === "string" &&
			cell.strLength &&
			cell.value !== null &&
			cell.value !== undefined &&
			cell.value.length > cell.strLength
		) {
			return "error";
		}
		if (cell.type === "boolean" && cell.value !== "Y" && cell.value !== "N") {
			return "error";
		}
		if (cell.type === "number" && !isNumber(cell.value)) {
			return "error";
		}
		if (
			cell.colName === "TIER" &&
			cell.value !== "good" &&
			cell.value !== "better" &&
			cell.value !== "best"
		) {
			return "error";
		}

		return "valid";
	};

	const processValue = (cell: Cell, row: number, col: number) => {
		if (row === 0 || row === 1) {
			return cell.value;
		}

		if (cell.format) {
			return cell.format!(cell.value);
		}

		return cell.value;
	};

	const toDataNumberValue = (val: any) => {
		if (isNumber(val)) {
			return Number(val);
		}
		return null;
	};

	const toDataBooleanValue = (val: any) => {
		return val === "Y" ? true : val === "N" ? false : null;
	};

	const toDataTierValidation = (val: any) => {
		return val !== "good" && val !== "better" && val !== "best" ? null : val;
	};

	const getValueWithField = (field: string, rowCell: Cell[]) => {
		const res = rowCell.filter((rc) => rc.field === field);
		if (res.length > 0) {
			return res[0].value;
		}
		return "";
	};

	const updateBidSheetProducts = async (grid: Cell[][], addOrUpdateRowIs: Set<number>) => {
		for (const rowI of addOrUpdateRowIs) {
			//shift from the left side
			const rowCells = grid[rowI];

			//should be already validated by type, safe to cast
			const bidSheetProduct: BidSheetProductInput = {
				netDeliveredCaseWest: toDataNumberValue(
					getValueWithField("netDeliveredCaseWest", rowCells),
				),
				netDeliveredCaseEast: toDataNumberValue(
					getValueWithField("netDeliveredCaseEast", rowCells),
				),
				manufName: getValueWithField("manufName", rowCells),
				equivMfrNumber: getValueWithField("equivMfrNumber", rowCells),
				tier: toDataTierValidation(getValueWithField("bidTier", rowCells)),
				manufacturerProductDescription: getValueWithField(
					"manufacturerProductDescription",
					rowCells,
				),
				brand: getValueWithField("brand", rowCells),
				gtinNumber: getValueWithField("gtinNumber", rowCells),
				material: getValueWithField("material", rowCells),
				gauge: getValueWithField("gauge", rowCells),
				rollOrFlatPack: getValueWithField("rollOrFlatPack", rowCells),
				packSize: getValueWithField("packSize", rowCells),
				isSepticSafe: toDataBooleanValue(getValueWithField("isSepticSafe", rowCells)),
				numberOfSheetsPerCase: toDataNumberValue(
					getValueWithField("numberOfSheetsPerCase", rowCells),
				),
				linearFtPerCase: toDataNumberValue(getValueWithField("linearFtPerCase", rowCells)),
				netWeight: toDataNumberValue(getValueWithField("netWeight", rowCells)),
				other: getValueWithField("other", rowCells),
				willProduceInCompanions: toDataBooleanValue(
					getValueWithField("willProduceInCompanions", rowCells),
				),
				notes: getValueWithField("notes", rowCells),
			};

			saveBidSheetProduct(rowCells[rowCells.length - 1].activityProductId!, bidSheetProduct);
		}
	};

	const parsePaste = (str: string) => {
		if (str.includes("\r")) {
			return str
				.replace(new RegExp("\\r\\n$"), "")
				.split("\r\n")
				.map((el) => el.split("\t"));
		} else {
			return str
				.replace(new RegExp("\\n$"), "")
				.split("\n")
				.map((el) => el.split("\t"));
		}
	};

	const countProgress = (grid: Cell[][]) => {
		let warningCount = 0;
		let errorCount = 0;
		let itemCount = 0;

		if (grid.length > 0) {
			//get value columns, count activity side
			const activitiesCols = grid[2].filter((col) => col.className.includes("activity-title"));
			//loop rows, skip header, first 2
			for (let i = 2; i < grid.length; ++i) {
				//loop cols, skip activitySide
				for (let j = activitiesCols.length; j < grid[i].length; ++j) {
					if (grid[i][j].isRequired) {
						itemCount++;
					}
					if (grid[i][j].className.includes("warning")) {
						warningCount++;
					}
					if (grid[i][j].className.includes("error")) {
						errorCount++;
					}
				}
			}
		}

		const percent = (itemCount - warningCount - errorCount) / itemCount;
		const completedPercent = percent.toLocaleString("en", { style: "percent" });

		return { errorCount, warningCount, completedPercent };
	};

	const scrollGrid = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		//holdingbutton
		if (e.buttons === 1) {
			const stepSize = 10;
			const areaWidth = 150;
			//define boundaries
			//left side
			if (!e.currentTarget.offsetParent) {
				return;
			}
			const parent = e.currentTarget.offsetParent as HTMLDivElement;
			const left1 = parent.offsetLeft;
			const left2 = parent.offsetLeft + areaWidth;
			//right side
			const right1 = parent.offsetLeft + parent.clientWidth - areaWidth;
			const right2 = parent.offsetLeft + parent.clientWidth;

			//check mouse position horizontally, no need for vertical since it shoul be inside the component
			if (e.clientX > left1 && e.clientX < left2 && interval === undefined) {
				//move left
				interval = setInterval(() => {
					if (gridParentRef && gridParentRef.current) {
						gridParentRef.current!.scrollLeft -= stepSize;
					}
				}, 25);
			}
			if (e.clientX > right1 && e.clientX < right2 && interval === undefined) {
				//move right
				interval = setInterval(() => {
					if (gridParentRef && gridParentRef.current) {
						gridParentRef.current!.scrollLeft += stepSize;
					}
				}, 25);
			}
			if (e.clientX > left2 && e.clientX < right1 && interval !== undefined) {
				//if not on edges but still selecting stop moving
				clearInterval(interval);
				interval = undefined;
			}
		}
	};

	const stopScroll = () => {
		clearInterval(interval);
		interval = undefined;
	};

	//React hooks
	useEffect(() => {
		const grid: Cell[][] = [];
		// const headerRow: any = [];

		//add header row
		grid.push(HEADER_ROW(activity?.hiddenColumns!), SUB_HEADER_ROW(activity?.hiddenColumns!));

		//add data row for each data
		if (!isAdmin && getBidSheetRes.data) {
			//check if activity product exist
			if (getBidSheetRes.data.getActivityProducts.nodes.length > 0) {
				//get number of rows to create
				const rowsCount = getBidSheetRes.data.getActivityProducts.nodes.length;
				for (let i = 0; i < rowsCount; i++) {
					const fullRow: Cell[] = [];
					//create new array of cell in proper order
					const activityProduct = getBidSheetRes.data.getActivityProducts.nodes[i];
					const activityRow = [
						activityProduct.rfpItemNumber,
						activityProduct.tier,
						activityProduct.cat,
						activityProduct.sub,
						activityProduct.type,
						activityProduct.milMicGauge,
						activityProduct.newCatColor,
						activityProduct.total,
						activityProduct.east,
						activityProduct.west,
					];
					activityRow.forEach((val, k) => {
						if (
							!(
								hiddenColsArr.length > 0 &&
								hiddenColsArr.includes(ACTIVITY_PRODUCT_COLUMNS[k].field)
							)
						) {
							fullRow.push({
								value: toCellValue(val),
								readOnly: true,
								className: "activity-data " + ACTIVITY_PRODUCT_COLUMNS[k].type,
								type: ACTIVITY_PRODUCT_COLUMNS[k].type,
								field: ACTIVITY_PRODUCT_COLUMNS[k].field,
								format: ACTIVITY_PRODUCT_COLUMNS[k].format,
							});
						}
					});

					//create right side of the row and push
					//if has row data on this level of activity row
					const bidSheetProduct = getBidSheetRes.data.getBidSheetProducts.find(
						(el) => el.activityProductId === activityProduct.id,
					);
					if (bidSheetProduct) {
						const bidSheetRow = [
							bidSheetProduct.netDeliveredCaseWest,
							bidSheetProduct.netDeliveredCaseEast,
							bidSheetProduct.manufName,
							bidSheetProduct.equivMfrNumber,
							bidSheetProduct.tier,
							bidSheetProduct.manufacturerProductDescription,
							bidSheetProduct.brand,
							bidSheetProduct.gtinNumber,
							bidSheetProduct.material,
							bidSheetProduct.gauge,
							bidSheetProduct.rollOrFlatPack,
							bidSheetProduct.packSize,
							bidSheetProduct.isSepticSafe,
							bidSheetProduct.numberOfSheetsPerCase,
							bidSheetProduct.linearFtPerCase,
							bidSheetProduct.netWeight,
							bidSheetProduct.other,
							bidSheetProduct.willProduceInCompanions,
							bidSheetProduct.notes,
						];
						bidSheetRow.forEach((val, i) => {
							const value = toCellValue(val);
							const { type, isRequired, strLength, dataEditor } = BID_SHEET_PRODUCT_COLUMNS[i];
							const cell: Cell = {
								value,
								className: "bidsheet-data " + type + " ",
								type,
								activityProductId: activityProduct.id,
								bidSheetProductId: bidSheetProduct.id,
								isRequired,
								strLength,
								dataEditor,
								colName: BID_SHEET_PRODUCT_COLUMNS[i].value,
								field: BID_SHEET_PRODUCT_COLUMNS[i].field,
								format: BID_SHEET_PRODUCT_COLUMNS[i].format,
							};
							cell.className += getCellStatus(cell);

							if (
								!(
									hiddenColsArr.length > 0 &&
									hiddenColsArr.includes(BID_SHEET_PRODUCT_COLUMNS[i].field)
								)
							) {
								fullRow.push(cell);
							}
						});
					} else {
						BID_SHEET_PRODUCT_COLUMNS.forEach((col) => {
							const cell: Cell = {
								value: "",
								className: "bidsheet-data " + col.type + " ",
								type: col.type,
								activityProductId: activityProduct.id,
								isRequired: col.isRequired,
								strLength: col.strLength,
								dataEditor: col.dataEditor,
								colName: col.value,
								field: col.field,
								format: col.format,
							};
							cell.className += getCellStatus(cell);
							if (!(hiddenColsArr.length > 0 && hiddenColsArr.includes(col.field))) {
								fullRow.push(cell);
							}
						});
					}

					//finally push full row to grid
					grid.push(fullRow);
				}
				setState({ grid });
			}
		}
	}, [getBidSheetRes.data, isAdmin, setState]);

	useEffect(() => {
		const { errorCount, warningCount, completedPercent } = countProgress(state.grid);
		if (
			state.grid.length > 0 &&
			(bidSheetCount.errorCount !== errorCount ||
				bidSheetCount.warningCount !== warningCount ||
				bidSheetCount.completedPercent !== completedPercent)
		) {
			setBidSheetCount({
				...countProgress(state.grid),
			});
		}
	}, [bidSheetCount, setBidSheetCount, state.grid]);

	useEffect(() => {
		if (saveBidSheetProductRes.hadBeenCalled) {
			if (saveBidSheetProductRes.isSaving) {
				setBidSheetStatus({ ...bidSheetStatus, isUpdating: true });
			} else {
				setBidSheetStatus({
					...bidSheetStatus,
					isUpdating: false,
					lastUpdatedDate: new Date(),
				});
			}
		}
	}, [saveBidSheetProductRes.isSaving, setBidSheetStatus]);

	//jsx variables

	return (
		<div
			className="w-full overflow-x-auto"
			id="grid-parent"
			onMouseOver={scrollGrid}
			onMouseUp={stopScroll}
			onMouseLeave={stopScroll}
			onMouseOut={stopScroll}
			ref={gridParentRef}
		>
			<ReactDataSheet
				data={state.grid}
				valueRenderer={processValue}
				dataRenderer={(cell: Cell) => cell.value}
				onCellsChanged={(changes) => {
					const grid = state.grid.map((row: Cell[]) => [...row]);
					const addOrUpdateRowIs = new Set<number>();

					changes.forEach(({ cell, row, col, value }) => {
						if (cell && !cell.readOnly) {
							const newCell = { ...cell, value };

							const cellStatus = getCellStatus(newCell);

							grid[row][col] = {
								...newCell,
								className: "bidsheet-data " + cellStatus + " " + cell.type,
							};
						}

						addOrUpdateRowIs.add(row);
					});

					updateBidSheetProducts(grid, addOrUpdateRowIs);

					setState({ grid });
				}}
				className={"data-sheet"}
				parsePaste={parsePaste}
			/>
		</div>
	);
};

export default Spreadsheet;
