import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { LocalStorageKey } from "../constants/LocalStorageKey";
import { useGetUserQuery } from "../generated/graphql";
import { userAtom } from "../state/user.recoil";

export const useLoginWithLocalToken = (shouldSkip: boolean): boolean => {
	const hasToken = !!localStorage.getItem(LocalStorageKey.TOKEN);

	const [user, setUser] = useRecoilState(userAtom);
	const getUserRes = useGetUserQuery({
		skip: shouldSkip || !hasToken || !!user,
	});

	useEffect(() => {
		if (!getUserRes.loading) {
			if (getUserRes.data) {
				setUser(getUserRes.data.getUser);
			}
		}
	}, [getUserRes.loading, getUserRes.data, setUser]);

	if (shouldSkip || !hasToken) {
		return false;
	}

	const isGettingUser = getUserRes.loading || (!!getUserRes.data && !user);

	return isGettingUser;
};

export default useLoginWithLocalToken;
