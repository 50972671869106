import React from "react";
import { useRecoilValue } from "recoil";
import Spreadsheet from "./spreadsheet/Spreadsheet";
import { bidSheetCountAtom, bidSheetLastUpdatedAtom } from "../../state/bidSheet.Recoil";

const BidSheet = () => {
	const bidSheet = useRecoilValue(bidSheetLastUpdatedAtom);
	const { errorCount, warningCount, completedPercent } = useRecoilValue(bidSheetCountAtom);
	return (
		<div className="animate__animated animate__fadeInLeft animate__faster mt-5">
			<div className="my-3 px-2">
				Please complete the following table in its entirety.{" "}
				<b>Valid data will be saved automatically.</b>
			</div>
			<div className="h-8 my-3 flex items-center">
				{bidSheet.isUpdating && (
					<div className="flex items-center">
						<div className="rounded-2xl border-t-4 border-b-4 border-primary animate-spin h-5 w-5 mr-3"></div>
						Saving . . .
					</div>
				)}
				{!bidSheet.isUpdating &&
					bidSheet.lastUpdatedDate &&
					!bidSheet.errorMsg &&
					String(bidSheet.lastUpdatedDate) && (
						<div className="my-3 italic px-2">
							Last Updated on <span>{String(bidSheet.lastUpdatedDate)}</span>
						</div>
					)}

				{!bidSheet.isUpdating && bidSheet.errorMsg && (
					<div className="my-3 italic px-2 text-red-800">
						Error, please refresh page, code: <span className="font-bold">{bidSheet.errorMsg}</span>
					</div>
				)}
			</div>
			<div className="flex justify-end gap-2 py-2">
				{errorCount !== undefined && errorCount > 0 && (
					<div className="rounded-sm bg-red-300 px-2 animate__animated animate__fadeInRight animate__faster">
						Invalid: {errorCount}
					</div>
				)}
				{warningCount !== undefined && warningCount > 0 && (
					<div className="rounded-sm bg-yellow-300 px-2 animate__animated animate__fadeInRight animate__faster">
						Remaining: {warningCount}
					</div>
				)}
				{completedPercent && (
					<div className="rounded-sm bg-green-300 px-2 animate__animated animate__fadeInRight animate__faster">
						Completed: {completedPercent}
					</div>
				)}
			</div>
			<div className="w-full h-full relative transform translate-x-0">
				<Spreadsheet />
			</div>
		</div>
	);
};

export default BidSheet;
