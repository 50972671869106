import React, { ButtonHTMLAttributes } from "react";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}
const Button: React.FC<Props> = (props) => {
	return (
		<button
			className="flex bg-primary text-white rounded w-max px-3 py-1.5 items-center hover:bg-red-800"
			{...props}
		>
			{props.children}
		</button>
	);
};

export default Button;
