import Card from "../../components/Card";
import { ChevronDownIcon, ChevronUpIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import {
	ActActiveUsers,
	ActivityFragment,
	useDeleteActivityMutation,
	useGetCompaniesThatHasBidCountQuery,
	useGetCompaniesThatHasFileCountQuery,
	UserTypeNames,
} from "../../generated/graphql";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import ActivityForm from "./ActivityForm";
import { userAtom } from "../../state/user.recoil";
import { formatDate } from "../../utils/utils";
import { MdWarning } from "react-icons/md";

interface Props {
	activity: ActivityFragment;
	onReloadActivities: () => void;
	shouldExpand?: boolean;
	onClickCard: () => void;
	onClickExpand: () => void;
	onDeleteActivity: () => void;
	activityActiveUsers?: ActActiveUsers[];
}

const Activities: React.FC<Props> = ({
	activity,
	onReloadActivities,
	shouldExpand,
	onClickCard,
	onClickExpand,
	onDeleteActivity,
	activityActiveUsers,
}) => {
	const user = useRecoilValue(userAtom);
	const isAdmin = user!.typeUserType.name === UserTypeNames.Admin;

	const setModalRender = useSetRecoilState(modalRenderAtom);

	const getCompaniesThatHasFileCountRes = useGetCompaniesThatHasFileCountQuery({
		variables: {
			activityId: activity.id,
		},
	});
	const getCompaniesThatHasBidCountRes = useGetCompaniesThatHasBidCountQuery({
		variables: {
			activityId: activity.id,
		},
	});

	function createMarkup(htmlCont: string) {
		return { __html: htmlCont };
	}

	const companiesThatHasFileCount =
		getCompaniesThatHasFileCountRes.data?.getCompaniesThatHasFileCount || 0;
	const companiesThatHasBidCount =
		getCompaniesThatHasBidCountRes.data?.getCompaniesThatHasBidCount || 0;

	const activityCardCss = `
		.activityCard {
			padding: 0;
		}
		.card-bottom::before {
			display: block;
			position: absolute;
			color: rgb(107, 114, 128);
			top: 10px;
			right: 20px;
			font-weight: 700;
			content: "GO TO ACTIVITY";
		}
		.card-bottom:hover::before {
			color: rgb(200, 31, 63);
			font-weight: 700;
			animation: fadeIn 1s infinite alternate;
		}
		@keyframes fadeIn {
			from { opacity: 0.5; }
		}
	`;

	return (
		<Card className="activityCard rfp-item animate__animated animate__fadeInUp animate__faster">
			<style>{activityCardCss}</style>
			<div
				className={
					"text-gray-500 text-lg font-bold  hover:text-primary flex justify-between hover:bg-gray-100 rounded p-8 " +
					(shouldExpand ? "border-b border-gray-300" : "")
				}
				onClick={() => onClickExpand()}
			>
				<div className="sm:flex gap-5 items-center justify-between w-full">
					<div className="flex gap-4 items-center">
						<div>{activity.name}</div>
						<div className="text-primary text-xs flex gap-1 items-center">
							{activityActiveUsers && activityActiveUsers?.length > 0 && (
								<div className="flex items-center gap-2">
									<MdWarning className="h-5 w-5" />
									Active Users:
								</div>
							)}
							{activityActiveUsers?.map((activeUser, i) => (
								<div key={i} className="font-bold">
									{i !== 0 && ","}
									{activeUser.user.userCompanyUser?.name}
								</div>
							))}
						</div>
					</div>
					<div className="sm:flex gap-5">
						{activity.activeAt && (
							<div className="text-xs">
								Activated At:&nbsp;
								<span className="text-gray-900">
									{formatDate(new Date(activity.activeAt), false)}
								</span>
							</div>
						)}
						<div className="text-xs">
							Expires At:&nbsp;
							<span className="text-gray-900">
								{formatDate(new Date(activity.expiresAt), false)}
							</span>
						</div>
					</div>
				</div>

				{shouldExpand ? (
					<ChevronUpIcon className="w-7 h-7" />
				) : (
					<ChevronDownIcon className="w-7 h-7" />
				)}
			</div>
			{shouldExpand && (
				<div
					className="card-bottom relative hover:bg-gray-100 px-8 py-2 pt-8"
					onClick={() => onClickCard()}
				>
					{user!.typeUserType.name === UserTypeNames.Admin && (
						<div className="flex gap-4">
							{getCompaniesThatHasFileCountRes.loading ? (
								<div className="rounded-2xl border-t-4 border-b-4 border-red-600 animate-spin h-5 w-5 mr-3" />
							) : (
								<>
									<div className="p-4 shadow rounded text-2xl bg-gray-200">
										Files from{" "}
										<span className="text-primary font-bold">{companiesThatHasFileCount}</span>{" "}
										companies
									</div>
								</>
							)}
							{getCompaniesThatHasBidCountRes.loading ? (
								<div className="rounded-2xl border-t-4 border-b-4 border-red-600 animate-spin h-5 w-5 mr-3" />
							) : (
								<>
									<div className="p-4 shadow rounded text-2xl bg-gray-200">
										Bids from{" "}
										<span className="text-primary font-bold">{companiesThatHasBidCount}</span>{" "}
										companies
									</div>
								</>
							)}
						</div>
					)}
					<div
						className="py-1"
						dangerouslySetInnerHTML={createMarkup(
							activity.description! ||
								(isAdmin
									? "<i style='color: grey;'>Please Add more details to your activity, click on 'edit' to add details.<i>"
									: "<i style='color: grey;'>Click Card again to go to the Activity.<i>"),
						)}
					/>
					<div className="text-primary hover:text-red-800 flex gap-5 justify-end">
						{user!.typeUserType.name === UserTypeNames.Admin && (
							<>
								<button
									className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800 gap-1 my-10 items-center"
									onClick={(e) => {
										e.stopPropagation();
										setModalRender(
											<ActivityForm activity={activity} onSuccess={() => onReloadActivities()} />,
										);
									}}
								>
									<PencilIcon className="w-5 h-5 mr-1" />
									Edit
								</button>
								<button
									className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800 gap-1 my-10 items-center"
									onClick={(e) => {
										e.stopPropagation();
										setModalRender(
											<div className="flex flex-col sm:max-w-lg">
												<div className="text-left">
													Are you sure you want to delete{" "}
													<span className="text-primary font-bold">{activity.name}</span>, any{" "}
													<span className="text-gray-800 font-bold">File</span> or{" "}
													<span className="text-gray-800 font-bold">Progress</span> associated to
													this Activity will be lost forever.
												</div>
												<button
													className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center my-10 "
													onClick={() => onDeleteActivity()}
												>
													DELETE ACTIVITY
												</button>
											</div>,
										);
									}}
								>
									<TrashIcon className="w-5 h-5 mr-1" />
									Delete
								</button>
							</>
						)}
					</div>
				</div>
			)}
		</Card>
	);
};

export default Activities;
