import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { useRecoilState } from "recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import "./topModal.scss";

const TopModal: React.FC = () => {
	const [render, setRender] = useRecoilState(modalRenderAtom);
	if (!render) {
		return null;
	}

	const onCloseClick = () => {
		setRender(undefined);
	};

	return (
		<div className="modal">
			<div
				className="card animate__animated animate__fadeInUp animate__faster"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="card-header">
					<XIcon
						className="w-5 h-5 self-end ml-auto hover:bg-gray-300 rounded-full cursor-pointer"
						onClick={() => onCloseClick?.()}
					/>
				</div>
				<div className="content">{render}</div>
			</div>
		</div>
	);
};

export default TopModal;
