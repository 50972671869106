import { DownloadIcon, FilterIcon, XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import {
	CompaniesBidsheetProductFilter,
	useGetBidSheetAsCsvLazyQuery,
} from "../../generated/graphql";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { downloadStr, formatDateISO } from "../../utils/utils";

type FilterObj = {
	title: String;
	isActive: boolean;
	value?: string;
	valueMin?: string;
	valueMax?: string;
	type?: "regular" | "bool" | "range";
};

interface Filters {
	companyName: FilterObj;

	rfptItemNumber: FilterObj;
	tier: FilterObj;
	cat: FilterObj;
	sub: FilterObj;
	type: FilterObj;
	milMicGauge: FilterObj;
	newCatColor: FilterObj;
	total: FilterObj;
	east: FilterObj;
	west: FilterObj;

	netDeliveredCaseWest: FilterObj;
	netDeliveredCaseEast: FilterObj;
	manufName: FilterObj;
	equivMfrNumber: FilterObj;
	bidTier: FilterObj;
	manufacturerProductDescription: FilterObj;
	brand: FilterObj;
	gtinNumber: FilterObj;
	material: FilterObj;
	gauge: FilterObj;
	rollOrFlatPack: FilterObj;
	packSize: FilterObj;
	isSepticSafe: FilterObj;
	numberOfSheetsPerCase: FilterObj;
	linearFtPerCase: FilterObj;
	netWeight: FilterObj;
	other: FilterObj;
	willProduceInCompanions: FilterObj;
	notes: FilterObj;
}

interface Props {
	onFilterChange: (filter: CompaniesBidsheetProductFilter) => void;
}

const ActivityBidsheetFilters = ({ onFilterChange }: Props) => {
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const activity = useRecoilValue(activityAtom)!;
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [filters, setFilters] = useState<Filters>({
		companyName: {
			title: "Company Name",
			isActive: false,
		},
		rfptItemNumber: {
			title: "RFP Item #",
			isActive: false,
		},
		tier: {
			title: "Tier",
			isActive: false,
		},
		cat: {
			title: "Cat",
			isActive: false,
		},
		sub: {
			title: "Sub",
			isActive: false,
		},
		type: {
			title: "Type",
			isActive: false,
		},
		milMicGauge: {
			title: "Mil/Mic/Gauge",
			isActive: false,
		},
		newCatColor: {
			title: "New Cat/Color",
			isActive: false,
		},
		total: {
			title: "Total",
			isActive: false,
			type: "range",
		},
		east: {
			title: "East",
			isActive: false,
			type: "range",
		},
		west: {
			title: "West",
			isActive: false,
			type: "range",
		},
		netDeliveredCaseWest: {
			title: "Net Case Delivered West",
			isActive: false,
			type: "range",
		},
		netDeliveredCaseEast: {
			title: "Net Case Delivered East",
			isActive: false,
			type: "range",
		},
		manufName: {
			title: "Manuf Name",
			isActive: false,
		},
		equivMfrNumber: {
			title: "Equiv Mfr Number",
			isActive: false,
		},
		bidTier: {
			title: "Bid Tier",
			isActive: false,
		},
		manufacturerProductDescription: {
			title: "Manuf. Product Desc.",
			isActive: false,
		},
		brand: {
			title: "Brand",
			isActive: false,
		},
		gtinNumber: {
			title: "GTIN Number",
			isActive: false,
		},
		material: {
			title: "Material",
			isActive: false,
		},
		gauge: {
			title: "Gauge",
			isActive: false,
		},
		rollOrFlatPack: {
			title: "Roll or Flat Pack",
			isActive: false,
		},
		packSize: {
			title: "Pack Size",
			isActive: false,
		},
		isSepticSafe: {
			title: "is Septic Safe",
			isActive: false,
			type: "bool",
		},
		numberOfSheetsPerCase: {
			title: "Number of sheets Per Case",
			isActive: false,
			type: "range",
		},
		linearFtPerCase: {
			title: "linear Ft Per Case",
			isActive: false,
			type: "range",
		},
		netWeight: {
			title: "Net Weight",
			isActive: false,
			type: "range",
		},
		other: {
			title: "Other",
			isActive: false,
		},
		willProduceInCompanions: {
			title: "Will Produce in Companions",
			isActive: false,
			type: "bool",
		},
		notes: {
			title: "notes",
			isActive: false,
		},
	});
	const [getBidSheetAsCSV, getBidSheetAsCSVRes] = useGetBidSheetAsCsvLazyQuery({
		variables: {
			input: {
				activityId: activity.id,
			},
		},
		fetchPolicy: "network-only",
	});

	const hasFiltersActive = () => {
		const res = Object.keys(filters).find((key) => {
			const filter = key as keyof Filters;
			return filters[filter].isActive;
		});
		return res;
	};

	const clearAllFilters = () => {
		const newFilters = { ...filters };
		Object.keys(filters).forEach((key) => {
			const filter = key as keyof Filters;
			newFilters[filter].isActive = false;
			newFilters[filter].value = undefined;
			newFilters[filter].valueMin = undefined;
			newFilters[filter].valueMax = undefined;
		});
		setFilters(newFilters);

		//reload results
		filterChange();
	};

	const clearAllValues = () => {
		const newFilters = { ...filters };
		Object.keys(filters).forEach((key) => {
			const filter = key as keyof Filters;
			newFilters[filter].value = undefined;
			newFilters[filter].valueMin = undefined;
			newFilters[filter].valueMax = undefined;
		});
		setFilters(newFilters);

		//reload results
		filterChange();
	};

	const addAllFilters = () => {
		const newFilters = { ...filters };
		Object.keys(filters).forEach((key) => {
			const filter = key as keyof Filters;
			newFilters[filter].isActive = true;
		});
		setFilters(newFilters);
	};

	const downloadBidSheetAsCSV = () => {
		setLoadingMessage("Downloading Bid Sheet CSV");
		getBidSheetAsCSV();
	};

	const filterChange = () => {
		//build filter object, return to top component
		const realFilter: CompaniesBidsheetProductFilter = {
			companyName: filters.companyName.value || undefined,
			rfpItemNumber: Number(filters.rfptItemNumber.value) || undefined,
			tier: filters.tier.value || undefined,
			cat: filters.cat.value || undefined,
			sub: filters.sub.value || undefined,
			type: filters.type.value || undefined,
			milMicGauge: filters.milMicGauge.value || undefined,
			newCatColor: filters.newCatColor.value || undefined,

			manufName: filters.manufName.value || undefined,
			equivMfrNumber: filters.equivMfrNumber.value || undefined,
			bidTier: filters.bidTier.value || undefined,
			manufacturerProductDescription: filters.manufacturerProductDescription.value || undefined,
			brand: filters.brand.value || undefined,
			gtinNumber: filters.gtinNumber.value || undefined,
			material: filters.material.value || undefined,
			gauge: filters.gauge.value || undefined,
			rollOrFlatPack: filters.rollOrFlatPack.value || undefined,
			packSize: filters.packSize.value || undefined,
			isSepticSafe:
				filters.isSepticSafe.value === "true"
					? true
					: filters.isSepticSafe.value === "false"
					? false
					: undefined,
			other: filters.other.value || undefined,
			willProduceInCompanions:
				filters.willProduceInCompanions.value === "true"
					? true
					: filters.willProduceInCompanions.value === "false"
					? false
					: undefined,
			notes: filters.notes.value || undefined,
		};
		//special cases
		if (filters.total.valueMin && filters.total.valueMax) {
			//verify if both are numbers
			if (!isNaN(Number(filters.total.valueMin)) && !isNaN(Number(filters.total.valueMin))) {
				realFilter.totalMin = Number(filters.total.valueMin);
				realFilter.totalMax = Number(filters.total.valueMax);
			}
		}

		if (filters.east.valueMin && filters.east.valueMax) {
			//verify if both are numbers
			if (!isNaN(Number(filters.east.valueMin)) && !isNaN(Number(filters.east.valueMin))) {
				realFilter.eastMin = Number(filters.east.valueMin);
				realFilter.eastMax = Number(filters.east.valueMax);
			}
		}

		if (filters.west.valueMin && filters.west.valueMax) {
			//verify if both are numbers
			if (!isNaN(Number(filters.west.valueMin)) && !isNaN(Number(filters.west.valueMin))) {
				realFilter.westMin = Number(filters.west.valueMin);
				realFilter.westMax = Number(filters.west.valueMax);
			}
		}

		if (filters.netDeliveredCaseWest.valueMin && filters.netDeliveredCaseWest.valueMax) {
			//verify if both are numbers
			if (
				!isNaN(Number(filters.netDeliveredCaseWest.valueMin)) &&
				!isNaN(Number(filters.netDeliveredCaseWest.valueMin))
			) {
				realFilter.netDeliveredCaseWestMin = Number(filters.netDeliveredCaseWest.valueMin);
				realFilter.netDeliveredCaseWestMax = Number(filters.netDeliveredCaseWest.valueMax);
			}
		}

		if (filters.netDeliveredCaseEast.valueMin && filters.netDeliveredCaseEast.valueMax) {
			//verify if both are numbers
			if (
				!isNaN(Number(filters.netDeliveredCaseEast.valueMin)) &&
				!isNaN(Number(filters.netDeliveredCaseEast.valueMin))
			) {
				realFilter.netDeliveredCaseEastMin = Number(filters.netDeliveredCaseEast.valueMin);
				realFilter.netDeliveredCaseEastMax = Number(filters.netDeliveredCaseEast.valueMax);
			}
		}

		if (filters.numberOfSheetsPerCase.valueMin && filters.numberOfSheetsPerCase.valueMax) {
			//verify if both are numbers
			if (
				!isNaN(Number(filters.numberOfSheetsPerCase.valueMin)) &&
				!isNaN(Number(filters.numberOfSheetsPerCase.valueMin))
			) {
				realFilter.numberOfSheetsPerCaseMin = Number(filters.numberOfSheetsPerCase.valueMin);
				realFilter.numberOfSheetsPerCaseMax = Number(filters.numberOfSheetsPerCase.valueMax);
			}
		}

		if (filters.linearFtPerCase.valueMin && filters.linearFtPerCase.valueMax) {
			//verify if both are numbers
			if (
				!isNaN(Number(filters.linearFtPerCase.valueMin)) &&
				!isNaN(Number(filters.linearFtPerCase.valueMin))
			) {
				realFilter.linearFtPerCaseMin = Number(filters.linearFtPerCase.valueMin);
				realFilter.linearFtPerCaseMax = Number(filters.linearFtPerCase.valueMax);
			}
		}

		if (filters.netWeight.valueMin && filters.netWeight.valueMax) {
			//verify if both are numbers
			if (
				!isNaN(Number(filters.netWeight.valueMin)) &&
				!isNaN(Number(filters.netWeight.valueMin))
			) {
				realFilter.netWeightMin = Number(filters.netWeight.valueMin);
				realFilter.netWeightMax = Number(filters.netWeight.valueMax);
			}
		}

		return onFilterChange(realFilter);
	};

	useEffect(() => {
		if (getBidSheetAsCSVRes.called && !getBidSheetAsCSVRes.loading) {
			if (getBidSheetAsCSVRes.data) {
				downloadStr(
					getBidSheetAsCSVRes.data.getBidSheetAsCSV,
					formatDateISO(new Date()) + "_BidSheet.csv",
				);
			}
			setLoadingMessage(undefined);
		}
	}, [getBidSheetAsCSVRes.called, getBidSheetAsCSVRes.data, getBidSheetAsCSVRes.loading]);

	return (
		<div className="activity-bidsheet-filters w-full">
			<div className="flex justify-between">
				<Button onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
					{!isFiltersOpen ? (
						<FilterIcon className="w-5 h-5 mr-1" />
					) : (
						<XIcon className="w-5 h-5 mr-1" />
					)}
					Filters
				</Button>
				<Button onClick={() => downloadBidSheetAsCSV()}>
					<DownloadIcon className="w-5 h-5" />
					Download Bid Sheet CSV
				</Button>
			</div>

			{isFiltersOpen && (
				<>
					<div
						className="fixed top-0 left-0 w-screen h-screen bg-gray-900 bg-opacity-20 z-20"
						onClick={() => setIsFiltersOpen(false)}
					></div>
					<div className="absolute overflow-hidden w-full h-full">
						<div className="absolute bg-white rounded border-2 border-gray-500 text-black z-20 animate__animated animate__slideInDown animate__faster">
							<div className="flex justify-between">
								<h2 className="p-1 font-bold">Select Filters</h2>
								<div className="flex items-center">
									<h4
										className="p-1 text-primary cursor-pointer hover:text-red-800"
										onClick={() => addAllFilters()}
									>
										all
									</h4>
									/
									<h4
										className="p-1 text-primary cursor-pointer hover:text-red-800"
										onClick={() => clearAllFilters()}
									>
										none
									</h4>
								</div>
							</div>

							{Object.keys(filters).map((key, i) => {
								const filter = key as keyof Filters;
								return (
									<label
										className="flex justify-between items-center px-1 rounded cursor-pointer hover:bg-gray-200 "
										key={i}
									>
										<span>{filters[filter].title}</span>
										<input
											onChange={(e) => {
												const newFilters = { ...filters };
												newFilters[filter].isActive = e.currentTarget.checked;
												if (!e.currentTarget.checked) {
													newFilters[filter].value = undefined;
													newFilters[filter].valueMin = undefined;
													newFilters[filter].valueMax = undefined;
												}
												setFilters(newFilters);
											}}
											type="checkbox"
											checked={filters[filter].isActive}
											className="focus:ring-transparent focus:ring-offset-transparent h-4 w-4 text-primary border-gray-300 rounded ml-2"
										/>
									</label>
								);
							})}
						</div>
					</div>
				</>
			)}

			{hasFiltersActive() && (
				<form
					className="flex flex-wrap bg-gray-400 rounded w-full gap-2 px-2 py-1 mt-1"
					onChange={() => filterChange()}
				>
					{Object.keys(filters).map((key, i) => {
						const filter = key as keyof Filters;
						return (
							<React.Fragment key={i}>
								{filters[filter].isActive && (
									<div className="flex flex-col w-48">
										<label className="text-sm font-bold">{filters[filter].title}</label>
										{filters[filter].type === "bool" ? (
											<div className="flex">
												<label className="w-1/2">
													<input
														type="radio"
														className="focus:ring-transparent focus:ring-offset-transparent h-4 w-4 text-primary border-gray-300 ml-2"
														name={filter}
														onChange={(e) => {
															const newFilters = { ...filters };
															newFilters[filter].value = "true";
															setFilters(newFilters);
														}}
													/>
													<span>Yes</span>
												</label>
												<label className="w-1/2">
													<input
														type="radio"
														className="focus:ring-transparent focus:ring-offset-transparent h-4 w-4 text-primary border-gray-300 ml-2"
														name={filter}
														onChange={(e) => {
															const newFilters = { ...filters };
															newFilters[filter].value = "false";
															setFilters(newFilters);
														}}
													/>
													<span>No</span>
												</label>
											</div>
										) : filters[filter].type === "range" ? (
											<div className="flex">
												<input
													className="border border-gray-400 rounded focus:outline-none focus:border-primary pl-1 h-8  w-full"
													value={filters[filter].valueMin || ""}
													onChange={(e) => {
														const newFilters = { ...filters };
														newFilters[filter].valueMin = e.currentTarget.value;
														setFilters(newFilters);
													}}
													placeholder="Min"
												></input>
												<input
													className="border border-gray-400 rounded focus:outline-none focus:border-primary pl-1 h-8  w-full"
													value={filters[filter].valueMax || ""}
													onChange={(e) => {
														const newFilters = { ...filters };
														newFilters[filter].valueMax = e.currentTarget.value;
														setFilters(newFilters);
													}}
													placeholder="Max"
												></input>
											</div>
										) : (
											<input
												className="border border-gray-400 rounded focus:outline-none focus:border-primary pl-1 h-8  w-full"
												value={filters[filter].value || ""}
												onChange={(e) => {
													const newFilters = { ...filters };
													newFilters[filter].value = e.currentTarget.value;
													setFilters(newFilters);
												}}
											></input>
										)}
									</div>
								)}
							</React.Fragment>
						);
					})}
					<div className="flex w-24 justify-end items-end">
						<input
							type="reset"
							value="Reset"
							className="p-1 flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center hover:bg-red-800 cursor-pointer"
							onClick={() => clearAllValues()}
						/>
					</div>
				</form>
			)}
		</div>
	);
};

export default ActivityBidsheetFilters;
