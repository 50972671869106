import env from "./env";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { LocalStorageKey } from "./constants/LocalStorageKey";

const httpLink = createHttpLink({
	uri: env.graphQLUrl,
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem(LocalStorageKey.TOKEN);
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : "",
		},
	};
});

export const apolloClient = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
});
