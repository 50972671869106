import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { ChevronDownIcon, ChevronUpIcon, FilterIcon, PlusIcon } from "@heroicons/react/solid";
import {
	OrderBy,
	useDeleteCompanyMutation,
	useGetCompaniesLazyQuery,
} from "../../generated/graphql";
import { useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import CompanyForm from "./CompanyForm";
import { useStates } from "../../hooks/useStates";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { pushToOrderBys } from "../../utils/GraphQLUtils";
import { IoTrashOutline } from "react-icons/io5";
import { GrEdit } from "react-icons/gr";

const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
	orderBys: OrderBy[];
};

const Companies: React.FC = () => {
	const [{ currentPage, orderBys }, setState] = useStates<State>({
		currentPage: 0,
		orderBys: [{ by: "name", isDesc: false }],
	});
	const [filter, setFilter] = useState("");

	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const [getCompanies, getCompaniesRes] = useGetCompaniesLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [deleteCompany] = useDeleteCompanyMutation();

	const getCompaniesByPage = (page: number) => {
		getCompanies({
			variables: {
				getCompaniesInput: {
					pagination: {
						offset: page * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
					orderBys,
					filter: {
						likeAny: filter,
					},
				},
			},
		});
	};

	const resetCompaniesTable = () => {
		if (currentPage === 0) {
			getCompaniesByPage(0);
		} else {
			setState({ currentPage: 0 });
		}
		setTimeout(() => {
			setModalRender(undefined);
		}, 1000);
	};

	const _deleteCompany = (id: number) => {
		setLoadingMessage("Deleting Company");
		deleteCompany({
			variables: {
				companyId: id,
			},
		})
			.then(() => {
				resetCompaniesTable();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				resetCompaniesTable();
				setLoadingMessage(undefined);
				setModalRender(undefined);
			});
	};

	useEffect(() => {
		getCompaniesByPage(currentPage);
	}, [currentPage]);

	useEffect(() => {
		resetCompaniesTable();
	}, [filter, orderBys]);

	useEffect(() => {
		if (getCompaniesRes.called) {
			if (getCompaniesRes.loading) {
				setLoadingMessage("Loading Companies");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getCompaniesRes.called, getCompaniesRes.loading, getCompaniesRes.data]);

	const companies = getCompaniesRes?.data?.getCompanies?.nodes || [];
	const pages = (getCompaniesRes.data?.getCompanies?.totalCount || 0) / PAGE_ITEM_COUNT;

	const deletePrompt = (id: number) => {
		return (
			<div className="w-85 flex flex-col justify-center items-center self-center mb-7">
				<div className="my-5">Are you sure you want to delete this company?</div>
				<div className="flex gap-4">
					<button
						className="w-max px-4 py-1 rounded border border-gray-300 hover:bg-gray-400"
						onClick={() => setModalRender(undefined)}
					>
						No
					</button>
					<button
						className="w-max px-4 py-1 rounded bg-primary hover:bg-red-900 text-white"
						onClick={() => _deleteCompany(id)}
					>
						Yes
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="container mx-auto p-3">
			<Header />
			<div className="flex flex-row  justify-between my-10 text-2xl">
				<b>Companies</b>

				<button
					className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center hover:bg-red-800"
					onClick={() => setModalRender(<CompanyForm onSuccess={resetCompaniesTable} />)}
				>
					<PlusIcon className="w-6 h-6 mr-1" />
					Add New Company
				</button>
			</div>

			<div className="relative w-full my-3">
				<input
					className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-80"
					onChange={(e) => setFilter(e.target.value)}
					value={filter}
					placeholder="filter by any field (id, name, type)"
				></input>
				<FilterIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
			</div>

			<div>
				<Table
					columns={[
						{
							name: "Name",
							field: "name",
							isSortable: true,
						},
						{
							name: "Type",
							formattedField: (row) => {
								return <div>{row.typeCompanyType.name}</div>;
							},
							isSortable: true,
						},
						{
							name: "ID",
							field: "id",
							isSortable: true,
						},
						{
							name: "Update",
							formattedField: (row) => {
								return (
									<button
										className="flex text-primary m-auto"
										onClick={() =>
											setModalRender(<CompanyForm company={row} onSuccess={resetCompaniesTable} />)
										}
									>
										<GrEdit className="w-5 h-5 text-primary" />
									</button>
								);
							},
						},
						{
							name: "Delete",
							formattedField: (row) => {
								return (
									<button
										className="flex text-primary m-auto"
										onClick={() => setModalRender(deletePrompt(row.id))}
									>
										<IoTrashOutline className="w-5 h-5 text-primary" />
									</button>
								);
							},
						},
					]}
					rows={companies}
					pagination={{
						pages,
						currentPage,
						onPageClick: (page: number) => {
							setState({
								currentPage: page,
							});
						},
					}}
					onSort={(field, isDesc) => {
						if (field) {
							setState({
								orderBys: pushToOrderBys(orderBys, field, isDesc),
							});
						}
					}}
					defaultSortField="name"
					defaultIsSortDesc={false}
					ascIcon={<ChevronUpIcon className="h-5 w-5" />}
					descIcon={<ChevronDownIcon className="h-5 w-5" />}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default Companies;
