import React from "react";

interface Props {
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	className?: String;
}

const Card: React.FC<Props> = (props) => {
	return (
		<div className={"card " + (props.className ? props.className : "")} onClick={props.onClick}>
			{props.children}
		</div>
	);
};

export default Card;
