import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Table from "../../components/Table";
import {
	CompaniesBidsheetProductFilter,
	useGetCompaniesBidsheetProductLazyQuery,
} from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { formatCurrency, formatDate, formatLocaleNumber } from "../../utils/utils";
import ActivityBidsheetFilters from "./ActivityBidsheetFilters";

const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
};

const ActivityBidsheet = () => {
	const [{ currentPage }, setState] = useStates<State>({
		currentPage: 0,
	});
	const activity = useRecoilValue(activityAtom)!;
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const [bidsheetFilter, setBidsheetFilter] = useState<CompaniesBidsheetProductFilter>();

	const [getCompaniesBidsheet, getCompaniesBidsheetRes] = useGetCompaniesBidsheetProductLazyQuery({
		fetchPolicy: "cache-and-network",
	});

	// const [getBidSheetAsCSV, getBidSheetAsCSVRes] = useGetBidSheetAsCsvLazyQuery({
	// 	fetchPolicy: "network-only",
	// });

	const getCompaniesBidsheetByPage = (page: number) => {
		getCompaniesBidsheet({
			variables: {
				input: {
					activityId: activity.id,
					pagination: {
						offset: page * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
					orderBys: [
						{
							by: "id",
						},
					],
					filter: bidsheetFilter,
				},
			},
		});
	};

	const onFilterChange = (filter: CompaniesBidsheetProductFilter) => {
		setBidsheetFilter(filter);
	};

	const isHidden = (field: any) => {
		const hiddenColsArray = activity.hiddenColumns?.split(",");
		return hiddenColsArray?.includes(field) || false;
	};
	// const downloadBidSheetAsCSV = () => {
	// 	setLoadingMessage("Downloading Bid Sheet CSV");
	// 	getBidSheetAsCSV();
	// };

	useEffect(() => {
		getCompaniesBidsheetByPage(currentPage);
	}, [currentPage, bidsheetFilter]);

	// useEffect(() => {
	// 	if (getBidSheetAsCSVRes.called && !getBidSheetAsCSVRes.loading) {
	// 		if (getBidSheetAsCSVRes.data) {
	// 			downloadStr(
	// 				getBidSheetAsCSVRes.data.getBidSheetAsCSV,
	// 				formatDateISO(new Date()) + "_BidSheet.csv",
	// 			);
	// 		}
	// 		setLoadingMessage(undefined);
	// 	}
	// }, [getBidSheetAsCSVRes.called, getBidSheetAsCSVRes.data, getBidSheetAsCSVRes.loading]);

	useEffect(() => {
		if (getCompaniesBidsheetRes.called) {
			if (getCompaniesBidsheetRes.loading) {
				setLoadingMessage("Loading Products");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [
		getCompaniesBidsheetRes.called,
		getCompaniesBidsheetRes.loading,
		getCompaniesBidsheetRes.data,
	]);

	const companyBidsheets = getCompaniesBidsheetRes.data?.getCompaniesBidsheetProduct.nodes || [];
	const pages =
		(getCompaniesBidsheetRes.data?.getCompaniesBidsheetProduct?.totalCount || 0) / PAGE_ITEM_COUNT;

	return (
		<div className="activity-bidsheet">
			<div className="flex justify-between">
				<ActivityBidsheetFilters onFilterChange={onFilterChange} />
			</div>

			<br />
			<div className="w-full overflow-auto">
				<Table
					className="w-max"
					columns={[
						{
							name: "COMPANY NAME",
							formattedField: (row) => {
								return <div>{row.company?.name}</div>;
							},
						},
						{
							name: "RFP ITEM #",
							formattedField: (row) => {
								return <div>{row.activityProduct?.rfpItemNumber}</div>;
							},
						},
						{
							name: "TIER",
							formattedField: (row) => {
								return <div>{row.activityProduct?.tier}</div>;
							},
							columnWidth: "max-content",
							hidden: isHidden("tier"),
						},
						{
							name: "CAT",
							formattedField: (row) => {
								return <div>{row.activityProduct?.cat}</div>;
							},
							hidden: isHidden("cat"),
						},
						{
							name: "SUB",
							formattedField: (row) => {
								return <div>{row.activityProduct?.sub}</div>;
							},
							hidden: isHidden("sub"),
						},
						{
							name: "TYPE",
							formattedField: (row) => {
								return <div>{row.activityProduct?.type}</div>;
							},
							hidden: isHidden("type"),
						},
						{
							name: "MIL/MIC/GAUGE",
							formattedField: (row) => {
								return <div>{row.activityProduct?.milMicGauge}</div>;
							},
							hidden: isHidden("milMicGauge"),
						},
						{
							name: "NEW CAT/COLOR",
							formattedField: (row) => {
								return <div>{row.activityProduct?.newCatColor}</div>;
							},
							hidden: isHidden("newCatColor"),
						},
						{
							name: "TOTAL",
							formattedField: (row) => {
								return <div>{formatLocaleNumber(row.activityProduct?.total)}</div>;
							},
							hidden: isHidden("total"),
						},
						{
							name: "EAST",
							formattedField: (row) => {
								return <div>{formatLocaleNumber(row.activityProduct?.east)}</div>;
							},
							hidden: isHidden("east"),
						},
						{
							name: "WEST",
							formattedField: (row) => {
								return <div>{formatLocaleNumber(row.activityProduct?.west)}</div>;
							},
							hidden: isHidden("west"),
						},
						{
							name: "NET DELIVERED CASE WEST",
							field: "netDeliveredCaseWest",
							hidden: isHidden("netDeliveredCaseWest"),
						},
						{
							name: "NET DELIVERED CASE EAST",
							field: "netDeliveredCaseEast",
							formattedField: (row) => {
								return <div>{formatCurrency(row.netDeliveredCaseEast)}</div>;
							},
							hidden: isHidden("netDeliveredCaseEast"),
						},
						{
							name: "MANUF NAME",
							field: "manufName",
							formattedField: (row) => {
								return <div>{formatCurrency(row.netDeliveredCaseWest)}</div>;
							},
							hidden: isHidden("manufName"),
						},
						{
							name: "EQUIV MFR NUMBER",
							field: "equivMfrNumber",
							hidden: isHidden("equivMfrNumber"),
						},
						{
							name: "BID TIER",
							field: "tier",
							hidden: isHidden("bidTier"),
						},
						{
							name: "MANUFACTURER PRODUCT DESCRIPTION",
							field: "manufacturerProductDescription",
							hidden: isHidden("manufacturerProductDescription"),
						},
						{
							name: "BRAND",
							field: "brand",
							hidden: isHidden("brand"),
						},
						{
							name: "GTIN NUMBER",
							field: "gtinNumber",
							hidden: isHidden("gtinNumber"),
						},
						{
							name: "MATERIAL",
							field: "material",
							hidden: isHidden("material"),
						},
						{
							name: "GAUGE",
							field: "gauge",
							hidden: isHidden("gauge"),
						},
						{
							name: "ROLL OR FLAT PACK",
							field: "rollOrFlatPack",
							hidden: isHidden("rollOrFlatPack"),
						},
						{
							name: "PACK SIZE",
							field: "packSize",
							hidden: isHidden("packSize"),
						},
						{
							name: "IS SEPTIC SAFE",
							field: "isSepticSafe",
							hidden: isHidden("isSepticSafe"),
							formattedField: (row) => {
								return <div>{row.isSepticSafe ? "Y" : "N"}</div>;
							},
						},
						{
							name: "NUMBER OF SHEETS PER CASE",
							field: "numberOfSheetsPerCase",
							hidden: isHidden("numberOfSheetsPerCase"),
						},
						{
							name: "LINEAR FT PER CASE",
							field: "linearFtPerCase",
							hidden: isHidden("linearFtPerCase"),
						},
						{
							name: "NET WEIGHT",
							field: "netWeight",
							hidden: isHidden("netWeight"),
						},
						{
							name: "OTHER",
							field: "other",
							hidden: isHidden("other"),
						},
						{
							name: "WILL PRODUCE IN COMPANIONS",
							field: "willProduceInCompanions",
							hidden: isHidden("willProduceInCompanions"),
							formattedField: (row) => {
								return <div>{row.willProduceInCompanions ? "Y" : "N"}</div>;
							},
						},
						{
							name: "NOTES",
							field: "notes",
							hidden: isHidden("notes"),
						},
						{
							name: "CREATED AT",
							field: "createdAt",
							formattedField: (row) => {
								return <div>{formatDate(new Date(row.createdAt), false)}</div>;
							},
						},
						{
							name: "UPDATED AT",
							field: "updatedAt",
							formattedField: (row) => {
								return <div>{formatDate(new Date(row.updatedAt), false)}</div>;
							},
						},
					]}
					rows={companyBidsheets}
					pagination={{
						pages,
						currentPage,
						onPageClick: (page: number) => {
							setState({
								currentPage: page,
							});
						},
					}}
				/>
			</div>
		</div>
	);
};

export default ActivityBidsheet;
