import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import React, { FormEvent, useEffect, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import ApolloInputSearch from "../../components/CustomInputSearch/ApolloInputSearch";
import Table from "../../components/Table";
import {
	CompanySearchFragment,
	SearchCompaniesDocument,
	SearchCompaniesQueryVariables,
	useAddActivityCompanyMutation,
	useDeleteActivityCompanyMutation,
	useGetActivityCompaniesLazyQuery,
} from "../../generated/graphql";
import { modalRenderAtom } from "../../state/modal.recoil";

type ActivityCompanyProps = {
	activityId: number;
};

type Notification = {
	isError: boolean;
	msg: string | undefined;
};

const ActivityCompanies = ({ activityId }: ActivityCompanyProps) => {
	//state hooks
	const [companyId, setCompanyId] = useState<number>(0);
	const [notification, setNotification] = useState<Notification>({
		isError: false,
		msg: undefined,
	});
	// const [activityCompanies, setActivityCompanies] = useState();

	//recoils / context
	const setModalRender = useSetRecoilState(modalRenderAtom);

	//custom hooks
	const [getActivityCompanies, { data }] = useGetActivityCompaniesLazyQuery({
		variables: {
			activityId,
		},
		fetchPolicy: "cache-and-network",
	});
	const [addActivityCompany] = useAddActivityCompanyMutation();
	const [deleteActivityCompany] = useDeleteActivityCompanyMutation();

	//functions
	const submit = (e: FormEvent) => {
		e.preventDefault();

		addActivityCompany({
			variables: {
				activityId: activityId,
				companyId: companyId,
			},
		})
			.then(() => {
				getActivityCompanies();
				setNotification({
					isError: false,
					msg: "Company added Succesfully",
				});
			})
			.catch(() => {
				setNotification({
					isError: true,
					msg: "Company not added, please try again. If problem persist please contact Admin.",
				});
			});
	};

	const deleteAC = (activityCompanyId: number) => {
		deleteActivityCompany({
			variables: {
				id: activityCompanyId,
			},
		})
			.then(() => {
				getActivityCompanies();
				setNotification({
					isError: false,
					msg: "Company deleted Succesfully",
				});
			})
			.catch(() => {
				setNotification({
					isError: true,
					msg: "Company not deleted, please try again. If problem persist pleas contact Admin.",
				});
			});
	};

	//React hooks
	useEffect(() => {
		getActivityCompanies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//jsx variables
	const activityCompanies = data?.getActivityCompanies?.nodes || [];

	return (
		<div>
			{notification.msg &&
				(notification.isError ? (
					<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
						<ExclamationIcon className="h-5 w-5" />
						{notification.msg}
					</div>
				) : (
					<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
						<CheckCircleIcon className="h-5 w-5" />
						{notification.msg}
					</div>
				))}
			<div>Invite a company to this activity</div>
			<form className="flex gap-4 my-3" onSubmit={(e) => submit(e)}>
				<div className="relative w-72">
					<ApolloInputSearch<CompanySearchFragment, SearchCompaniesQueryVariables>
						placeholder={"Company Name"}
						query={SearchCompaniesDocument}
						onGetQueryVariables={(searchStr) => {
							return {
								input: {
									pagination: {
										limit: 10,
									},
									filter: {
										likeName: searchStr !== "" ? "%" + searchStr.toUpperCase() + "%" : undefined,
									},
								},
							};
						}}
						onGetDisplayLabels={(value) => {
							return {
								resultLabel: value.name,
								selectedLabel: value.name,
							};
						}}
						onSelectSearchResult={(searchResult) => setCompanyId(searchResult.value.id)}
						className="border border-gray-400 rounded focus:outline-none focus:border-primary h-9 pl-1 w-full"
					/>
				</div>
				<Button type="submit"> Invite Company</Button>
			</form>
			{/*<Button onClick={(e) => updateAC(4)}>Update Company</Button> */}
			<Table
				columns={[
					{
						name: "Company",
						formattedField: (row) => {
							return <div>{row.company.name}</div>;
						},
					},
					{
						name: "Submitted",
						field: "submittedAt",
					},
					{
						name: "Delete",
						formattedField: (row) => {
							return (
								<button
									className="flex text-primary m-auto"
									onClick={() =>
										setModalRender(
											<div className="flex flex-col gap-5">
												<div className="my-5">
													Are you sure you want to delete <b>{row.company.name}</b>.
													<br />
													All progress from that company will be lost.
												</div>
												<Button
													onClick={(e) => {
														deleteAC(row.id);
														setModalRender(undefined);
													}}
												>
													Delete Company
												</Button>
											</div>,
										)
									}
								>
									<IoTrashOutline className="w-5 h-5 text-primary" />
								</button>
							);
						},
					},
				]}
				rows={activityCompanies}
			/>
		</div>
	);
};

export default ActivityCompanies;
