import { useRecoilValue } from "recoil";
import { userAtom } from "../state/user.recoil";
import { UserIcon } from "@heroicons/react/solid";
import { LogoutIcon } from "@heroicons/react/outline";
import { PathName } from "../constants/PathName";
import { Link, useHistory } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { useDeleteActivityActiveUserMutation, UserTypeNames } from "../generated/graphql";
import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { currentActivityActiveUserAtom } from "../state/activityActiveUsers.recoil";

const navigation = [
	{ name: "Users", href: "/users", current: false },
	{ name: "Companies", href: "/companies", current: false },
	{ name: "Activities", href: "/activities", current: false },
];

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(" ");
}

const Header: React.FC = () => {
	const user = useRecoilValue(userAtom);
	const history = useHistory();
	const goHome = () => {
		if (user!.typeUserType.name === UserTypeNames.CompanyUser) {
			history.push(PathName.ACTIVITIES);
		} else {
			history.push(PathName.ADMIN_DASHBOARD);
		}
	};
	const logout = useLogout();
	const [deleteActivityActiveUser] = useDeleteActivityActiveUserMutation();
	const currentActivityActiveUser = useRecoilValue(currentActivityActiveUserAtom);

	const preLogOut = async () => {
		if (currentActivityActiveUser) {
			await deleteActivityActiveUser({
				variables: {
					ActivityActiveUserId: currentActivityActiveUser.id,
				},
			});
		}
		logout();
	};

	return (
		<Disclosure as="nav" className="bg-white border-b border-gray-200">
			{({ open }) => (
				<>
					<div className="px-4 sm:px-6 lg:px-8">
						<div className="flex justify-between h-16">
							<div className="flex">
								<div className="flex-shrink-0 flex items-center">
									<div className="w-40 h-14 mr-4 flex cursor-pointer" onClick={goHome}>
										<img src="/img/unipro.svg" alt="" />
									</div>
								</div>
								{user!.typeUserType.name === UserTypeNames.Admin && (
									<>
										<div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
											{navigation.map((item) => (
												<Link
													key={item.name}
													to={item.href}
													className={classNames(
														item.current
															? "border-indigo-500 text-gray-900"
															: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
														"inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium",
													)}
													aria-current={item.current ? "page" : undefined}
												>
													<div className="text-primary">{item.name}</div>
												</Link>
											))}
										</div>
									</>
								)}
							</div>
							<div className="hidden sm:ml-6 sm:flex sm:items-center">
								<div className="relative w-max ">
									<div className="relative h-full z-10 px-3 bg-white flex w-max items-center pb-1">
										<UserIcon className="w-5 h-5 mr-2 text-primary" />
										{user!.typeUserType.name === UserTypeNames.CompanyUser && (
											<>
												<b className="text-primary">Company: </b>&nbsp;
												{user!.userCompanyUser!.company.name}
												&nbsp;&nbsp;&nbsp;
											</>
										)}
										<b className="text-primary">User: </b>&nbsp;{user!.username}
										<button
											className="ml-2 px-3 hover:bg-gray-100 flex gap-1 items-center rounded border border-primary"
											onClick={() => preLogOut()}
										>
											<LogoutIcon className="w-5 h-5 text-primary" />
											Log Out
										</button>
									</div>
								</div>
							</div>
							<div className="-mr-2 flex items-center sm:hidden">
								<Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									<span className="sr-only">Open main menu</span>
									{open ? (
										<XIcon className="block h-6 w-6" aria-hidden="true" />
									) : (
										<MenuIcon className="block h-6 w-6" aria-hidden="true" />
									)}
								</Disclosure.Button>
							</div>
						</div>
					</div>

					<Disclosure.Panel className="sm:hidden">
						<div className="pt-2 pb-3 space-y-1">
							{navigation.map((item) => (
								<a
									key={item.name}
									href={item.href}
									className={classNames(
										item.current
											? "bg-indigo-50 border-indigo-500 text-indigo-700"
											: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
										"block pl-3 pr-4 py-2 border-l-4 text-base font-medium",
									)}
									aria-current={item.current ? "page" : undefined}
								>
									{item.name}
								</a>
							))}
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
		// <div className="container relative mx-auto flex justify-between border-b border-gray-300">
		// 	<div className="flex items-center">
		// 		<div className="w-40 h-14 mr-4 flex cursor-pointer" onClick={goHome}>
		// 			<img src="/img/unipro.svg" alt="" />
		// 		</div>
		// 	</div>

		// 	<div className="relative w-max ">
		// 		<div
		// 			className="relative h-full z-10 px-3 bg-white
		//          flex w-max items-end pb-1"
		// 		>
		// 			<UserIcon className="w-5 h-5 mr-2 text-primary" />
		// 			{user!.typeUserType.name === UserTypeNames.CompanyUser && (
		// 				<>
		// 					<b className="text-primary">Company: </b>&nbsp;{companyUser!.company.name}
		// 					&nbsp;&nbsp;&nbsp;
		// 				</>
		// 			)}
		// 			<b className="text-primary">User: </b>&nbsp;{user!.username}
		// 			<button
		// 				className="ml-2 px-3 hover:bg-gray-100 flex gap-1 items-center rounded border border-primary"
		// 				onClick={() => logout()}
		// 			>
		// 				<LogoutIcon className="w-5 h-5 text-primary" />
		// 				Log Out
		// 			</button>
		// 		</div>
		// 	</div>
		// 	<div className="absolute shadow-md bottom-0 w-full h-1"></div>
		// </div>
	);
};

export default Header;
