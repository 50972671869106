import React from "react";
import Select from "react-select";

const SelectEditor: React.FC<any> = ({ value, onCommit, onRevert, options }) => {
	const customStyles = {
		control: () => ({
			width: "100%",
			display: "flex",
		}),
		valueContainer: (provided: any) => ({
			...provided,
			padding: 0,
		}),
		input: (provided: any) => ({
			...provided,
			padding: 0,
			paddingLeft: 10,
			margin: 0,
		}),
		placeholder: (provided: any) => ({
			...provided,
			paddingLeft: 10,
		}),
		indicatorSeparator: () => ({}),
		dropdownIndicator: (provided: any) => ({
			...provided,
			padding: 0,
		}),

		option: (provided: any, state: any) => ({
			...provided,
		}),

		singleValue: (provided: any, state: any) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";

			return { ...provided, opacity, transition };
		},
	};

	const handleChange = (opt: any) => {
		if (!opt) {
			return onRevert();
		}
		onCommit(opt.value);
	};

	return (
		<>
			<Select
				autoFocus
				openOnFocus
				closeOnSelect
				value={value}
				openMenuOnFocus
				onChange={handleChange}
				options={options}
				className="datasheet-select"
				styles={customStyles}
			/>
		</>
	);
};

export default SelectEditor;
