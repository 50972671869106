import { format, formatISO, parseISO } from "date-fns";
import { useState } from "react";

export const extractGQLError = (err: any) => {};
export const isNumber = (val: any): boolean => {
	return val !== null && val !== undefined && val !== "" && !isNaN(val);
};

export const formatCurrency = (val: any): string => {
	if (isNumber(val)) {
		let num = +val;
		return num.toLocaleString("en-US", { style: "currency", currency: "USD" });
	}

	return val;
};

export const formatLocaleNumber = (val: any): string => {
	if (isNumber(val)) {
		let num = +val;
		return num.toLocaleString("en-US");
	}

	return val;
};

export const downloadStr = (str: string, name: string) => {
	const url = window.URL.createObjectURL(new Blob([str]));
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", name);
	document.body.appendChild(link);
	link.click();
};

// yyyy-MM-dd to MM-dd-yyyy
export const formatDateStr = (
	dateStr: string | null | undefined,
	hasTime: boolean,
	delimeter = "-",
): string => {
	if (dateStr) {
		return format(
			parseISO(dateStr),
			`MM${delimeter}dd${delimeter}yyyy` + (hasTime ? " hh:mm:ss aaaaa'm'" : ""),
		);
	} else {
		return "";
	}
};

// yyyy-MM-dd to MM-dd-yyyy
export const formatDate = (
	date: Date | null | undefined,
	hasTime: boolean,
	delimeter = "-",
): string => {
	if (date) {
		return format(date, `MM${delimeter}dd${delimeter}yyyy` + (hasTime ? " hh:mm:ss aaaaa'm'" : ""));
	} else {
		return "";
	}
};

// yyyy-MM-dd
export const formatDateISO = (date: Date | null | undefined): string => {
	const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
	if (date) {
		const correctDate = new Date(date).getTime() + timeZoneOffset;
		return formatISO(new Date(correctDate), {
			representation: "date",
		});
	} else {
		return "";
	}
};

// export const formatDateYMD = (date: Date | null | undefined): string => {
// 	if (date) {
// 		// const offset = new Date()
// 		return format(date, "yyyy-MM-dd");
// 	} else {
// 		return "";
// 	}
// };
