import { formatCurrency, formatLocaleNumber } from "../../utils/utils";
import SelectEditor from "../../components/SelectEditor";
import { useRecoilValue } from "recoil";
import { activityAtom } from "../../state/activityRecoil";

export type Cell = {
	value: any;
	className: string;
	readOnly?: boolean;
	type?: string;
	format?: (val: any) => string;
	bidSheetProductId?: number;
	activityProductId?: number;
	rowSpan?: number;
	colSpan?: number;
	width?: string | number;
	isRequired?: boolean;
	strLength?: number;
	dataEditor?: any;
	colName?: string;
	field: string;
};

const tierOptions = [
	{ label: "Good", value: "good" },
	{ label: "Better", value: "better" },
	{ label: "Best", value: "best" },
];

const yesnoOptions = [
	{ label: "Yes", value: "Y" },
	{ label: "No", value: "N" },
];

export const ACTIVITY_PRODUCT_COLUMNS: Cell[] = [
	{
		value: "RFP ITEM #",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "",
		type: "string",
		field: "rfpItemNumber",
	},
	{
		value: "TIER",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "tier",
	},
	{
		value: "CATEGORY",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "cat",
	},
	{
		value: "SUBCATEGORY",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "sub",
	},
	{
		value: "TYPE",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "type",
	},
	{
		value: "MIL/MIC/GAUGE",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "milMicGauge",
	},
	{
		value: "DESCRIPTION",
		readOnly: true,
		className: "activity-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		type: "string",
		field: "newCatColor",
	},
	{
		value: "TOTAL",
		readOnly: true,
		className: "activity-title",
		rowSpan: 1,
		colSpan: 1,
		width: "auto",
		type: "number",
		format: formatLocaleNumber,
		field: "total",
	},
	{
		value: "EAST",
		readOnly: true,
		className: "activity-title",
		rowSpan: 1,
		colSpan: 1,
		width: "auto",
		type: "number",
		format: formatLocaleNumber,
		field: "east",
	},
	{
		value: "WEST",
		readOnly: true,
		className: "activity-title",
		rowSpan: 1,
		colSpan: 1,
		width: "auto",
		type: "number",
		format: formatLocaleNumber,
		field: "west",
	},
];
export const ACTIVITY_PRODUCT_COLUMNS_LENGTH = ACTIVITY_PRODUCT_COLUMNS.length;

export const BID_SHEET_PRODUCT_COLUMNS: Cell[] = [
	//editable side
	{
		value: "NET DELIVERED $$/CASE - WEST",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 160,
		isRequired: true,
		format: formatCurrency,
		type: "number",
		field: "netDeliveredCaseWest",
	},
	{
		value: "NET DELIVERED $$/CASE - EAST",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 160,
		isRequired: true,
		format: formatCurrency,
		type: "number",
		field: "netDeliveredCaseEast",
	},
	{
		value: "MANUFACTURER NAME",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 180,
		isRequired: true,
		type: "string",
		field: "manufName",
	},
	{
		value: "EQUIVALENT MFR #",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 120,
		isRequired: true,
		type: "string",
		field: "equivMfrNumber",
	},
	{
		value: "TIER",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 100,
		isRequired: true,
		type: "string",
		dataEditor: (a: any) => <SelectEditor {...a} options={tierOptions} />,
		field: "bidTier",
	},
	{
		value: "MANUFACTURER PRODUCT DESCRIPTION",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 250,
		isRequired: true,
		type: "string",
		field: "manufacturerProductDescription",
	},
	{
		value: "EQUIVALENT MFR BRAND",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 180,
		isRequired: true,
		type: "string",
		field: "brand",
	},
	{
		value: "GTIN #",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		strLength: 20,
		field: "gtinNumber",
	},
	{
		value: "MATERIAL",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		field: "material",
	},
	{
		value: "GAUGE",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		field: "gauge",
	},
	{
		value: "ROLL OR FLAT PACK",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		field: "rollOrFlatPack",
	},
	{
		value: "PACK/SIZE",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		field: "packSize",
	},
	{
		value: "Septic Safe (Y/N)",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 100,
		isRequired: true,
		type: "boolean",
		dataEditor: (a: any) => <SelectEditor {...a} options={yesnoOptions} />,
		field: "isSepticSafe",
	},
	{
		value: "# OF SHEETS PER CASE",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 140,
		isRequired: true,
		type: "number",
		field: "numberOfSheetsPerCase",
	},
	{
		value: "LINEAR FT PER CASE",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 120,
		isRequired: true,
		type: "number",
		field: "linearFtPerCase",
	},
	{
		value: "NET WEIGHT (LBS.)",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 130,
		isRequired: true,
		type: "number",
		field: "netWeight",
	},
	{
		value: "OTHER",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: "auto",
		isRequired: true,
		type: "string",
		field: "other",
	},
	{
		value: "BASED ON VOLUME WILL YOU PRODUCE IN COMPANIONS Y/N",
		readOnly: true,
		className: "bidsheet-title text-xs",
		rowSpan: 2,
		colSpan: 1,
		width: 220,
		isRequired: true,
		type: "boolean",
		dataEditor: (a: any) => <SelectEditor {...a} options={yesnoOptions} />,
		field: "willProduceInCompanions",
	},
	{
		value: "NOTES",
		readOnly: true,
		className: "bidsheet-title",
		rowSpan: 2,
		colSpan: 1,
		width: 100,
		type: "string",
		field: "notes",
	},
];

export const HEADER_ROW = (hiddenCols: string) => {
	let row: Cell[] = [];

	const hiddenColsArr = (hiddenCols && hiddenCols.split(",")) || [];

	ACTIVITY_PRODUCT_COLUMNS.forEach((act) => {
		if (act.value !== "TOTAL" && act.value !== "EAST" && act.value !== "WEST") {
			if (!(hiddenColsArr.length > 0 && hiddenColsArr.includes(act.field))) {
				row.push(act);
			}
		}
	});

	if (
		!(
			hiddenColsArr.length > 0 &&
			hiddenColsArr.includes("total") &&
			hiddenColsArr.includes("east") &&
			hiddenColsArr.includes("west")
		)
	) {
		let count = 0;
		hiddenColsArr.includes("total") && count++;
		hiddenColsArr.includes("east") && count++;
		hiddenColsArr.includes("west") && count++;

		row.push({
			value: "COMMITED ANNUAL CASES",
			readOnly: true,
			className: "activity-title",
			rowSpan: 1,
			colSpan: 3 - count,
			width: "auto",
			field: "commAnnualCasesBlock",
		});
	}

	BID_SHEET_PRODUCT_COLUMNS.forEach((bid) => {
		if (!(hiddenColsArr.length > 0 && hiddenColsArr.includes(bid.field))) {
			row.push(bid);
		}
	});

	return row;
};

//layot [value, readOnly, className, rowSpan, colSpan, width],
export const SUB_HEADER_ROW = (hiddenCols: string) => {
	let row: Cell[] = [];

	const hiddenColsArr = (hiddenCols && hiddenCols.split(",")) || [];

	row.push({
		value: "",
		readOnly: true,
		className: "opacity-0",
		rowSpan: 1,
		colSpan: 1,
		width: "auto",
		field: "empty",
	});

	ACTIVITY_PRODUCT_COLUMNS.forEach((act) => {
		if (act.value === "TOTAL" || act.value === "EAST" || act.value === "WEST") {
			if (!(hiddenColsArr.length > 0 && hiddenColsArr.includes(act.field))) {
				row.push(act);
			}
		}
	});

	return row;
};
