import { LocalStorageKey } from "../constants/LocalStorageKey";

export const useLogout = () => {
	// @TODO this can be more graceful by setting all states to default value then using history.push instead of reload
	const logout = () => {
		localStorage.removeItem(LocalStorageKey.TOKEN);
		window.location.reload();
	};

	return logout;
};

export default useLogout;
