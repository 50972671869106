import React from "react";
import { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { ActivitySurveyItem } from "../../../generated/graphql";
import { useStates } from "../../../hooks/useStates";
import { ActivitySurveyItemInput } from "../../../generated/graphql";
import { EnumActivitySurveyItemType } from "./enum";
import { BsCursorText } from "react-icons/bs";
import { TiSortNumerically } from "react-icons/ti";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";
import { MdDateRange, MdFileUpload } from "react-icons/md";

interface Props {
	preview: boolean;
	surveyId: number;
	keyIndex: number;
	inputType: string;
	answer?: string;
	activitySurveyItem: ActivitySurveyItem;
	delete: (id: number, index: number) => void;
	update: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	updateAnswer: (id: number, updateValue: string, updateComment: string) => void;
	sortChange: (indexChange: number, direction?: number) => void;
}

type State = {
	title: string;
	description: string;
	saveValue: string;
	required: boolean;
};
const SurveyItemTextbox: React.FC<Props> = (props) => {
	const [{ title, description, required, saveValue }, setState] = useStates<State>({
		title: props.activitySurveyItem.title,
		description: props.activitySurveyItem.description ?? "",
		required: props.activitySurveyItem.required,
		saveValue: "",
	});
	useEffect(() => {
		if (props.activitySurveyItem.itemType !== EnumActivitySurveyItemType.File_upload)
			setState({
				saveValue: props.answer,
			});
	}, [props.answer]);
	const onChange = (val: string) => {
		setState({
			title: val,
		});
	};
	const onChange2 = (val: string) => {
		setState({
			description: val,
		});
	};
	const onChangeRequired = () => {
		setState({
			required: !required,
		});
	};
	const onBlur = () => {
		updateItem();
	};
	const updateItem = () => {
		props.update(props.activitySurveyItem.id, {
			activitySurveyId: props.surveyId,
			section: props.activitySurveyItem.section,
			itemType: props.activitySurveyItem.itemType,
			title: title,
			description: description,
			sortOrder: props.activitySurveyItem.sortOrder,
			required: required,
		});
	};

	const onChangeSaveValue = (val: string) => {
		setState({
			saveValue: val,
		});
	};
	const onBlurSave = () => {
		updateAnswer();
	};
	const updateAnswer = () => {
		props.updateAnswer(props.activitySurveyItem.id, saveValue, "");
	};
	return (
		<>
			{props.preview ? (
				<div className="survey-card flex gap-3 w-full">
					<div className="number-tag h-10">{props.keyIndex + 1}</div>
					<div className="prev-question">
						<div className="question">{props.activitySurveyItem.title}</div>
						<div className="text-sm text-gray-400">{props.activitySurveyItem.description}</div>
						<input
							className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-1 w-full "
							type={props.inputType}
							onChange={(e) => onChangeSaveValue(e.target.value)}
							onBlur={(e) => onBlurSave()}
							placeholder="Answer"
							value={saveValue}
						></input>
					</div>
				</div>
			) : (
				<div className="survey-card">
					<div className="question-header">
						<div className="flex items-center">
							<div className="number-tag-type">{props.keyIndex + 1}</div>
							<div className="question-type-tag">
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.Numeric && (
									<TiSortNumerically className="w-5 h-5 text-gray-600" />
								)}
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.Date && (
									<MdDateRange className="w-5 h-5 text-gray-600" />
								)}
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.Textbox && (
									<BsCursorText className="w-5 h-5 text-gray-600" />
								)}
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.File_upload && (
									<MdFileUpload className="w-5 h-5 text-gray-600" />
								)}
								<div className="text-gray-600 pl-2">{props.inputType}</div>
							</div>
						</div>

						<div className="flex gap-3">
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 1)}
							>
								<HiOutlineArrowDown className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 0)}
							>
								<HiOutlineArrowUp className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-primary"
								onClick={() => {
									props.delete(props.activitySurveyItem.id, props.keyIndex);
								}}
							>
								<TrashIcon className="w-5 h-5" />
							</div>
						</div>
					</div>

					<div className="">
						<input
							onChange={(e) => onChange(e.target.value)}
							onBlur={(e) => onBlur()}
							type="Text"
							placeholder="What's your question?"
							value={title}
						></input>

						<textarea
							onChange={(e) => onChange2(e.target.value)}
							onBlur={(e) => onBlur()}
							placeholder="Describe if necessary"
							value={description}
							rows={2}
						></textarea>
					</div>

					<br />
					<div className="question-footer">
						<div className="flex mt-4">
							<label className="switch switch-left-right">
								<input
									className="switch-input"
									type="checkbox"
									checked={required}
									onChange={(e) => onChangeRequired()}
									onBlur={(e) => onBlur()}
								/>
								<span className="switch-label" data-on="" data-off=""></span>
								<span className="switch-handle"></span>
							</label>
							<div> Required</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyItemTextbox;
