import { RecoilRoot } from "recoil";
import "./env";
import Main from "./views/Main";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./apollo.client";

// load css here
import "./App.scss";
import TopModal from "./components/topModal/TopModal";
import Loading from "./components/Loading";

function App() {
	return (
		<div>
			<ApolloProvider client={apolloClient}>
				<RecoilRoot>
					<Main />
					<TopModal />
					<Loading />
				</RecoilRoot>
			</ApolloProvider>
		</div>
	);
}

export default App;
