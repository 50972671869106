import { DocumentNode } from "@apollo/client";
import { InputHTMLAttributes, useCallback, useEffect, useRef } from "react";
import { apolloClient } from "../../apollo.client";
import { useStates } from "../../hooks/useStates";
import InputSearch, { InputSearchResult } from "../InputSearch/InputSearch";

export interface ApolloInputSearchProps<TSearchResultValue, TQueryVariables>
	extends InputHTMLAttributes<HTMLInputElement> {
	query: DocumentNode;
	onGetQueryVariables: (searchStr: string) => TQueryVariables;
	onGetDisplayLabels: (
		value: TSearchResultValue,
	) => Pick<InputSearchResult<TSearchResultValue>, "resultLabel" | "selectedLabel">;
	onSelectSearchResult?: (searchResult: InputSearchResult<TSearchResultValue>) => void;
	shouldSearchOnMount?: boolean; // start first search onMount otherwise start/enable search onFocus
	inSearchStr?: string;
}

type State<TSearchResultValue> = {
	searchStr: string;
	searchResults: InputSearchResult<TSearchResultValue>[];
};

/**
 * Query return type must be { rows: TSearchResultValue[] }
 */
const ApolloInputSearch = <TSearchResultValue, TQueryVariables>({
	query,
	onGetQueryVariables,
	onGetDisplayLabels,
	onSelectSearchResult,
	shouldSearchOnMount,
	inSearchStr,
	onChange,
	onFocus,
	...inputProps
}: ApolloInputSearchProps<TSearchResultValue, TQueryVariables>) => {
	// state hooks
	const [{ searchStr, searchResults }, setState] = useStates<State<TSearchResultValue>>({
		searchStr: "",
		searchResults: [],
	});

	const canSearch = useRef(!!shouldSearchOnMount);

	// recoils

	// custom hooks

	// local variables

	// functions
	const search = useCallback(() => {
		if (!canSearch.current) {
			return;
		}

		setState({
			searchResults: [
				{
					value: 0 as any,
					resultLabel: "Searching...",
					selectedLabel: "",
					isNotSelectable: true,
				},
			],
		});

		apolloClient
			.query({
				query,
				variables: onGetQueryVariables(searchStr),
				fetchPolicy: "no-cache",
			})
			.then((res) => {
				const searchResultsTemp = res.data[Object.keys(res.data)[0]].nodes.map(
					(row: TSearchResultValue) => {
						return {
							value: row,
							...onGetDisplayLabels(row),
						};
					},
				);

				setState({
					searchResults: searchResultsTemp,
				});
			})
			.catch(() => {
				// @TODO

				setState({
					searchResults: [],
				});
			});
	}, [searchStr, query, onGetQueryVariables, onGetDisplayLabels]);

	// React hooks
	useEffect(() => {
		search();
	}, [searchStr]);

	useEffect(() => {
		setState({
			searchResults: [],
		});
	}, [search]);

	useEffect(() => {
		setState({
			searchStr: inSearchStr !== undefined ? inSearchStr : "",
		});
	}, [inSearchStr]);

	// jsx variables

	return (
		<InputSearch
			{...inputProps}
			searchResults={searchResults}
			onSelectSearchResult={(searchResult) => {
				onSelectSearchResult?.(searchResult);
				setState({
					searchStr: searchResult.selectedLabel,
				});
			}}
			value={searchStr}
			onChange={(e) => {
				setState({
					searchStr: e.target.value,
				});
				onChange?.(e);
			}}
			onFocus={(e) => {
				if (!canSearch.current) {
					canSearch.current = true;
					search();
				}
				onFocus?.(e);
			}}
		/>
	);
};

export default ApolloInputSearch;
