import React from "react";
import { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { ActivitySurveyItem } from "../../../generated/graphql";
import { useStates } from "../../../hooks/useStates";
import { ActivitySurveyItemInput } from "../../../generated/graphql";
import { EnumActivitySurveyItemType } from "./enum";
import { MdTextFields } from "react-icons/md";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";

interface Props {
	preview: boolean;
	surveyId: number;
	activitySurveyItem: ActivitySurveyItem;
	keyIndex: number;
	delete: (id: number, index: number) => void;
	update: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	sortChange: (indexChange: number, direction?: number) => void;
}

type State = {
	title: string;
	description: string;
};
const SurveyItemLabel: React.FC<Props> = (props) => {
	const [{ title, description }, setState] = useStates<State>({
		title: props.activitySurveyItem.title,
		description: props.activitySurveyItem.description ?? "",
	});
	const onChange = (val: string) => {
		setState({
			title: val,
		});
	};
	const onChange2 = (val: string) => {
		setState({
			description: val,
		});
	};

	const onBlur = () => {
		updateProp();
	};
	useEffect(() => {
		updateProp();
	}, [title, description]);

	const updateProp = () => {
		props.update(props.activitySurveyItem.id, {
			activitySurveyId: props.surveyId,
			section: props.activitySurveyItem.section,
			itemType: EnumActivitySurveyItemType.Label,
			title: title,
			description: description,
			sortOrder: props.activitySurveyItem.sortOrder,
			required: false,
		});
	};

	return (
		<>
			{props.preview ? (
				<>
					<div className="font-bold text-lg">{props.activitySurveyItem.title}</div>
					<div className="text-sm text-gray-400">{props.activitySurveyItem.description}</div>
					<br />
				</>
			) : (
				<div className="survey-card">
					<div className="question-header">
						<div className="flex items-center">
							<div className="label-type-tag">
								<MdTextFields className="w-5 h-5 text-gray-600" />

								<div className="text-gray-600 pl-2">Label</div>
							</div>
						</div>

						<div className="flex gap-3">
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 1)}
							>
								<HiOutlineArrowDown className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 0)}
							>
								<HiOutlineArrowUp className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-primary"
								onClick={() => {
									props.delete(props.activitySurveyItem.id, props.keyIndex);
								}}
							>
								<TrashIcon className="w-5 h-5" />
							</div>
						</div>
					</div>

					<div>
						<input
							onChange={(e) => onChange(e.target.value)}
							onBlur={(e) => onBlur()}
							type="Text"
							placeholder="enter label here"
							value={title}
						></input>
						<textarea
							onChange={(e) => onChange2(e.target.value)}
							onBlur={(e) => onBlur()}
							placeholder="Describe if necessary"
							value={description}
							rows={2}
						></textarea>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyItemLabel;
