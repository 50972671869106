import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Table from "../../components/Table";
import { ChevronDownIcon, ChevronUpIcon, FilterIcon, PlusIcon } from "@heroicons/react/solid";
import { modalRenderAtom } from "../../state/modal.recoil";
import UserForm from "./UserForm";
import { useSetRecoilState } from "recoil";
import { useStates } from "../../hooks/useStates";
import {
	OrderBy,
	useDeleteUserMutation,
	useGetCompanyUsersLazyQuery,
} from "../../generated/graphql";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { pushToOrderBys } from "../../utils/GraphQLUtils";
import { GrEdit } from "react-icons/gr";
import { IoTrashOutline } from "react-icons/io5";

const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
	orderBys: OrderBy[];
};

const Users: React.FC = () => {
	const [{ currentPage, orderBys }, setState] = useStates<State>({
		currentPage: 0,
		orderBys: [],
	});
	const [filter, setFilter] = useState("");

	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const [getCompanyUsers, getCompanyUsersRes] = useGetCompanyUsersLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [deleteUser] = useDeleteUserMutation();

	const getCompanyUsersByPage = (page: number) => {
		getCompanyUsers({
			variables: {
				getCompanyUsersInput: {
					pagination: {
						offset: page * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
					orderBys,
					filter: {
						likeAny: filter,
					},
				},
			},
		});
	};

	const resetUsersTable = () => {
		if (currentPage === 0) {
			getCompanyUsersByPage(0);
		} else {
			setState({ currentPage: 0 });
		}
		setTimeout(() => {
			setModalRender(undefined);
		}, 1000);
	};

	const _deleteUser = (id: number) => {
		setLoadingMessage("Deleting User");
		deleteUser({
			variables: {
				userId: id,
			},
		})
			.then(() => {
				resetUsersTable();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
				setModalRender(undefined);
			});
	};

	useEffect(() => {
		getCompanyUsersByPage(currentPage);
	}, [currentPage, orderBys]);

	useEffect(() => {
		resetUsersTable();
	}, [filter, orderBys]);

	useEffect(() => {
		if (getCompanyUsersRes.called) {
			if (getCompanyUsersRes.loading) {
				setLoadingMessage("Loading Users");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getCompanyUsersRes.called, getCompanyUsersRes.loading, getCompanyUsersRes.data]);

	const companyUsers = getCompanyUsersRes?.data?.getCompanyUsers?.nodes || [];
	const pages = (getCompanyUsersRes.data?.getCompanyUsers?.totalCount || 0) / PAGE_ITEM_COUNT;

	const deletePrompt = (id: number) => {
		return (
			<div className="w-80 flex flex-col justify-center items-center self-center mb-7">
				<div className="my-5">Are you sure you want to delete this user?</div>
				<div className="flex gap-4">
					<button
						className="w-max px-4 py-1 rounded border border-gray-300 hover:bg-gray-400"
						onClick={() => setModalRender(undefined)}
					>
						No
					</button>
					<button
						className="w-max px-4 py-1 rounded bg-primary hover:bg-red-900 text-white"
						onClick={() => _deleteUser(id)}
					>
						Yes
					</button>
				</div>
			</div>
		);
	};

	return (
		<div className="container mx-auto p-3">
			<Header />
			<div className="flex flex-row  justify-between my-10 text-2xl">
				<b>Users</b>
				<br />
				<button
					className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center hover:bg-red-800"
					onClick={() => setModalRender(<UserForm onSuccess={resetUsersTable} />)}
				>
					<PlusIcon className="w-6 h-6 mr-1" />
					Add New User
				</button>
			</div>

			<div className="relative w-full my-3">
				<input
					className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-80"
					onChange={(e) => setFilter(e.target.value)}
					value={filter}
					placeholder="filter by any field (Name, Email, ...)"
				></input>
				<FilterIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
			</div>

			<div>
				<Table
					columns={[
						{
							name: "Name",
							field: "name",
							isSortable: true,
						},
						{
							name: "Email",
							formattedField: (row) => {
								return <div>{row.user.username}</div>;
							},
							isSortable: true,
						},
						{
							name: "Position",
							field: "title",
							isSortable: true,
						},
						{
							name: "Company",
							formattedField: (row) => {
								return <div>{row.company.name}</div>;
							},
							isSortable: true,
						},
						{
							name: "Update",
							formattedField: (row) => {
								return (
									<button
										className="flex text-primary m-auto"
										onClick={() =>
											setModalRender(<UserForm companyUser={row} onSuccess={resetUsersTable} />)
										}
									>
										<GrEdit className="w-5 h-5 text-primary" />
									</button>
								);
							},
						},
						{
							name: "Delete",
							formattedField: (row) => {
								return (
									<button
										className="flex text-primary m-auto"
										onClick={() => setModalRender(deletePrompt(row.user.id))}
									>
										<IoTrashOutline className="w-5 h-5 text-primary" />
									</button>
								);
							},
						},
					]}
					rows={companyUsers}
					pagination={{
						pages,
						currentPage,
						onPageClick: (page: number) => {
							setState({
								currentPage: page,
							});
						},
					}}
					onSort={(field, isDesc) => {
						if (field) {
							setState({
								orderBys: pushToOrderBys(orderBys, field, isDesc),
							});
						}
					}}
					ascIcon={<ChevronUpIcon className="h-5 w-5" />}
					descIcon={<ChevronDownIcon className="h-5 w-5" />}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default Users;
