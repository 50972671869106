import React from "react";
import {
	ActivitySurveyItem,
	ActivitySurveyItemInput,
	ActivitySurveyItemOptionInput,
} from "../../../generated/graphql";
import Textbox from "./textbox";
import Textarea from "./textarea";
import Dropdown from "./dropdown";
import Checkbox from "./checkbox";
import Radio from "./radio";
import Label from "./label";
import Missing from "./missing";
import FileUpload from "./fileUpload";

import { EnumActivitySurveyItemType } from "./enum";

interface Props {
	preview: boolean;
	surveyId: number;
	keyIndex: number;
	answer?: string;
	answerComment?: string;
	activitySurveyItem: ActivitySurveyItem;
	update?: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	delete?: (id: number, index: number) => void;
	addOption?: (activitySurveyItemOptionInput: ActivitySurveyItemOptionInput) => void;
	updateOption?: (id: number, activitySurveyItemOptionInput: ActivitySurveyItemOptionInput) => void;
	deleteOption?: (id: number, index?: number, parentIndex?: number) => void;
	updateAnswer?: (id: number, updateValue: string, updateComment: string) => void;
	updateFile?: (
		file: Blob | null,
		activitySurveyItemId: number,
		activitySurveyItemInput: ActivitySurveyItemInput,
	) => void;
	sortChange?: (indexChange: number, direction?: number) => void;
	addAddition?: (ActivitySurveyItem: ActivitySurveyItem, itemType: number) => void;
	getValueForItem?: (activitySurveyItem: ActivitySurveyItem) => string;
	// getFileName?: (activitySurveyItemId: number) => void;
	// fileName?: File | null;
}

const SurveyItem: React.FC<Props> = (props) => {
	const renderItemSwitch = (param: EnumActivitySurveyItemType) => {
		switch (param) {
			case EnumActivitySurveyItemType.Textbox:
				return (
					<Textbox
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						answer={props.answer}
						activitySurveyItem={props.activitySurveyItem}
						inputType="Text"
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Textbox>
				);
			case EnumActivitySurveyItemType.Numeric:
				return (
					<Textbox
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						activitySurveyItem={props.activitySurveyItem}
						answer={props.answer}
						inputType="Number"
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Textbox>
				);
			case EnumActivitySurveyItemType.Date:
				return (
					<Textbox
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						activitySurveyItem={props.activitySurveyItem}
						answer={props.answer}
						inputType="Date"
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Textbox>
				);
				break;
			case EnumActivitySurveyItemType.Textarea:
				return (
					<Textarea
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						answer={props.answer}
						activitySurveyItem={props.activitySurveyItem}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Textarea>
				);
				break;
			case EnumActivitySurveyItemType.File_upload:
				return (
					<FileUpload
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						inputType="File"
						activitySurveyItem={props.activitySurveyItem}
						answer={props.answer}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						updateFile={props.updateFile ? props.updateFile : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></FileUpload>
				);
				break;
			case EnumActivitySurveyItemType.Dropdown:
				return (
					<Dropdown
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						activitySurveyItem={props.activitySurveyItem}
						answer={props.answer}
						answerComment={props.answerComment}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						addOption={props.addOption ? props.addOption : () => {}}
						updateOption={props.updateOption ? props.updateOption : () => {}}
						deleteOption={props.deleteOption ? props.deleteOption : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
						addAddition={props.addAddition ? props.addAddition : () => {}}
						getValueForItem={props.getValueForItem ? props.getValueForItem : () => ""}
					></Dropdown>
				);
				break;
			case EnumActivitySurveyItemType.Checkbox:
				return (
					<Checkbox
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						itemType="Checkbox"
						activitySurveyItem={props.activitySurveyItem}
						answer={props.answer}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						addOption={props.addOption ? props.addOption : () => {}}
						updateOption={props.updateOption ? props.updateOption : () => {}}
						deleteOption={props.deleteOption ? props.deleteOption : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Checkbox>
				);
				break;
			case EnumActivitySurveyItemType.Radio:
				return (
					<Radio
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						itemType="Radio"
						answer={props.answer}
						activitySurveyItem={props.activitySurveyItem}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						addOption={props.addOption ? props.addOption : () => {}}
						updateOption={props.updateOption ? props.updateOption : () => {}}
						deleteOption={props.deleteOption ? props.deleteOption : () => {}}
						updateAnswer={props.updateAnswer ? props.updateAnswer : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Radio>
				);
				break;

			case EnumActivitySurveyItemType.Label:
				return (
					<Label
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						activitySurveyItem={props.activitySurveyItem}
						update={props.update ? props.update : () => {}}
						delete={props.delete ? props.delete : () => {}}
						sortChange={props.sortChange ? props.sortChange : () => {}}
					></Label>
				);
				break;
			default:
				// return "Missing Area";
				return (
					<Missing
						preview={props.preview}
						surveyId={props.surveyId}
						keyIndex={props.keyIndex}
						activitySurveyItem={props.activitySurveyItem}
						delete={props.delete ? props.delete : () => {}}
					></Missing>
				);
		}
	};
	return <>{renderItemSwitch(props.activitySurveyItem.itemType)}</>;
};

export default SurveyItem;
