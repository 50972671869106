import React, { useEffect, useState } from "react";
import { GrCycle } from "react-icons/gr";
import { DownloadIcon, FilterIcon, XIcon } from "@heroicons/react/solid";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import {
	CompaniesBidsheetProductFilter,
	useGetBidSheetAsCsvLazyQuery,
	useGetActivitySurveyAnswersAsCsvLazyQuery,
	OrderBy,
} from "../../generated/graphql";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { downloadStr, formatDateISO } from "../../utils/utils";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import Table from "../../components/Table";
import {
	GetActivitySurveyCompanyAnswersOutput,
	useGetActivitySurveyAnswersQuery,
} from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { pushToOrderBys } from "../../utils/GraphQLUtils";

const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
	orderBys: OrderBy[];
	filter: string;
	pages: number;
	currentRows: Array<any>;
};

const ResultSurvey: React.FC = () => {
	const [{ currentPage, orderBys, filter, pages, currentRows }, setState] = useStates<State>({
		currentPage: 0,
		orderBys: [],
		filter: "",
		pages: 0,
		currentRows: [],
	});
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const activity = useRecoilValue(activityAtom)!;
	const [getActivitySurveyAnswersAsCSV, getActivitySurveyAnswersAsCSVRes] =
		useGetActivitySurveyAnswersAsCsvLazyQuery({
			variables: {
				id: activity.id,
			},
			fetchPolicy: "network-only",
		});

	const downloadAnswersAsCSV = () => {
		setLoadingMessage("Downloading Bid Sheet CSV");
		getActivitySurveyAnswersAsCSV();
	};
	useEffect(() => {
		if (getActivitySurveyAnswersAsCSVRes.called && !getActivitySurveyAnswersAsCSVRes.loading) {
			if (getActivitySurveyAnswersAsCSVRes.data) {
				downloadStr(
					getActivitySurveyAnswersAsCSVRes.data.getActivitySurveyAnswersAsCSV,
					formatDateISO(new Date()) + "_BidSheet.csv",
				);
			}
			setLoadingMessage(undefined);
		}
	}, [
		getActivitySurveyAnswersAsCSVRes.called,
		getActivitySurveyAnswersAsCSVRes.data,
		getActivitySurveyAnswersAsCSVRes.loading,
	]);
	const { data, loading, error, called } = useGetActivitySurveyAnswersQuery({
		variables: {
			activityId: activity.id,
		},
	});

	const getCurrentRowsByPage = (page: number) => {
		if (rows.length > 0) {
			let newRows = [...rows]; //copy from original
			//Filter
			newRows = newRows.filter((el) => {
				return Object.keys(el).some((key) =>
					String(el[key]).toLowerCase().includes(filter.toLowerCase()),
				);
			});

			//Sort
			if (orderBys.length > 0) {
				if (orderBys[0].by === "id") {
					if (orderBys[0].isDesc) {
						newRows.sort((a, b) => b.id - a.id);
					} else {
						newRows.sort((a, b) => a.id - b.id);
					}
				} else {
					//any string
					if (orderBys[0].isDesc) {
						newRows.sort((a, b) =>
							(a[orderBys[0].by] || "").localeCompare(b[orderBys[0].by] || ""),
						);
					} else {
						newRows.sort((a, b) =>
							(b[orderBys[0].by] || "").localeCompare(a[orderBys[0].by] || ""),
						);
					}
				}
			}

			//Paginate
			const newpages = newRows.length / PAGE_ITEM_COUNT;
			newRows = newRows.slice(currentPage * PAGE_ITEM_COUNT, (currentPage + 1) * PAGE_ITEM_COUNT);

			//setCurrentRows
			setState({ currentRows: newRows, pages: newpages });
		}
	};

	const resetUsersTable = () => {
		if (currentPage === 0) {
			getCurrentRowsByPage(0);
		} else {
			setState({ currentPage: 0 });
		}
	};

	useEffect(() => {
		if (called) {
			if (loading) {
				setLoadingMessage("Loading Products");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [called, loading, data]);

	useEffect(() => {
		getCurrentRowsByPage(currentPage);
	}, [currentPage]);

	useEffect(() => {
		resetUsersTable();
	}, [filter, orderBys]);

	const activitySurveyAnswers = data?.getActivitySurveyAnswers || [];

	const parseAndBuildTable = (ASA: GetActivitySurveyCompanyAnswersOutput[]) => {
		if (ASA.length < 1) {
			const res = { rows: [], columns: [] };
			return res;
		}

		//moving forward has at least 1
		const rows = ASA.map((el) => {
			let rowFix = {
				id: el.activitySurveyCompanyId,
				company: el.company,
				...JSON.parse(el.questionsAndAnswers!),
			};
			return rowFix;
		});

		const columns = Object.keys(rows[0]).map((key) => {
			return {
				name: key,
				field: key,
				isSortable: true,
			};
		});

		return { rows, columns };
	};

	const { rows, columns } = parseAndBuildTable(activitySurveyAnswers);

	return (
		<div className="animate__animated animate__fadeInLeft animate__faster mt-5  ">
			<div className="flex gap-3 p-8 justify-between">
				<div className="relative w-80 my-3">
					<input
						className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-80 max-w-xs"
						style={{ paddingLeft: "40px" }}
						onChange={(e) => setState({ filter: e.currentTarget.value })}
						value={filter}
						placeholder="filter by any field (Id, Company, ...)"
					></input>
					<FilterIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
				</div>
				<Button onClick={() => downloadAnswersAsCSV()} className="w-max">
					<DownloadIcon className="w-5 h-5" />
					Download Bid Sheet CSV
				</Button>
			</div>
			<div className="w-full overflow-auto">
				<Table
					className="w-max"
					columns={columns}
					rows={currentRows}
					pagination={{
						pages,
						currentPage,
						onPageClick: (page: number) => {
							setState({
								currentPage: page,
							});
						},
					}}
					onSort={(field, isDesc) => {
						if (field) {
							setState({
								orderBys: pushToOrderBys(orderBys, field as string, isDesc),
							});
						}
					}}
					ascIcon={<ChevronUpIcon className="h-5 w-5" />}
					descIcon={<ChevronDownIcon className="h-5 w-5" />}
				/>
			</div>
		</div>
	);
};

export default ResultSurvey;
