import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import Survey from "./Survey";
import {
	ActivitySurveyItem,
	ActivitySurveyCompanyAnswerOutput,
	// QueryGetActivitySurveysArgs,
	// QueryGetActivitySurveyExistsArgs,
	useGetActivitySurveyLazyQuery,
	useGetActivitySurveyExistsLazyQuery,
	// MutationAddActivitySurveyArgs,
	useAddActivitySurveyMutation,
	useDeleteActivitySurveyMutation,
	// useUpdateActivitySurveyMutation
	// useGetActivityProductsLazyQuery,
	// useGetBidSheetAsCsvLazyQuery,
	useGetActivitySurveyCompanyLazyQuery,
	useAddActivitySurveyCompanyMutation,
	UserTypeNames,
} from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { userAtom } from "../../state/user.recoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import { downloadStr, formatDateISO } from "../../utils/utils";
import { isConstructorDeclaration } from "typescript";
import { SurveyTabsNames } from "./SurveyTabs";
// import ActivityProductForm from "./ActivityProductForm";

interface Props {
	isPreview: SurveyTabsNames;
}

const PAGE_ITEM_COUNT = 10;
type State = {
	hasSurvey: boolean;
	surveyId: number | null;
	activitySurveyItems: any; //ToFix Model
	activitySurveyCompanyId: number | null;
	activitySurveyCompanyAnswers: any; //ToFix Model
	// activitySurveyItems: ({
	// 	__typename?: "ActivitySurveyItem" | undefined;
	// } & Pick<ActivitySurveyItem, "id" | "description" | "sortOrder" | "section" | "title">)[];
};
// Type '({ __typename?: "ActivitySurveyItem" | undefined; } & Pick<ActivitySurveyItem, "id" | "sortOrder" | "section" | "title" | "description">)[]' is missing the following properties from type 'Pick<ActivitySurveyItem, "id" | "sortOrder" | "section" | "title" | "description">': id, sortOrder, section, titlets(2739)
const CreateSurvey: React.FC<Props> = ({ isPreview }) => {
	const [
		{
			hasSurvey,
			surveyId,
			activitySurveyItems,
			activitySurveyCompanyId,
			activitySurveyCompanyAnswers,
		},
		setState,
	] = useStates<State>({
		hasSurvey: false,
		surveyId: null,
		activitySurveyItems: [],
		activitySurveyCompanyId: null,
		activitySurveyCompanyAnswers: [],
	});
	const activity = useRecoilValue(activityAtom)!;
	const user = useRecoilValue(userAtom)!;
	const isAdmin = user!.typeUserType.name === UserTypeNames.Admin;
	const companyId = user!.userCompanyUser?.company.id ?? null;

	const [getActivity, getActivityRes] = useGetActivitySurveyLazyQuery({
		variables: {
			id: activity.id,
		},
		fetchPolicy: "network-only",
	});
	const [getActivityExists, getActivityExistsRes] = useGetActivitySurveyExistsLazyQuery({
		variables: {
			id: activity.id,
		},
		fetchPolicy: "network-only",
	});

	const [getActivitySurveyCompany, getActivitySurveyCompanyRes] =
		useGetActivitySurveyCompanyLazyQuery({
			variables: {
				activitySurveyId: surveyId ?? 0,
				companyId: companyId ?? 0,
			},
			fetchPolicy: "network-only",
		});

	useEffect(() => {
		console.log("useEffect surveyExistsData");
		if (!getActivityExistsRes.loading) {
			const surveyExistsData = getActivityExistsRes.data?.getActivitySurveyExists;
			if (surveyExistsData) {
				setState({
					hasSurvey: surveyExistsData.hasValue,
				});
			}
		}
	}, [getActivityExistsRes.loading, getActivityExistsRes.data, getActivityExistsRes.called]);

	useEffect(() => {
		console.log("useEffect getActivitySurveys");
		if (!getActivityRes.loading) {
			const data = getActivityRes.data?.getActivitySurveys;
			if (data) {
				console.log("data", data);
				data.nodes.length > 0 &&
					setState({
						surveyId: data.nodes[0].id,
						activitySurveyItems: data.nodes[0].activitySurveyItems,
					});
			}
		}
	}, [getActivityRes.loading, getActivityRes.data, getActivityRes.called]);

	const [addActivitySurveyCompany] = useAddActivitySurveyCompanyMutation();
	useEffect(() => {
		if (!getActivitySurveyCompanyRes.loading) {
			const data = getActivitySurveyCompanyRes.data?.getActivitySurveyCompany;
			if (data) {
				console.log("getActivitySurveyCompanyRes", data);
				data &&
					setState({
						activitySurveyCompanyId: data.activitySurveyCompanyId,
						activitySurveyCompanyAnswers: data.activitySurveyCompanyAnswers,
					});
			} else {
				companyId &&
					surveyId &&
					addActivitySurveyCompany({
						variables: {
							activitySurveyId: surveyId,
							companyId: companyId,
						},
					});
			}
		}
	}, [
		getActivitySurveyCompanyRes.loading,
		getActivitySurveyCompanyRes.data,
		getActivitySurveyCompanyRes.called,
	]);
	useEffect(() => {
		if (!getActivitySurveyCompanyRes.loading) {
			getActivitySurveyCompany();
		}
	}, [surveyId]);
	useEffect(() => {
		getActivityExists();
		getActivity();
	}, []);
	const [addActivitySurvey] = useAddActivitySurveyMutation();
	const [deleteActivitySurvey] = useDeleteActivitySurveyMutation();
	const changeActivitySurvey = () => {
		if (hasSurvey) {
			surveyId &&
				deleteActivitySurvey({
					variables: {
						id: surveyId,
					},
				}).then(() => {
					getActivityExists();
					getActivity();
				});
		} else {
			addActivitySurvey({
				variables: {
					activityId: activity.id,
				},
			}).then(() => {
				getActivityExists();
				getActivity();
			});
		}
	};

	return (
		<div>
			{isAdmin ? (
				<>
					<div className="flex justify-between">
						{!hasSurvey ? (
							<Button
								onClick={() => {
									changeActivitySurvey();
								}}
								className="outline-btn"
							>
								<PlusIcon className="w-5 h-5" />

								<span>Add Survey</span>
							</Button>
						) : (
							<>
								{/* <Button
									onClick={() => {
										changeActivitySurvey();
									}}
									className="outline-btn"
								>
									<TrashIcon className="w-5 h-5" />

									<span>Remove Survey</span>
								</Button> */}
							</>
						)}
						<div className="h-5" />
					</div>
					<div>
						{!hasSurvey ? (
							<div className="mt-4">Click add survey to begin creating surevy.</div>
						) : (
							<>
								{surveyId && activitySurveyItems && (
									<Survey
										isAdmin={isAdmin}
										activitySurveyItems={activitySurveyItems}
										surveyId={surveyId}
										getActivity={getActivity}
										isPreview={isPreview === SurveyTabsNames.Preview}
									></Survey>
								)}
							</>
						)}
					</div>
					<br />
				</>
			) : (
				<>
					{surveyId && activitySurveyItems && (
						<Survey
							isAdmin={isAdmin}
							activitySurveyItems={activitySurveyItems}
							activitySurveyItemAnswers={activitySurveyCompanyAnswers}
							surveyId={surveyId}
							surveyCompanyId={activitySurveyCompanyId}
							getActivity={getActivity}
							updateAnswers={getActivitySurveyCompany}
							isPreview={isPreview === SurveyTabsNames.Preview}
						></Survey>
					)}
					<br />
				</>
			)}
		</div>
	);
};

export default CreateSurvey;
