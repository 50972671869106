import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { FormEvent } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
	ActivityProductFragment,
	ActivityProductInput,
	useAddActivityProductMutation,
	useUpdateActivityProductMutation,
} from "../../generated/graphql";
import { useFormState } from "../../hooks/useFormState";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";

export interface Props {
	activityProduct?: ActivityProductFragment;
	onSuccess: () => void;
}
type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
};
type FormState = ActivityProductInput;

const ActivityProductForm: React.FC<Props> = ({ activityProduct, onSuccess }) => {
	const [{ isStatusMessageError, statusMessage }, setState] = useStates<State>({
		isStatusMessageError: false,
	});
	const [formState, setFormState] = useFormState<FormState>({
		rfpItemNumber: activityProduct?.rfpItemNumber,
		tier: activityProduct?.tier,
		cat: activityProduct?.cat,
		sub: activityProduct?.sub,
		type: activityProduct?.type,
		milMicGauge: activityProduct?.milMicGauge,
		newCatColor: activityProduct?.newCatColor,
		total: activityProduct?.total,
		east: activityProduct?.east,
		west: activityProduct?.west,
	});

	const activity = useRecoilValue(activityAtom)!;
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);

	const [addActivityProduct] = useAddActivityProductMutation();
	const [updateActivityProduct] = useUpdateActivityProductMutation();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		setState({
			isStatusMessageError: false,
			statusMessage: undefined,
		});

		if (activityProduct) {
			setLoadingMessage("Updating Product");
			updateActivityProduct({
				variables: {
					activityProductId: activityProduct.id,
					activityProductInput: formState,
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Product updated successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to update product",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		} else {
			setLoadingMessage("Adding Product");
			addActivityProduct({
				variables: {
					activityId: activity.id,
					activityProductInput: formState,
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Product added successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to add product",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	const isHidden = (field: any) => {
		const hiddenColsArray = activity.hiddenColumns?.split(",");
		return hiddenColsArray?.includes(field) || false;
	};

	return (
		<div className="activity-add-product">
			<div className="font-bold my-5">
				{activityProduct ? "Update product" : "Complete form to add a new product"}
			</div>
			<form className="flex flex-col" onSubmit={(e) => submit(e)}>
				{!isHidden("rfpItemNumber") && (
					<>
						<label className="text-gray-400">RFP ITEM # </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ rfpItemNumber: +e.target.value })}
								value={formState.rfpItemNumber || ""}
							></input>
						</div>
					</>
				)}

				{!isHidden("tier") && (
					<>
						<label className="text-gray-400">TIER </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ tier: e.target.value })}
								value={formState.tier || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("cat") && (
					<>
						<label className="text-gray-400">CAT </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ cat: e.target.value })}
								value={formState.cat || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("sub") && (
					<>
						<label className="text-gray-400">SUB </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ sub: e.target.value })}
								value={formState.sub || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("type") && (
					<>
						<label className="text-gray-400">TYPE </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ type: e.target.value })}
								value={formState.type || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("milMicGauge") && (
					<>
						<label className="text-gray-400">MIL/MIC/GAUGE </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ milMicGauge: e.target.value })}
								value={formState.milMicGauge || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("newCatColor") && (
					<>
						<label className="text-gray-400">NEW CAT/COLOR </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ newCatColor: e.target.value })}
								value={formState.newCatColor || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("total") && (
					<>
						<label className="text-gray-400">TOTAL </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ total: +e.target.value })}
								value={formState.total || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("east") && (
					<>
						<label className="text-gray-400">EAST </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ east: +e.target.value })}
								value={formState.east || ""}
							></input>
						</div>
					</>
				)}
				{!isHidden("west") && (
					<>
						<label className="text-gray-400">WEST </label>
						<div className="relative w-full mb-3">
							<input
								onChange={(e) => setFormState({ west: +e.target.value })}
								value={formState.west || ""}
							></input>
						</div>
					</>
				)}

				{/* Status messages */}
				{statusMessage &&
					(isStatusMessageError ? (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					) : (
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
							<CheckCircleIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					))}

				<button
					className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center mt-10 hover:bg-red-800"
					type="submit"
				>
					{activityProduct ? "Update Product" : "Add Product"}
				</button>
			</form>
		</div>
	);
};

export default ActivityProductForm;
