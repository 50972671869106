import React, { createRef, useState } from "react";
import "./fileInput.scss";

interface Props {
	onChange?: (files: FileList | null) => void;
	className?: String;
}

const FileInput: React.FC<Props> = (props) => {
	const [fileName, setFileName] = useState("");
	const [isDragging, setIsDragging] = useState(false);
	const inputRef = createRef<HTMLInputElement>();
	const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		//important to stop default
		e.stopPropagation();
		e.preventDefault();

		//update name
		if (e.currentTarget.files && e.currentTarget.files.length > 1) {
			setFileName(e.currentTarget.files.length + " files selected");
		} else if (e.currentTarget.files && e.currentTarget.files.length === 1) {
			setFileName(e.currentTarget.files![0].name);
		} else {
			setFileName("");
		}

		setIsDragging(false);

		//call props onchange event
		if (props.onChange) {
			props.onChange(e.currentTarget.files);
		}
	};
	const onFileDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
		//important to stop default
		e.stopPropagation();
		e.preventDefault();

		//update input
		inputRef.current!.files = e.dataTransfer.files;

		//update name
		if (e.dataTransfer.files && e.dataTransfer.files.length > 1) {
			setFileName(e.dataTransfer.files.length + " files selected");
		} else if (e.dataTransfer.files && e.dataTransfer.files.length === 1) {
			setFileName(e.dataTransfer.files![0].name);
		} else {
			setFileName("");
		}

		setIsDragging(false);

		//call props onchange event
		if (props.onChange) {
			props.onChange(e.dataTransfer.files);
		}
	};
	const dragOverHandler: React.DragEventHandler<HTMLDivElement> = (ev) => {
		// Prevent default behavior (Prevent file from being opened)
		ev.preventDefault();
		ev.stopPropagation();
	};

	return (
		<div className="file-input">
			<div
				className={"drop-area " + (isDragging ? " dragging " : "") + (fileName ? " hasFile " : "")}
				onClick={() => inputRef.current?.click()}
				onDragEnter={(e) => {
					console.log("enter");
					setIsDragging(true);
				}}
				onDragOver={dragOverHandler}
				onDragLeave={(e) => {
					console.log("leave");
					setIsDragging(false);
				}}
				onDrop={onFileDrop}
			>
				<div className="text-center">
					{isDragging ? (
						"Drop"
					) : fileName === "" ? (
						<div>
							Drop File Here <br /> or <br />
							Click To Browse
						</div>
					) : (
						fileName
					)}
				</div>
			</div>
			<input type="file" multiple={true} ref={inputRef} id="test" onChange={onFileChange} />
		</div>
	);
};

export default FileInput;
