import React from "react";
import { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { useStates } from "../../../../hooks/useStates";
import {
	ActivitySurveyItemOptions,
	ActivitySurveyItemOptionInput,
} from "../../../../generated/graphql";
import { BsDot } from "react-icons/bs";

interface Props {
	index: number;
	parentIndex: number;
	activitySurveyItemId: number;
	activitySurveyItemOption: {
		__typename?: "ActivitySurveyItemOptions";
	} & Pick<ActivitySurveyItemOptions, "id" | "optionValue" | "optionName">;
	update: (id: number, activitySurveyItemOptionInput: ActivitySurveyItemOptionInput) => void;
	delete: (id: number, index: number, parentIndex: number) => void;
}

type State = {
	optionName: string;
};
const SelectOptions: React.FC<Props> = (props) => {
	const [{ optionName }, setState] = useStates<State>({
		optionName: props.activitySurveyItemOption.optionName ?? "",
	});
	const onChange = (val: string) => {
		setState({
			optionName: val,
		});
		updateProp();
	};
	const onBlur = () => {
		updateProp();
	};
	useEffect(() => {
		updateProp();
	}, [optionName]);

	const updateProp = () => {
		props.update(props.activitySurveyItemOption.id, {
			activitySurveyItemId: props.activitySurveyItemId,
			optionName: optionName,
			optionValue: props.activitySurveyItemOption.optionValue,
		});
	};

	return (
		<div className="select-option-cont">
			<BsDot className="w-10 h-10 text-gray-400" />
			<input
				onChange={(e) => onChange(e.target.value)}
				onBlur={(e) => onBlur()}
				type="Text"
				placeholder="enter option here"
				value={optionName}
			></input>
			<div
				className="text-primary pl-2"
				onClick={() => {
					props.delete(props.activitySurveyItemOption.id, props.index, props.parentIndex);
				}}
			>
				<TrashIcon className="w-5 h-5" />
			</div>
		</div>
	);
};

export default SelectOptions;
