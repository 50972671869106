import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { FormEvent } from "react";
import { useRecoilState } from "recoil";
import {
	CompanyFragment,
	CompanyInput,
	CompanyTypeNames,
	useAddCompanyMutation,
	useUpdateCompanyMutation,
} from "../../generated/graphql";
import { useFormState } from "../../hooks/useFormState";
import { useStates } from "../../hooks/useStates";
import { loadingMessageAtom } from "../../state/loading.recoil";

export interface Props {
	company?: CompanyFragment;
	onSuccess: () => void;
}
type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
};
type FormState = CompanyInput;

const CompanyForm: React.FC<Props> = ({ company, onSuccess }) => {
	const [{ isStatusMessageError, statusMessage }, setState] = useStates<State>({
		isStatusMessageError: false,
	});
	const [formState, setFormState] = useFormState<FormState>({
		name: company?.name || "",
		type: company?.typeCompanyType.name || CompanyTypeNames.Supplier,
	});

	const [loadingMessage, setLoadingMessage] = useRecoilState(loadingMessageAtom);

	const [addCompany] = useAddCompanyMutation();
	const [updateCompany] = useUpdateCompanyMutation();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		if (company) {
			setLoadingMessage("Updating Company");
			updateCompany({
				variables: {
					companyId: company.id,
					companyInput: formState,
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Company updated successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to update company",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		} else {
			setLoadingMessage("Adding Company");
			addCompany({
				variables: {
					companyInput: formState,
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Company added successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to add company",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	return (
		<div className="comp-add-user-modal">
			<div className="font-bold my-5">
				{company ? "Update company" : "Complete form to add a new company"}
			</div>
			<form className="flex flex-col" onSubmit={(e) => submit(e)}>
				<label className="text-gray-400">Name </label>
				<div className="relative w-full mb-3">
					<input
						onChange={(e) => setFormState({ name: e.target.value })}
						value={formState.name}
						required
					></input>
				</div>
				{/*  */}
				<label className="text-gray-400">Company Type </label>
				<div className="inline-block relative">
					<select
						onChange={(e) => setFormState({ type: e.target.value as CompanyTypeNames })}
						value={formState.type}
						required
						className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
					>
						{Object.values(CompanyTypeNames).map((companyTypeName, i) => {
							return (
								<option key={i} value={companyTypeName}>
									{companyTypeName}
								</option>
							);
						})}
					</select>
				</div>

				{/* Status messages */}
				{statusMessage &&
					(isStatusMessageError ? (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					) : (
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
							<CheckCircleIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					))}

				<button
					className={
						"flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center mt-10 hover:bg-red-800" +
						(loadingMessage || statusMessage ? "pointer-events-none bg-opacity-70" : "")
					}
					type="submit"
				>
					{company ? "Update Company" : "Add Company"}
				</button>
			</form>
		</div>
	);
};

export default CompanyForm;
