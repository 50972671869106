import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { FormEvent, useState } from "react";
import { useRecoilState } from "recoil";
import {
	ActivityFragment,
	ActivityInput,
	useAddActivityMutation,
	useUpdateActivityMutation,
} from "../../generated/graphql";
import { useFormState } from "../../hooks/useFormState";
import { useStates } from "../../hooks/useStates";
import { loadingMessageAtom } from "../../state/loading.recoil";
import React from "react";
import RichTextEditor from "react-rte";
import { formatDateISO } from "../../utils/utils";

export interface Props {
	activity?: ActivityFragment;
	onSuccess: () => void;
}
type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
};
type FormState = ActivityInput;

const ActivityForm: React.FC<Props> = ({ activity, onSuccess }) => {
	const [{ isStatusMessageError, statusMessage }, setState] = useStates<State>({
		isStatusMessageError: false,
	});

	const [formState, setFormState] = useFormState<FormState>({
		name: activity?.name || "",
		expiresAt: formatDateISO(activity?.expiresAt) || new Date(),
	});

	// state of the editor
	const [rteState, setRteState] = useState(
		RichTextEditor.createValueFromString(activity?.description || "", "html"),
	);

	const [loadingMessage, setLoadingMessage] = useRecoilState(loadingMessageAtom);

	const [addActivity] = useAddActivityMutation();
	const [updateActivity] = useUpdateActivityMutation();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		setState({
			isStatusMessageError: false,
			statusMessage: undefined,
		});

		if (activity) {
			setLoadingMessage("Updating Activity");
			updateActivity({
				variables: {
					activityId: activity.id,
					activityInput: { ...formState, description: rteState.toString("html") },
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Activity updated successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to update activity",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		} else {
			setLoadingMessage("Adding Activity");
			addActivity({
				variables: {
					activityInput: { ...formState, description: rteState.toString("html") },
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "Activity added successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to add activity",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	return (
		<div className="w-full">
			<div className="font-bold my-5">
				{activity ? "Update activity" : "Complete form to add a new activity"}
			</div>
			<form className="flex flex-col text-left" onSubmit={(e) => submit(e)}>
				<label className="text-gray-400">Name </label>
				<div className="relative w-full mb-3">
					<input
						className="border border-gray-400 rounded focus:outline-none focus:border-primary h-9 pl-5 w-full"
						onChange={(e) => setFormState({ name: e.target.value })}
						value={formState.name}
						required={true}
					></input>
				</div>

				<label className="text-gray-400">Details </label>
				<RichTextEditor
					value={rteState}
					onChange={(v) => {
						setRteState(v);
					}}
				/>
				<div className="h-6"></div>
				<label className="text-gray-400">Expiration Date </label>
				<div className="relative w-full mb-3">
					<input
						className="border border-gray-400 rounded focus:outline-none focus:border-primary h-9 pl-5 w-full"
						onChange={(e) => setFormState({ expiresAt: e.target.value })}
						value={formState.expiresAt}
						type="date"
					></input>
				</div>

				{/* Status messages */}
				{statusMessage &&
					(isStatusMessageError ? (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					) : (
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
							<CheckCircleIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					))}

				<button
					className={
						"flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center my-10 hover:bg-red-800 " +
						(loadingMessage || statusMessage ? "pointer-events-none bg-opacity-70" : "")
					}
					type="submit"
				>
					{activity ? "Update Activity" : "Add Activity"}
				</button>
			</form>
		</div>
	);
};

export default ActivityForm;
