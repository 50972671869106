import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PathName } from "../constants/PathName";
import Login from "./login/Login";
import PrivateRoute from "../components/PrivateRoute";
import Activity from "./activities/Activity";
import ResetPassword from "./login/ResetPassword";
import ForgotPassword from "./login/ForgotPassword";
import AdminDashboard from "./AdminDashboard";
import Activities from "./activities/Activities";
import Users from "./users/Users";
import Companies from "./companies/Companies";

const history = createBrowserHistory();

const Main: React.FC = () => {
	return (
		<div>
			<Router history={history}>
				<Switch>
					<Route exact path="/" render={() => <Redirect push to={PathName.DEFAULT} />} />
					<Route exact path={PathName.LOGIN}>
						<Login />
					</Route>
					<Route exact path={PathName.FORGOT_PASSWORD}>
						<ForgotPassword />
					</Route>
					<Route exact path={PathName.RESET_PASSWORD}>
						<ResetPassword />
					</Route>
					<PrivateRoute exact path={PathName.ADMIN_DASHBOARD} component={<AdminDashboard />} />
					<PrivateRoute exact path={PathName.ACTIVITIES} component={<Activities />} />
					<PrivateRoute exact path={PathName.USERS} component={<Users />} />
					<PrivateRoute exact path={PathName.COMPANIES} component={<Companies />} />
					<PrivateRoute
						exact
						path={PathName.ACTIVITY("/:id")}
						render={(p) => <Activity {...p} />}
					/>
				</Switch>
			</Router>
		</div>
	);
};

export default Main;
