import { useEffect } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import FileInput from "../../components/FileInput/FileInput";
import {
	FileTypeNames,
	useDeleteFileMutation,
	useGetDocumentFilesLazyQuery,
	UserTypeNames,
} from "../../generated/graphql";
import useFiles from "../../hooks/useFiles";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import { userAtom } from "../../state/user.recoil";
import { formatDateStr } from "../../utils/utils";

type State = {
	files: FileList | null;
};

// @TODO use Table
const Files: React.FC = () => {
	const [state, setState] = useStates<State>({
		files: null,
	});
	const user = useRecoilValue(userAtom);
	const activity = useRecoilValue(activityAtom);

	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const { downloadFile, downloadActivityFiles, addFile } = useFiles();
	const [getDocumentFiles, getDocumentFilesRes] = useGetDocumentFilesLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [deleteFileMutation] = useDeleteFileMutation();

	const getActivityDocumentFiles = () => {
		getDocumentFiles({
			variables: {
				activityId: activity!.id,
			},
		});
	};

	const _downloadFile = async (id: number, name?: string | null) => {
		setLoadingMessage("Downloading File");
		downloadFile(id, name)
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
			});
	};

	const uploadFile = async () => {
		if (state.files) {
			setLoadingMessage("Uploading File(s)");
			//add files one by one
			const promises = [];
			for (let i = 0; i < state.files.length; i++) {
				promises.push(addFile(state.files[i], FileTypeNames.Document));
			}
			await Promise.all(promises)
				.then(() => {
					getActivityDocumentFiles();
				})
				.catch(() => {
					// @TODO error
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	const deleteFile = (id: number) => {
		setLoadingMessage("Deleting File");
		deleteFileMutation({
			variables: {
				id,
			},
		})
			.then(() => {
				getActivityDocumentFiles();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
				setModalRender(undefined);
			});
	};

	const downloadActivityDocumentFiles = () => {
		setLoadingMessage("Downloading Files");
		downloadActivityFiles(FileTypeNames.Document)
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
			});
	};

	const deletePrompt = (id: number) => {
		return (
			<div className="w-80 flex flex-col justify-center items-center self-center mb-7">
				<div className="my-5">Are you sure you want to delete this uploaded file?</div>
				<div className="flex gap-4">
					<Button
						className="w-max px-4 py-1 rounded border border-gray-300 hover:bg-gray-400"
						onClick={() => setModalRender(undefined)}
					>
						No
					</Button>
					<Button onClick={() => deleteFile(id)}>Yes</Button>
				</div>
			</div>
		);
	};

	useEffect(() => {
		getActivityDocumentFiles();
	}, []);

	useEffect(() => {
		if (getDocumentFilesRes.called) {
			if (getDocumentFilesRes.loading) {
				setLoadingMessage("Loading Files");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getDocumentFilesRes.called, getDocumentFilesRes.loading, getDocumentFilesRes.data]);

	const documentFiles = getDocumentFilesRes.data?.getDocumentFiles || [];

	return (
		<div className="animate__animated animate__fadeInLeft animate__faster">
			{user!.typeUserType.name === UserTypeNames.CompanyUser ? (
				<div className="my-5 flex gap-6">
					<FileInput
						onChange={(files) =>
							setState({
								files: files!,
							})
						}
					></FileInput>
					<Button onClick={() => uploadFile()}>Upload</Button>
				</div>
			) : (
				<Button onClick={() => downloadActivityDocumentFiles()}>Download</Button>
			)}
			<br />
			<table>
				<thead>
					<tr>
						{user!.typeUserType.name === UserTypeNames.Admin && <th>Company</th>}
						<th>File Name</th>
						<th>Created At</th>
						<th>Download</th>
						<th>Delete</th>
					</tr>
				</thead>
				<tbody>
					{documentFiles.map((file, i) => {
						return (
							<tr key={i}>
								{user!.typeUserType.name === UserTypeNames.Admin && <td>{file.company!.name}</td>}
								<td>{file.name}</td>
								<td>{formatDateStr(file.createdAt, true)}</td>
								<td>
									<button
										className="bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800"
										onClick={() => _downloadFile(file.id, file.name)}
									>
										Download
									</button>
								</td>
								<td>
									<button
										className="flex text-primary m-auto"
										onClick={() => setModalRender(deletePrompt(file.id))}
									>
										<IoTrashOutline className="w-5 h-5 text-primary" />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Files;
