import { atom, selector } from "recoil";
import { UserFragment } from "../generated/graphql";

export const userAtom = atom<UserFragment | undefined>({
	key: "user",
	default: undefined,
});

export const isLoggedInSelector = selector<boolean>({
	key: "isLoggedIn",
	get: ({ get }) => {
		const user = get(userAtom);
		return !!user;
	},
});
