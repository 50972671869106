import { FormEvent } from "react";
import { useFormState } from "../../hooks/useFormState";
import { MailIcon } from "@heroicons/react/solid";
import { useForgotPasswordMutation } from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { ExclamationIcon } from "@heroicons/react/outline";

type State = {
	statusMessage?: string;
	requestReset: boolean;
	requestSuccess: boolean;
};

const ForgotPassword: React.FC = () => {
	const [{ statusMessage, requestReset, requestSuccess }, setState] = useStates<State>({requestReset:false, 
		requestSuccess: false});
	const [{ email }, setFormState] = useFormState({
		email: "",
	});
	const [forgotPassword, forgotPasswordRes] = useForgotPasswordMutation();
	const resetAgain = () =>{
		setState({
			statusMessage: undefined,
			requestReset: false,
			requestSuccess: false
		})
		setFormState({email: ""})
	}
	const submit = (e: FormEvent) => {
		e.preventDefault();
		setState({
			statusMessage: undefined,
			requestReset: true,
		});
		forgotPassword({
			variables: {
				email,
			},
		})
			.then(() => {
				setState({
					statusMessage: "Request sent",
					requestSuccess: true,
					requestReset: false
				});
			})
			.catch(() => {
				setState({
					statusMessage: "Request failed.",
					requestReset: false
				});
			});
			console.log(forgotPasswordRes)
	};

	return (
		<div className="container flex flex-col mx-auto items-center">
			<div className="flex w-full max-w-lg my-14 gap-5">
				<div className="w-full">
					<img src="/img/unipro.svg" alt="" />
				</div>
				<div className="w-full">
					<img src="/img/tcm.jpg" alt="" />
				</div>
			</div>

			<div className="flex flex-col p-4 border border-gray-400 rounded max-w-md mx-auto w-full">
				<form className="flex flex-col" onSubmit={(e) => submit(e)}>
					<label className="text-gray-400">
						Enter your user account's verified email address and we will send you a password reset
						link.
					</label>
					<br />
					<div className="relative w-full mb-3">
						<input
							className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-10 w-full"
							onChange={(e) => setFormState({ email: e.target.value })}
							value={email}
							disabled={requestSuccess}
						></input>
						<MailIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" />
					</div>
					{forgotPasswordRes.error && (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					)}
					{!requestSuccess && <button
						className="flex bg-primary text-white rounded w-max px-3 py-1.5 items-center"
						type="submit"
						disabled={requestReset}
					>
						{forgotPasswordRes.loading && (
							<div className="rounded-2xl border-t-4 border-b-4 border-white animate-spin h-5 w-5 mr-3"></div>
						)}
						Send password reset email
					</button>
					}
					
					{requestSuccess && (
						<>
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">Password reset email sent successfully. If you did not receive an email please contact us at R3TCM@R3Redistribution.com</div>
						<span onClick={resetAgain} className="flex bg-primary text-white rounded w-max px-3 py-1.5 items-center cursor-pointer">Try Again</span>
						</>
					)}
					</form>
			</div>
		</div>
	);
};

export default ForgotPassword;
