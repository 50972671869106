import React from "react";
import { useStates } from "../../../../hooks/useStates";
import { useEffect } from "react";
import { PlusIcon } from "@heroicons/react/solid";
import Button from "../../../../components/Button";
import {
	ActivitySurveyItem,
	ActivitySurveyItemInput,
	useAddActivitySurveyItemMutation,
} from "../../../../generated/graphql";
import { EnumActivitySurveyItemType } from "../../components/enum";
import "../../surveys.scss";
import { BsCursorText, BsListUl, BsTextareaT } from "react-icons/bs";

import { TrashIcon } from "@heroicons/react/outline";

interface Props {
	preview: boolean;
	surveyId: number;
	activitySurveyItem: ActivitySurveyItem;
	addAddition: any;
	deleteAddition: any;
	update: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	// onChangeSaveValue: any;
	// onBlurSave: any;
	answer?: string;
	updateAnswer?: (updateValue: string) => void;
}

type State = {
	title: string;
	description: string;
	required: boolean;
	saveValue: string;
	showAdditonalOptions: boolean;
};
const Addition: React.FC<Props> = (props) => {
	const [{ title, description, required, saveValue, showAdditonalOptions }, setState] =
		useStates<State>({
			title: props.activitySurveyItem.title,
			description: props.activitySurveyItem.description ?? "",
			required: props.activitySurveyItem.required,
			saveValue: "",
			showAdditonalOptions: false,
		});
	useEffect(() => {
		setState({
			saveValue: props.answer,
		});
	}, [props.answer]);
	const changeShowOptions = () => {
		setState({
			showAdditonalOptions: !showAdditonalOptions,
		});
	};
	const onChange2 = (val: string) => {
		setState({
			description: val,
		});
	};
	const onBlur = () => {
		updateItem();
	};
	const updateItem = () => {
		const updateItem =
			props.activitySurveyItem.activitySurveyItemChildren &&
			props.activitySurveyItem.activitySurveyItemChildren[0];
		updateItem &&
			props.update(updateItem.id, {
				activitySurveyId: props.surveyId,
				section: updateItem.section,
				itemType: updateItem.itemType,
				title: updateItem.title,
				description: description,
				sortOrder: updateItem.sortOrder,
				required: updateItem.required,
			});
	};

	const onChangeSaveValue = (val: string) => {
		setState({
			saveValue: val,
		});
		console.log(val, "2");
		updateAnswer2(val);
	};
	const onBlurSave = (val: string) => {
		console.log(val, "1");
		setState({
			saveValue: val,
		});
		updateAnswer2(val);
	};
	// const updateAnswer = () => {
	// 	props.updateAnswer && props.updateAnswer(saveValue);
	// };
	const updateAnswer2 = (val: string) => {
		props.updateAnswer && props.updateAnswer(val);
	};
	return (
		<>
			{!props.preview ? (
				<div className="animate__animated animate__fadeInLeft animate__faster mt-5 survey-inn-cont">
					{props.activitySurveyItem.activitySurveyItemChildren &&
					props.activitySurveyItem.activitySurveyItemChildren.length > 0 ? (
						<>
							<div className="w-11/12 inline-block">
								<textarea
									onChange={(e) => onChange2(e.target.value)}
									onBlur={(e) => onBlur()}
									placeholder="Describe addtion if necessary"
									value={description}
									rows={2}
								></textarea>
							</div>
							<div
								className="question-top-options text-primary  w-1/12 inline-block float-right"
								onClick={() => {
									props.activitySurveyItem.activitySurveyItemChildren &&
										props.deleteAddition(
											props.activitySurveyItem.activitySurveyItemChildren[0].id,
											null,
										);
								}}
							>
								<TrashIcon className="w-5 h-5" />
							</div>
						</>
					) : (
						<div></div>
					)}
				</div>
			) : (
				<div>
					{/* <div className="question">{props.activitySurveyItem.title}</div> */}
					<div className="text-sm text-gray-400">
						{props.activitySurveyItem.activitySurveyItemChildren &&
							props.activitySurveyItem.activitySurveyItemChildren.length > 0 &&
							props.activitySurveyItem.activitySurveyItemChildren[0].description}
					</div>
					<textarea
						onChange={(e) => onChangeSaveValue(e.target.value)}
						onBlur={(e) => onBlurSave(e.target.value)}
						placeholder="Additional comments"
						value={saveValue}
						rows={3}
					></textarea>
				</div>
			)}
		</>
	);
};

export default Addition;
