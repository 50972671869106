import {
	CheckCircleIcon,
	CheckIcon,
	ExclamationIcon,
	FolderIcon,
	InformationCircleIcon,
	OfficeBuildingIcon,
	PencilIcon,
	TableIcon,
	TemplateIcon,
	UserCircleIcon,
} from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { FormEvent, useEffect, useState } from "react";
import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Files from "../files/Files";
import Templates from "../templates/Templates";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import {
	ActivityInput,
	useGetActivityQuery,
	UserTypeNames,
	useUpdateActivityMutation,
	useUpdateActivityCompanyMutation,
	useGetActivityCompaniesQuery,
	ActivityCompanyFragment,
	useUpdateActivityOnFinalizeMutation,
	useGetActivityActiveUserLazyQuery,
	ActActiveUsers,
	useAddOrEditActivityActiveUserMutation,
	useDeleteActivityActiveUserMutation,
} from "../../generated/graphql";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activityAtom } from "../../state/activityRecoil";
import BidSheet from "../bidSheets/BidSheet";
import Survey from "../surveys/Survey";
import CreateSurvey from "../surveys/CreateSurvey";
import { userAtom } from "../../state/user.recoil";
import RichTextEditor from "react-rte";
import { useFormState } from "../../hooks/useFormState";
import { useStates } from "../../hooks/useStates";
import { loadingMessageAtom } from "../../state/loading.recoil";
import Button from "../../components/Button";
import ActivityCompanies from "./ActivityCompanies";
import ActivityTabs from "../bidSheets/ActivityTabs";
import "./activity.scss";
import { BsCardChecklist } from "react-icons/bs";
import { FiArrowRightCircle } from "react-icons/fi";
import { RiSurveyLine } from "react-icons/ri";
import SurveyTabs from "../surveys/SurveyTabs";
import {
	currentActivityActiveUserAtom,
	otherActivityActiveUserAtom,
} from "../../state/activityActiveUsers.recoil";
import { MdWarning } from "react-icons/md";
import Tooltip from "../../components/Tooltip";
import { ONE_HOUR } from "../../constants/General";

enum Tabs {
	//must be in order
	Instructions = "instructions",
	Templates = "templates",
	Files = "files",
	BidSheet = "bidSheet",
	Survey = "survey",
	Companies = "companies",
	Review = "review",
}
type TabStatus = {
	instructions: "incomplete" | "complete" | "disabled";
	files: "incomplete" | "complete" | "disabled";
	templates: "incomplete" | "complete" | "disabled";
	bidSheet: "incomplete" | "complete" | "disabled";
	survey: "incomplete" | "complete" | "disabled";
	companies: "incomplete" | "complete" | "disabled";
	review: "incomplete" | "complete" | "disabled";
};

type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
	shouldShowEditor: boolean;
};

interface Props extends RouteComponentProps<{ [x: string]: string | undefined }> {}

type FormState = ActivityInput;

const Activity: React.FC<Props> = ({ history, match }) => {
	const [{ isStatusMessageError, statusMessage, shouldShowEditor }, setState] = useStates<State>({
		isStatusMessageError: false,
		statusMessage: "",
		shouldShowEditor: false,
	});

	const [tabStatus, setTabStatus] = useStates<TabStatus>({
		instructions: "incomplete",
		files: "disabled",
		templates: "disabled",
		bidSheet: "disabled",
		survey: "disabled",
		companies: "disabled",
		review: "disabled",
	});

	const user = useRecoilValue(userAtom);
	const isAdmin = user!.typeUserType.name === UserTypeNames.Admin;

	const [tabSelected, setTabSelected] = useState(Tabs.Instructions);
	const [activity, setActivity] = useRecoilState(activityAtom);
	const [currentActivityActiveUser, setCurrentActivityActiveUser] = useRecoilState(
		currentActivityActiveUserAtom,
	);
	const [otherActivityActiveUser, setOtherActivityActiveUser] = useRecoilState(
		otherActivityActiveUserAtom,
	);

	const [formState, setFormState] = useFormState<FormState>({
		name: "",
		instruction: "",
		expiresAt: "",
		activeAt: "",
	});
	const [isWizardActive, setWizardActive] = useState(true);
	const [activityCompany, setActivityCompany] = useState<ActivityCompanyFragment | undefined>();
	// state of the editor
	const [rteState, setRteState] = useState(RichTextEditor.createValueFromString("", "html"));

	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);

	const getActivityRes = useGetActivityQuery({
		variables: {
			id: parseInt(match.params.id!),
		},
	});
	const getActivityCompany = useGetActivityCompaniesQuery({
		variables: {
			activityId: parseInt(match.params.id!),
			companyId: user?.userCompanyUser?.company.id,
		},
		skip: isAdmin,
	});

	const [getActivityActiveUsers, getActivityActiveUsersRes] = useGetActivityActiveUserLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [addOrEditActivityActiveUser, addOrEditActivityActiveUserRes] =
		useAddOrEditActivityActiveUserMutation();
	const [deleteActivityActiveUser] = useDeleteActivityActiveUserMutation({
		variables: {
			ActivityActiveUserId: currentActivityActiveUser?.id || 0,
		},
	});

	const [updateActivity] = useUpdateActivityMutation();
	const [updateActivityOnFinalize] = useUpdateActivityOnFinalizeMutation();
	const [updateActivityCompany] = useUpdateActivityCompanyMutation();

	function createMarkup(htmlCont: string) {
		return { __html: htmlCont };
	}

	const submit = (e: FormEvent) => {
		e.preventDefault();

		setState({
			isStatusMessageError: false,
			statusMessage: undefined,
		});

		setLoadingMessage("Updating Activity");
		updateActivity({
			variables: {
				activityId: activity!.id,
				activityInput: { ...formState, instruction: rteState.toString("html") },
			},
		})
			.then(() => {
				setState({
					isStatusMessageError: false,
					shouldShowEditor: false,
					statusMessage: "Activity updated successfully",
				});
				setActivity({ ...activity!, instruction: rteState.toString("html") });
				setFormState({...formState, 
					instruction: rteState.toString("html")
				});
			})
			.catch(() => {
				setState({
					isStatusMessageError: true,
					statusMessage: "Failed to update activity",
				});
			})
			.finally(() => {
				setLoadingMessage(undefined);
			});
	};

	const gotoNextStep = (tabSelected: Tabs) => {
		setTabStatus({ [tabSelected]: "complete" });
		const arr = Object.values(Tabs);
		const tabSelectedIndex = arr.indexOf(tabSelected);
		//conditions
		if (isAdmin) {
			//conditions for admin
			//skip if next is files
			if (arr[tabSelectedIndex + 1] === Tabs.Files) {
				setTabSelected(arr[tabSelectedIndex + 2]);
				return;
			}
		} else {
			//conditions for user
			//skip if next is companies
			if (arr[tabSelectedIndex + 1] === Tabs.Companies) {
				setTabSelected(arr[tabSelectedIndex + 2]);
				return;
			}
		}

		//if not last one
		if (arr[arr.length - 1] !== tabSelected) {
			setTabSelected(arr[tabSelectedIndex + 1]);
		}

		//clear message
		setState({
			statusMessage: "",
		});
	};

	const submitRfp = () => {
		const activeDate = new Date();

		//Admin
		isAdmin &&
			updateActivityOnFinalize({
				variables: {
					activityId: activity!.id,
					activityInput: { ...formState, activeAt: activeDate },
				},
			})
				.then(() => {
					setActivity({ ...activity!, activeAt: activeDate });
					setState({
						isStatusMessageError: false,
						statusMessage: "Activity Submitted successfully",
					});
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Activity Not Submitted",
					});
				})
				.finally(() => {});

		//User
		!isAdmin &&
			updateActivityCompany({
				variables: {
					id: activityCompany?.id!,
					activityCompaniesInput: {
						submittedAt: activeDate,
					},
				},
			})
				.then(() => {
					setActivityCompany({ ...activityCompany!, submittedAt: activeDate });
					setState({
						isStatusMessageError: false,
						statusMessage: "Activity Submitted successfully",
					});
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Activity Not Submitted",
					});
				})
				.finally(() => {});
	};

	useEffect(() => {
		return () => {
			//in the return of useffect will run this code on unmount component
			//cleanup recoil at end of use
			setActivity(undefined);
		};
	}, []);

	useEffect(() => {
		if ((isAdmin && activity?.activeAt) || (!isAdmin && activityCompany?.submittedAt)) {
			setTabStatus({
				instructions: "complete",
				files: "complete",
				templates: "complete",
				bidSheet: "complete",
				survey: "complete",
				companies: "complete",
				review: "complete",
			});
			setWizardActive(false);
		}
	}, [activity?.activeAt, activityCompany?.submittedAt]);

	useEffect(() => {
		//reset tabs after selectd one
		if (isWizardActive) {
			const arr = Object.values(Tabs);
			const arrSliced = arr.slice(arr.indexOf(tabSelected) + 1);
			const disabled = arrSliced.reduce((o, key) => ({ ...o, [key]: "disabled" }), {});
			if (arrSliced.length > 0) {
				setTabStatus(disabled);
			}
		}
	}, [tabSelected]);

	useEffect(() => {
		if (!getActivityRes.loading) {
			const activityData = getActivityRes.data?.getActivity;
			if (activityData) {
				setActivity(activityData);
				setFormState({
					name: activityData.name,
					description: activityData.description,
					instruction: activityData.instruction,
					expiresAt: activityData.expiresAt,
					activeAt: activityData.activeAt,
				});
				setRteState(RichTextEditor.createValueFromString(activityData.instruction || "", "html"));
			}
		}
	}, [getActivityRes.loading, getActivityRes.data, history]);

	useEffect(() => {
		if (!getActivityCompany.loading) {
			if (getActivityCompany.data?.getActivityCompanies) {
				setActivityCompany(getActivityCompany.data.getActivityCompanies.nodes[0]);
			}
		}
	}, [getActivityCompany.data, getActivityCompany.loading]);

	useEffect(() => {
		if (getActivityRes.called) {
			if (getActivityRes.loading) {
				setLoadingMessage("Loading Activity");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getActivityRes.called, getActivityRes.loading, getActivityRes.data]);

	useEffect(() => {
		//ON LOAD add or edit activity active user, save in DB
		addOrEditActivityActiveUser({
			variables: {
				addOrEditActivityActiveUserInput: {
					activityId: parseInt(match.params.id!),
					companyId: user?.userCompanyUser?.company.id!,
					userId: user?.id!,
				},
			},
		});
	}, [
		tabSelected,
		addOrEditActivityActiveUser,
		match.params.id,
		user?.userCompanyUser?.company.id,
		user?.id,
	]);

	useEffect(() => {
		//SET CURRENT USER TO RECOIL
		if (addOrEditActivityActiveUserRes.data && !addOrEditActivityActiveUserRes.loading) {
			const currentUser = addOrEditActivityActiveUserRes.data.addOrEditActivityActiveUser;
			if (currentUser) {
				setCurrentActivityActiveUser(currentUser as ActActiveUsers);
			}
		}
	}, [
		addOrEditActivityActiveUserRes.data,
		addOrEditActivityActiveUserRes.loading,
		setCurrentActivityActiveUser,
		currentActivityActiveUser,
		deleteActivityActiveUser,
	]);

	useEffect(() => {
		//GET OTHER USERS EDITING
		if (currentActivityActiveUser) {
			getActivityActiveUsers({
				variables: {
					activityId: parseInt(match.params.id!),
					companyId: user?.userCompanyUser?.company.id,
				},
			});
		}
	}, [getActivityActiveUsers, currentActivityActiveUser, match.params.id, user]);

	useEffect(() => {
		//SET OTHER USERS RECOIL
		if (getActivityActiveUsersRes.data && !getActivityActiveUsersRes.loading) {
			const otherUsers = getActivityActiveUsersRes.data.getActivityActiveUsers.nodes.filter(
				(el) => {
					//skip if self
					if (el.user.id === user?.id) {
						return false;
					}
					//check if a date is far away
					const timeDiff = new Date().getTime() - new Date(el.updatedAt).getTime();
					if (timeDiff > ONE_HOUR) {
						deleteActivityActiveUser({
							variables: {
								ActivityActiveUserId: el.id,
							},
						});
						return false;
					}
					return el.user.id !== user?.id;
				},
			);
			if (otherUsers.length > 0) {
				setOtherActivityActiveUser(otherUsers as ActActiveUsers[]);
			}
		}

		return () => {
			setOtherActivityActiveUser([]);
		};
	}, [
		getActivityActiveUsersRes.data,
		getActivityActiveUsersRes.loading,
		user?.id,
		setOtherActivityActiveUser,
		deleteActivityActiveUser,
	]);

	useEffect(() => {
		return () => {
			deleteActivityActiveUser();
			setCurrentActivityActiveUser(undefined);
		};
	}, []);

	const isActuallyLoading =
		getActivityRes.loading || (!!getActivityRes.data?.getActivity && !activity);

	if (!isActuallyLoading && !activity) {
		return <Redirect to={PathName.NOT_FOUND}></Redirect>;
	}

	return (
		<div className="activity container mx-auto p-3">
			<Header />
			{isActuallyLoading ? (
				<div>Loading</div>
			) : (
				<>
					<div className="flex flex-col items-start gap-4">
						<ul className="flex text-gray-500 text-sm lg:text-base mt-10">
							<li className="inline-flex items-center">
								<div
									onClick={() => history.push(PathName.ACTIVITIES)}
									className="cursor-pointer hover:text-primary"
								>
									Activities
								</div>
								<ChevronRightIcon className="h-5 w-auto text-gray-400" />
							</li>
							<li className="inline-flex items-center">
								<div>{activity!.name}</div>
							</li>
						</ul>
						{otherActivityActiveUser.length > 0 && (
							<div className="flex gap-2 items-center text-primary border border-yellow-600 rounded p-2">
								<div className="flex items-center gap-2">
									<MdWarning className="h-5 w-5" />
									Data might not save correctly while another user is editing:{" "}
								</div>
								{otherActivityActiveUser.map((activeUser) => (
									<div key={activeUser.id} className="flex items-center gap-2">
										<UserCircleIcon className="h-6 w-6 inline-block" />{" "}
										{activeUser.user.userCompanyUser?.name}
									</div>
								))}
							</div>
						)}
					</div>
					{/* Steps starts from here */}
					<div className={tabSelected + " container mt-4 wizard"}>
						<div className="activity-tab-cont">
							<div
								className={
									(tabSelected === Tabs.Instructions) +
									" w-tab " +
									tabStatus.instructions +
									" tab-list-item-cont "
								}
								onClick={() => setTabSelected(Tabs.Instructions)}
							>
								<div className="activity-tab">
									<div className="activity-tab-in">
										<InformationCircleIcon className="w-5 h-5 mr-1" /> <span>Instructions</span>
									</div>
								</div>
								{/* Update that svg section, used for test */}
								<div
									className="hidden md:block absolute top-0 right-0 h-full w-5"
									aria-hidden="true"
								>
									<svg
										className="h-full w-full text-gray-400"
										viewBox="0 0 22 80"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0 -2L20 40L0 82"
											vectorEffect="non-scaling-stroke"
											stroke="currentcolor"
											strokeLinejoin="round"
										></path>
									</svg>
								</div>
							</div>

							<div
								className={
									(tabSelected === Tabs.Templates) +
									" w-tab " +
									tabStatus.templates +
									" tab-list-item-cont "
								}
								onClick={() => setTabSelected(Tabs.Templates)}
							>
								<div className="activity-tab">
									<div className="activity-tab-in">
										<TemplateIcon className="w-5 h-5 mr-1" /> <span>Templates</span>
									</div>
								</div>
								{/* Update that svg section, used for test */}
								<div
									className="hidden md:block absolute top-0 right-0 h-full w-5"
									aria-hidden="true"
								>
									<svg
										className="h-full w-full text-gray-400"
										viewBox="0 0 22 80"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0 -2L20 40L0 82"
											vectorEffect="non-scaling-stroke"
											stroke="currentcolor"
											strokeLinejoin="round"
										></path>
									</svg>
								</div>
							</div>

							{(!isWizardActive || !isAdmin) && (
								<div
									className={
										(tabSelected === Tabs.Files) +
										" w-tab " +
										tabStatus.files +
										" tab-list-item-cont "
									}
									onClick={() => setTabSelected(Tabs.Files)}
								>
									<div className="activity-tab">
										<div className="activity-tab-in">
											<FolderIcon className="w-5 h-5 mr-1" /> <span>Files</span>
										</div>
									</div>
									{/* Update that svg section, used for test */}
									<div
										className="hidden md:block absolute top-0 right-0 h-full w-5"
										aria-hidden="true"
									>
										<svg
											className="h-full w-full text-gray-400"
											viewBox="0 0 22 80"
											fill="none"
											preserveAspectRatio="none"
										>
											<path
												d="M0 -2L20 40L0 82"
												vectorEffect="non-scaling-stroke"
												stroke="currentcolor"
												strokeLinejoin="round"
											></path>
										</svg>
									</div>
								</div>
							)}

							<div
								className={
									(tabSelected === Tabs.BidSheet) +
									" w-tab " +
									tabStatus.bidSheet +
									" tab-list-item-cont "
								}
								onClick={() => setTabSelected(Tabs.BidSheet)}
							>
								<div className="activity-tab">
									<div className="activity-tab-in">
										<TableIcon className="w-5 h-5 mr-1" /> <span>Bid Sheet</span>
									</div>
								</div>
								{/* Update that svg section, used for test */}
								<div
									className="hidden md:block absolute top-0 right-0 h-full w-5"
									aria-hidden="true"
								>
									<svg
										className="h-full w-full text-gray-400"
										viewBox="0 0 22 80"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0 -2L20 40L0 82"
											vectorEffect="non-scaling-stroke"
											stroke="currentcolor"
											strokeLinejoin="round"
										></path>
									</svg>
								</div>
							</div>
							<div
								className={
									(tabSelected === Tabs.Survey) +
									" w-tab " +
									tabStatus.survey +
									" tab-list-item-cont "
								}
								onClick={() => setTabSelected(Tabs.Survey)}
							>
								<div className="activity-tab">
									<div className="activity-tab-in">
										<RiSurveyLine className="w-5 h-5 mr-1" /> <span>Survey</span>
									</div>
								</div>
								{/* Update that svg section, used for test */}
								<div
									className="hidden md:block absolute top-0 right-0 h-full w-5"
									aria-hidden="true"
								>
									<svg
										className="h-full w-full text-gray-400"
										viewBox="0 0 22 80"
										fill="none"
										preserveAspectRatio="none"
									>
										<path
											d="M0 -2L20 40L0 82"
											vectorEffect="non-scaling-stroke"
											stroke="currentcolor"
											strokeLinejoin="round"
										></path>
									</svg>
								</div>
							</div>
							{isAdmin && (
								<div
									className={
										(tabSelected === Tabs.Companies) +
										" w-tab " +
										tabStatus.companies +
										" tab-list-item-cont "
									}
									onClick={() => setTabSelected(Tabs.Companies)}
								>
									<div className="activity-tab">
										<div className="activity-tab-in">
											<OfficeBuildingIcon className="w-5 h-5 mr-1" /> <span>Companies</span>
										</div>
									</div>
									{/* Update that svg section, used for test */}
									<div
										className="hidden md:block absolute top-0 right-0 h-full w-5"
										aria-hidden="true"
									>
										<svg
											className="h-full w-full text-gray-400"
											viewBox="0 0 22 80"
											fill="none"
											preserveAspectRatio="none"
										>
											<path
												d="M0 -2L20 40L0 82"
												vectorEffect="non-scaling-stroke"
												stroke="currentcolor"
												strokeLinejoin="round"
											></path>
										</svg>
									</div>
								</div>
							)}
							<div
								className={
									(tabSelected === Tabs.Review) +
									" w-tab " +
									tabStatus.review +
									" tab-list-item-cont "
								}
								onClick={() => setTabSelected(Tabs.Review)}
							>
								<div className="activity-tab">
									<div className="activity-tab-in">
										<BsCardChecklist className="w-5 h-5 mr-1" /> <span>Review</span>
									</div>
								</div>
							</div>
						</div>
						{/* Status messages */}
						{statusMessage &&
							(isStatusMessageError ? (
								<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
									<ExclamationIcon className="h-5 w-5" />
									{statusMessage}
								</div>
							) : (
								<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
									<CheckCircleIcon className="h-5 w-5" />
									{statusMessage}
								</div>
							))}
						{tabSelected === Tabs.Instructions ? (
							<form className="flex flex-col" onSubmit={(e) => submit(e)}>
								{!!!shouldShowEditor && (
									<>
										<div
											className="my-10 animate__animated animate__fadeInLeft animate__faster"
											dangerouslySetInnerHTML={createMarkup(
												activity!.instruction! ||
													(isAdmin
														? "<i style='color: grey;'>Please Add Instructions for Users to Follow, Click on the 'edit' button to start.<i>"
														: "<i style='color: grey;'>Please Follow the Steps.<i>"),
											)}
										></div>
									</>
								)}

								{isAdmin && !shouldShowEditor && (
									<>
										<div className="flex gap-4 justify-end my-8">
											<button
												className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800 gap-1 my-10 items-center"
												onClick={() => setState({ shouldShowEditor: true })}
											>
												<PencilIcon className="w-5 h-5" />
												Edit
											</button>
										</div>
									</>
								)}
								{shouldShowEditor && (
									<>
										<RichTextEditor
											value={rteState}
											onChange={(v) => {
												setRteState(v);
											}}
										/>
										<button
											className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center my-10 hover:bg-red-800"
											type="submit"
										>
											Update
										</button>
									</>
								)}
							</form>
						) : tabSelected === Tabs.Files ? (
							<Files />
						) : tabSelected === Tabs.Templates ? (
							<Templates />
						) : tabSelected === Tabs.BidSheet ? (
							<>{isAdmin ? <ActivityTabs /> : <BidSheet />}</>
						) : tabSelected === Tabs.Survey ? (
							<>
								{/* <CreateSurvey /> */}
								<SurveyTabs />
							</>
						) : tabSelected === Tabs.Companies && isAdmin ? (
							<ActivityCompanies activityId={activity!.id} />
						) : isAdmin ? (
							<div>
								I certify that all information provided is accurate. Companys may start to submit
								the activity
							</div>
						) : (
							<div>
								I certify that all information provided is accurate and I understand I cannot change
								information once submitted.
							</div>
						)}

						{isWizardActive && (
							<div className="flex justify-end mt-4">
								{tabSelected === Tabs.Review ? (
									<Button onClick={() => submitRfp()}>
										{" "}
										Submit
										<CheckIcon className="w-5 h-5 mx-1" />
									</Button>
								) : (
									<>
										{!shouldShowEditor &&  (
											<div
												className="flex text-primary items-center cursor-pointer text-lg"
												onClick={() => gotoNextStep(tabSelected)}
											>
												{" "}
												Next Step
												<FiArrowRightCircle className="w-7 h-7 mx-1 text-primary" />
											</div>
									)}
									</>
								)}
							</div>
						)}
					</div>
				</>
			)}

			<Footer />
		</div>
	);
};

export default Activity;
