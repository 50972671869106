import React from "react";
import { useEffect } from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { useStates } from "../../../hooks/useStates";
import {
	ActivitySurveyItem,
	ActivitySurveyItemInput,
	ActivitySurveyItemOptionInput,
} from "../../../generated/graphql";
import SelectOptions from "./options/selectOptions";
import { MdCheckBox, MdRadioButtonChecked } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { EnumActivitySurveyItemType } from "./enum";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";
interface Props {
	preview: boolean;
	surveyId: number;
	keyIndex: number;
	itemType: string;
	answer?: string;
	activitySurveyItem: ActivitySurveyItem;
	update: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	delete: (id: number, index: number) => void;
	addOption: (activitySurveyItemOptionInput: ActivitySurveyItemOptionInput) => void;
	updateOption: (id: number, activitySurveyItemOptionInput: ActivitySurveyItemOptionInput) => void;
	deleteOption: (id: number, index?: number, parentIndex?: number) => void;
	updateAnswer: (id: number, updateValue: string, updateComment: string) => void;
	sortChange: (indexChange: number, direction?: number) => void;
}

type State = {
	title: string;
	description: string;
	saveValue: string;
	required: boolean;
};
const SurveyItemRadio: React.FC<Props> = (props) => {
	const [{ title, description, required, saveValue }, setState] = useStates<State>({
		title: props.activitySurveyItem.title,
		description: props.activitySurveyItem.description ?? "",
		required: props.activitySurveyItem.required,
		saveValue: props.answer ?? "",
	});
	useEffect(() => {
		setState({
			saveValue: props.answer,
		});
	}, [props.answer]);
	const onChange = (val: string) => {
		setState({
			title: val,
		});
	};
	const onChange2 = (val: string) => {
		setState({
			description: val,
		});
	};
	const onChangeRequired = () => {
		setState({
			required: !required,
		});
	};
	const onBlur = () => {
		updateItem();
	};

	const updateItem = () => {
		props.update(props.activitySurveyItem.id, {
			activitySurveyId: props.surveyId,
			section: props.activitySurveyItem.section,
			itemType: props.activitySurveyItem.itemType,
			title: title,
			description: description,
			sortOrder: props.activitySurveyItem.sortOrder,
			required: required,
		});
	};
	const onChangeSaveValue = (val: string) => {
		setState({
			saveValue: val,
		});
	};
	const onBlurSave = () => {
		updateAnswer();
	};
	const updateAnswer = () => {
		props.updateAnswer(props.activitySurveyItem.id, saveValue, "");
	};
	return (
		<>
			{props.preview ? (
				<div className="survey-card flex gap-3 w-full">
					<div className="number-tag h-10">{props.keyIndex + 1}</div>
					<div className="prev-question">
						<div className="question">{props.activitySurveyItem.title}</div>
						<div className="text-sm text-gray-400">{props.activitySurveyItem.description}</div>

						{props.activitySurveyItem.activitySurveyItemOptions &&
							props.activitySurveyItem.activitySurveyItemOptions.length > 0 &&
							props.activitySurveyItem.activitySurveyItemOptions.map((item) => (
								<div className="flex gap-3 mt-2">
									<input
										type={props.itemType}
										name={`checkbox-${props.activitySurveyItem.sortOrder}`}
										value={item.optionValue}
										checked={item.optionValue === saveValue}
										onChange={(e) => onChangeSaveValue(e.target.value)}
										onBlur={() => onBlurSave()}
									/>

									<label>{item.optionName}</label>

									<br></br>
								</div>
							))}
					</div>
				</div>
			) : (
				<div className="survey-card">
					<div className="question-header">
						<div className="flex items-center">
							<div className="number-tag-type">{props.keyIndex + 1}</div>
							<div className="question-type-tag">
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.Radio && (
									<MdRadioButtonChecked className="w-5 h-5 text-gray-600" />
								)}
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.Checkbox && (
									<MdCheckBox className="w-5 h-5 text-gray-600" />
								)}
								<div className="text-gray-600 pl-2">{props.itemType}</div>
							</div>
						</div>

						<div className="flex gap-3">
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 1)}
							>
								<HiOutlineArrowDown className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 0)}
							>
								<HiOutlineArrowUp className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-primary"
								onClick={() => {
									props.delete(props.activitySurveyItem.id, props.keyIndex);
								}}
							>
								<TrashIcon className="w-5 h-5" />
							</div>
						</div>
					</div>

					<input
						onChange={(e) => onChange(e.target.value)}
						onBlur={(e) => onBlur()}
						type="Text"
						placeholder="What's your question?"
						value={title}
					></input>

					<textarea
						onChange={(e) => onChange2(e.target.value)}
						onBlur={(e) => onBlur()}
						placeholder="Describe if necessary"
						value={description}
						rows={2}
					></textarea>

					<div className="added-options-cont">
						{props.activitySurveyItem.activitySurveyItemOptions &&
							props.activitySurveyItem.activitySurveyItemOptions.length > 0 &&
							props.activitySurveyItem.activitySurveyItemOptions.map((item, index) => (
								<SelectOptions
									key={`key-item-${item && item.id}`}
									activitySurveyItemId={props.activitySurveyItem.id}
									activitySurveyItemOption={item}
									update={props.updateOption}
									delete={props.deleteOption}
									index={index}
									parentIndex={props.keyIndex}
								/>
							))}
					</div>

					<div
						className="flex text-primary cursor-pointer items-center gap-1 mt-8"
						onClick={() => {
							props.addOption({
								activitySurveyItemId: props.activitySurveyItem.id,
								optionName: "",
								optionValue: props.activitySurveyItem.activitySurveyItemOptions
									? props.activitySurveyItem.activitySurveyItemOptions?.length.toString()
									: "",
							});
						}}
					>
						<AiOutlineAppstoreAdd className="w-5 h-5" />
						Add Option
					</div>
					<div className="question-footer">
						<div className="flex mt-4">
							<label className="switch switch-left-right">
								<input
									className="switch-input"
									type="checkbox"
									checked={required}
									onChange={(e) => onChangeRequired()}
									onBlur={(e) => onBlur()}
								/>
								<span className="switch-label" data-on="" data-off=""></span>
								<span className="switch-handle"></span>
							</label>
							<div> Required</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyItemRadio;
