import React from "react";
import { useRecoilValue } from "recoil";
import { loadingMessageAtom } from "../state/loading.recoil";

const Loading = () => {
	const loadingMessage = useRecoilValue(loadingMessageAtom);
	if (!loadingMessage) {
		return null;
	}

	return (
		<div className="flex items-center justify-center fixed top-0 left-0 z-50 w-screen h-screen">
			<div className="fixed top-0 left-0 bg-gray-800 opacity-40 w-screen h-screen" />

			<div className="flex items-center text-white text-3xl z-50">
				<div className="rounded-full border-t-8 border-b-8 border-primary animate-spin h-12 w-12 mr-3 text-white"></div>
				{loadingMessage}
			</div>
		</div>
	);
};

export default Loading;
