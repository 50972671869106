import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Void: any;
};

export type ActActiveUsers = {
  __typename?: 'ActActiveUsers';
  id: Scalars['Int'];
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['Date']>;
  user: User;
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  activeAt?: Maybe<Scalars['Date']>;
  expiresAt: Scalars['Date'];
  hiddenColumns?: Maybe<Scalars['String']>;
};

export type ActivityActiveUserInput = {
  id?: Maybe<Scalars['Int']>;
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ActivityAnnouncement = {
  __typename?: 'ActivityAnnouncement';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  activityId: Scalars['Int'];
  title: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type ActivityCompany = {
  __typename?: 'ActivityCompany';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
  submittedAt?: Maybe<Scalars['Date']>;
  company: Company;
};

export type ActivityCompanyBidsheet = {
  __typename?: 'ActivityCompanyBidsheet';
  totalCount: Scalars['Int'];
  activityProduct: Array<ActivityProduct>;
  BidSheetProduct: Array<BidSheetProduct>;
};

export type ActivityCompanyInput = {
  submittedAt?: Maybe<Scalars['Date']>;
};

export type ActivityInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  expiresAt: Scalars['Date'];
  activeAt?: Maybe<Scalars['Date']>;
  hiddenColumns?: Maybe<Scalars['String']>;
};

export type ActivityProduct = {
  __typename?: 'ActivityProduct';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  activityId: Scalars['Int'];
  rfpItemNumber?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  newCatColor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  east?: Maybe<Scalars['Int']>;
  west?: Maybe<Scalars['Int']>;
  milMicGauge?: Maybe<Scalars['String']>;
};

export type ActivityProductInput = {
  rfpItemNumber?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  newCatColor?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  east?: Maybe<Scalars['Int']>;
  west?: Maybe<Scalars['Int']>;
  milMicGauge?: Maybe<Scalars['String']>;
};

export enum ActivityStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Expired = 'EXPIRED'
}

export type ActivitySurvey = {
  __typename?: 'ActivitySurvey';
  id: Scalars['Int'];
  activityId: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
  activitySurveyItems?: Maybe<Array<ActivitySurveyItem>>;
};

export type ActivitySurveyCompany = {
  __typename?: 'ActivitySurveyCompany';
  id: Scalars['Int'];
  activitySurveyId: Scalars['Int'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
  activitySurveyCompanyAnswers?: Maybe<Array<ActivitySurveyCompanyAnswer>>;
};

export type ActivitySurveyCompanyAnswerInput = {
  activitySurveyCompanyId: Scalars['Int'];
  activitySurveyItemId: Scalars['Int'];
  answer: Scalars['String'];
  answerComment: Scalars['String'];
};

export type ActivitySurveyCompanyAnswerOutput = {
  __typename?: 'ActivitySurveyCompanyAnswerOutput';
  activitySurveyCompanyId: Scalars['Int'];
  activitySurveyItemId: Scalars['Int'];
  answer: Scalars['String'];
  answerComment?: Maybe<Scalars['String']>;
};

export type ActivitySurveyCompanyInput = {
  activitySurveyId: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type ActivitySurveyInput = {
  activityId: Scalars['Int'];
};

export type ActivitySurveyItem = {
  __typename?: 'ActivitySurveyItem';
  id: Scalars['Int'];
  activitySurveyId: Scalars['Int'];
  section: Scalars['Int'];
  itemType: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  activitySurveyItemChildren?: Maybe<Array<ActivitySurveyItem>>;
  activitySurveyItemOptions?: Maybe<Array<ActivitySurveyItemOptions>>;
  required: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
  parentActivitySurveyItemId?: Maybe<Scalars['Int']>;
};

export type ActivitySurveyItemInput = {
  activitySurveyId: Scalars['Int'];
  section: Scalars['Int'];
  itemType: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  required: Scalars['Boolean'];
  parentActivitySurveyItemId?: Maybe<Scalars['Int']>;
};

export type ActivitySurveyItemInputWithOptionsInput = {
  activitySurveyId: Scalars['Int'];
  section: Scalars['Int'];
  itemType: Scalars['Int'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sortOrder: Scalars['Int'];
  required: Scalars['Boolean'];
  activitySurveyItemOptions?: Maybe<Array<ActivitySurveyItemOptionInputs>>;
  parentActivitySurveyItemId?: Maybe<Scalars['Int']>;
};

export type ActivitySurveyItemInputs = {
  id: Scalars['Int'];
  activitySurveyItemInputWithOptions: ActivitySurveyItemInputWithOptionsInput;
};

export type ActivitySurveyItemOptionInput = {
  activitySurveyItemId: Scalars['Int'];
  optionName: Scalars['String'];
  optionValue: Scalars['String'];
};

export type ActivitySurveyItemOptionInputs = {
  id: Scalars['Int'];
  activitySurveyItemOption: ActivitySurveyItemOptionInput;
};

export type ActivitySurveyItemOptions = {
  __typename?: 'ActivitySurveyItemOptions';
  id: Scalars['Int'];
  activitySurveyItemId: Scalars['Int'];
  optionName: Scalars['String'];
  optionValue: Scalars['String'];
  createdAt: Scalars['Date'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type ActivitySurveyItemTypes = {
  __typename?: 'ActivitySurveyItemTypes';
  id: Scalars['Int'];
  type: Scalars['String'];
};

export type BidSheetProduct = {
  __typename?: 'BidSheetProduct';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  activityProductId: Scalars['Int'];
  companyId: Scalars['Int'];
  netDeliveredCaseWest?: Maybe<Scalars['Float']>;
  netDeliveredCaseEast?: Maybe<Scalars['Float']>;
  manufName?: Maybe<Scalars['String']>;
  equivMfrNumber?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  manufacturerProductDescription?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  gtinNumber?: Maybe<Scalars['String']>;
  packSize?: Maybe<Scalars['String']>;
  isSepticSafe?: Maybe<Scalars['Boolean']>;
  numberOfSheetsPerCase?: Maybe<Scalars['Int']>;
  linearFtPerCase?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  willProduceInCompanions?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  gauge?: Maybe<Scalars['String']>;
  rollOrFlatPack?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type BidSheetProductInput = {
  netDeliveredCaseWest?: Maybe<Scalars['Float']>;
  netDeliveredCaseEast?: Maybe<Scalars['Float']>;
  manufName?: Maybe<Scalars['String']>;
  equivMfrNumber?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  manufacturerProductDescription?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  gtinNumber?: Maybe<Scalars['String']>;
  packSize?: Maybe<Scalars['String']>;
  isSepticSafe?: Maybe<Scalars['Boolean']>;
  numberOfSheetsPerCase?: Maybe<Scalars['Int']>;
  linearFtPerCase?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  willProduceInCompanions?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  gauge?: Maybe<Scalars['String']>;
  rollOrFlatPack?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type CompaniesBidsheetProduct = {
  __typename?: 'CompaniesBidsheetProduct';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  activityProductId: Scalars['Int'];
  companyId: Scalars['Int'];
  netDeliveredCaseWest?: Maybe<Scalars['Float']>;
  netDeliveredCaseEast?: Maybe<Scalars['Float']>;
  manufName?: Maybe<Scalars['String']>;
  equivMfrNumber?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  manufacturerProductDescription?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  gtinNumber?: Maybe<Scalars['String']>;
  packSize?: Maybe<Scalars['String']>;
  isSepticSafe?: Maybe<Scalars['Boolean']>;
  numberOfSheetsPerCase?: Maybe<Scalars['Int']>;
  linearFtPerCase?: Maybe<Scalars['Float']>;
  netWeight?: Maybe<Scalars['Float']>;
  willProduceInCompanions?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  gauge?: Maybe<Scalars['String']>;
  rollOrFlatPack?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  activityProduct?: Maybe<ActivityProduct>;
};

export type CompaniesBidsheetProductFilter = {
  companyName?: Maybe<Scalars['String']>;
  rfpItemNumber?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  cat?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  newCatColor?: Maybe<Scalars['String']>;
  totalMin?: Maybe<Scalars['Int']>;
  totalMax?: Maybe<Scalars['Int']>;
  eastMin?: Maybe<Scalars['Int']>;
  eastMax?: Maybe<Scalars['Int']>;
  westMin?: Maybe<Scalars['Int']>;
  westMax?: Maybe<Scalars['Int']>;
  milMicGauge?: Maybe<Scalars['String']>;
  netDeliveredCaseWestMin?: Maybe<Scalars['Float']>;
  netDeliveredCaseWestMax?: Maybe<Scalars['Float']>;
  netDeliveredCaseEastMin?: Maybe<Scalars['Float']>;
  netDeliveredCaseEastMax?: Maybe<Scalars['Float']>;
  manufName?: Maybe<Scalars['String']>;
  equivMfrNumber?: Maybe<Scalars['String']>;
  bidTier?: Maybe<Scalars['String']>;
  manufacturerProductDescription?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  gtinNumber?: Maybe<Scalars['String']>;
  packSize?: Maybe<Scalars['String']>;
  isSepticSafe?: Maybe<Scalars['Boolean']>;
  numberOfSheetsPerCaseMin?: Maybe<Scalars['Int']>;
  numberOfSheetsPerCaseMax?: Maybe<Scalars['Int']>;
  linearFtPerCaseMin?: Maybe<Scalars['Float']>;
  linearFtPerCaseMax?: Maybe<Scalars['Float']>;
  netWeightMin?: Maybe<Scalars['Float']>;
  netWeightMax?: Maybe<Scalars['Float']>;
  willProduceInCompanions?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  material?: Maybe<Scalars['String']>;
  gauge?: Maybe<Scalars['String']>;
  rollOrFlatPack?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  typeCompanyType: CompanyType;
  name: Scalars['String'];
};

export type CompanyInput = {
  type: CompanyTypeNames;
  name: Scalars['String'];
};

export type CompanyType = {
  __typename?: 'CompanyType';
  name: CompanyTypeNames;
  description?: Maybe<Scalars['String']>;
};

export enum CompanyTypeNames {
  Supplier = 'SUPPLIER',
  Member = 'MEMBER'
}

export type CompanyUser = {
  __typename?: 'CompanyUser';
  id: Scalars['Int'];
  user: User;
  company: Company;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type CompanyUserInput = {
  companyId: Scalars['Int'];
  email: Scalars['String'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type File = {
  __typename?: 'File';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  company?: Maybe<Company>;
  activity: Activity;
  typeFileType: FileType;
  name?: Maybe<Scalars['String']>;
};

export type FileIdName = {
  __typename?: 'FileIdName';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
};

export type FileType = {
  __typename?: 'FileType';
  name: FileTypeNames;
  description?: Maybe<Scalars['String']>;
};

export enum FileTypeNames {
  Template = 'TEMPLATE',
  Document = 'DOCUMENT',
  Survey = 'SURVEY'
}

export type GetActivitiesFilter = {
  likeName?: Maybe<Scalars['String']>;
  status?: Maybe<ActivityStatus>;
  companyId?: Maybe<Scalars['Int']>;
};

export type GetActivitiesInput = {
  pagination?: Maybe<Pagination>;
  orderBys?: Maybe<Array<OrderBy>>;
  filter?: Maybe<GetActivitiesFilter>;
};

export type GetActivitiesOutput = {
  __typename?: 'GetActivitiesOutput';
  totalCount: Scalars['Int'];
  nodes: Array<Activity>;
};

export type GetActivityActiveUsersOutput = {
  __typename?: 'GetActivityActiveUsersOutput';
  totalCount: Scalars['Int'];
  nodes: Array<ActActiveUsers>;
};

export type GetActivityCompanyOutput = {
  __typename?: 'GetActivityCompanyOutput';
  totalCount: Scalars['Int'];
  nodes: Array<ActivityCompany>;
};

export type GetActivityProductsInput = {
  activityId: Scalars['Int'];
  pagination?: Maybe<Pagination>;
  orderBys?: Maybe<Array<OrderBy>>;
};

export type GetActivityProductsOutput = {
  __typename?: 'GetActivityProductsOutput';
  totalCount: Scalars['Int'];
  nodes: Array<ActivityProduct>;
};

export type GetActivitySurveyCompanyAnswersOutput = {
  __typename?: 'GetActivitySurveyCompanyAnswersOutput';
  activitySurveyCompanyId: Scalars['Int'];
  company: Scalars['String'];
  questionsAndAnswers?: Maybe<Scalars['String']>;
};

export type GetActivitySurveyCompanyOutput = {
  __typename?: 'GetActivitySurveyCompanyOutput';
  activitySurveyCompanyId: Scalars['Int'];
  activitySurveyCompanyAnswers?: Maybe<Array<ActivitySurveyCompanyAnswerOutput>>;
};

export type GetActivitySurveyExistsOutput = {
  __typename?: 'GetActivitySurveyExistsOutput';
  hasValue: Scalars['Boolean'];
};

export type GetActivitySurveyOutput = {
  __typename?: 'GetActivitySurveyOutput';
  totalCount: Scalars['Int'];
  nodes: Array<ActivitySurvey>;
};

export type GetBidSheetAsCsvInput = {
  activityId: Scalars['Int'];
};

export type GetCompaniesBidsheetProductInput = {
  activityId?: Maybe<Scalars['Int']>;
  pagination?: Maybe<Pagination>;
  orderBys?: Maybe<Array<OrderBy>>;
  filter?: Maybe<CompaniesBidsheetProductFilter>;
};

export type GetCompaniesBidsheetProductOutput = {
  __typename?: 'GetCompaniesBidsheetProductOutput';
  totalCount: Scalars['Int'];
  nodes: Array<CompaniesBidsheetProduct>;
};

export type GetCompaniesFilter = {
  likeName?: Maybe<Scalars['String']>;
  likeAny?: Maybe<Scalars['String']>;
};

export type GetCompaniesInput = {
  pagination?: Maybe<Pagination>;
  orderBys?: Maybe<Array<OrderBy>>;
  filter?: Maybe<GetCompaniesFilter>;
};

export type GetCompaniesOutput = {
  __typename?: 'GetCompaniesOutput';
  totalCount: Scalars['Int'];
  nodes: Array<Company>;
};

export type GetCompanyUsersFilter = {
  likeAny?: Maybe<Scalars['String']>;
  companyIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type GetCompanyUsersInput = {
  pagination?: Maybe<Pagination>;
  orderBys?: Maybe<Array<OrderBy>>;
  filter?: Maybe<GetCompanyUsersFilter>;
};

export type GetCompanyUsersOutput = {
  __typename?: 'GetCompanyUsersOutput';
  totalCount: Scalars['Int'];
  nodes: Array<CompanyUser>;
};

export type LoginCredentials = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  token: Scalars['String'];
  userType: UserTypeNames;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTest?: Maybe<Scalars['Date']>;
  login: LoginOutput;
  addCompanyUser: Scalars['Int'];
  updateCompanyUser?: Maybe<Scalars['Void']>;
  deleteUser?: Maybe<Scalars['Void']>;
  forgotPassword?: Maybe<Scalars['Void']>;
  resetPassword?: Maybe<Scalars['Void']>;
  resetTokenCheck?: Maybe<Scalars['Void']>;
  addCompany: Scalars['Int'];
  updateCompany?: Maybe<Scalars['Void']>;
  deleteCompany?: Maybe<Scalars['Void']>;
  addActivity: Scalars['Int'];
  updateActivity?: Maybe<Scalars['Void']>;
  updateActivityOnFinalize?: Maybe<Scalars['Void']>;
  deleteActivity?: Maybe<Scalars['Void']>;
  deleteFile?: Maybe<Scalars['Void']>;
  addActivityProduct: Scalars['Int'];
  updateActivityProduct?: Maybe<Scalars['Void']>;
  deleteActivityProduct?: Maybe<Scalars['Void']>;
  addBidSheetProduct: Scalars['Int'];
  updateBidSheetProduct?: Maybe<Scalars['Void']>;
  addOrUpdateBidSheetProduct?: Maybe<Scalars['Int']>;
  addActivityCompany?: Maybe<Scalars['Void']>;
  updateActivityCompany?: Maybe<Scalars['Void']>;
  deleteActivityCompany?: Maybe<Scalars['Void']>;
  addActivitySurvey?: Maybe<Scalars['Void']>;
  deleteActivitySurvey?: Maybe<Scalars['Void']>;
  addActivitySurveyItem?: Maybe<Scalars['Void']>;
  updateActivitySurveyItem?: Maybe<Scalars['Void']>;
  updateActivitySurveyItems?: Maybe<Scalars['Void']>;
  deleteActivitySurveyItem?: Maybe<Scalars['Void']>;
  addActivitySurveyItemOption?: Maybe<Scalars['Void']>;
  updateActivitySurveyItemOption?: Maybe<Scalars['Void']>;
  deleteActivitySurveyItemOption?: Maybe<Scalars['Void']>;
  addActivitySurveyCompany?: Maybe<Scalars['Void']>;
  updateActivitySurveyCompanyAnswer?: Maybe<Scalars['Void']>;
  addOrEditActivityActiveUser?: Maybe<ActActiveUsers>;
  deleteActivityActiveUser?: Maybe<Scalars['Void']>;
};


export type MutationAddTestArgs = {
  dateI?: Maybe<Scalars['Date']>;
};


export type MutationLoginArgs = {
  loginCredentials: LoginCredentials;
};


export type MutationAddCompanyUserArgs = {
  companyUserInput: CompanyUserInput;
};


export type MutationUpdateCompanyUserArgs = {
  companyUserId: Scalars['Int'];
  updateCompanyUserInput: UpdateCompanyUserInput;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationResetTokenCheckArgs = {
  token: Scalars['String'];
};


export type MutationAddCompanyArgs = {
  companyInput: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  companyId: Scalars['Int'];
  companyInput: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['Int'];
};


export type MutationAddActivityArgs = {
  activityInput: ActivityInput;
};


export type MutationUpdateActivityArgs = {
  activityId: Scalars['Int'];
  activityInput: ActivityInput;
};


export type MutationUpdateActivityOnFinalizeArgs = {
  activityId: Scalars['Int'];
  activityInput: ActivityInput;
};


export type MutationDeleteActivityArgs = {
  activityId: Scalars['Int'];
};


export type MutationDeleteFileArgs = {
  id: Scalars['Int'];
};


export type MutationAddActivityProductArgs = {
  activityId: Scalars['Int'];
  activityProductInput: ActivityProductInput;
};


export type MutationUpdateActivityProductArgs = {
  activityProductId: Scalars['Int'];
  activityProductInput: ActivityProductInput;
};


export type MutationDeleteActivityProductArgs = {
  activityProductId: Scalars['Int'];
};


export type MutationAddBidSheetProductArgs = {
  activityProductId: Scalars['Int'];
  bidSheetProductInput: BidSheetProductInput;
};


export type MutationUpdateBidSheetProductArgs = {
  bidSheetProductId: Scalars['Int'];
  bidSheetProductInput: BidSheetProductInput;
};


export type MutationAddOrUpdateBidSheetProductArgs = {
  activityProductId: Scalars['Int'];
  bidSheetProductInput: BidSheetProductInput;
};


export type MutationAddActivityCompanyArgs = {
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
};


export type MutationUpdateActivityCompanyArgs = {
  id: Scalars['Int'];
  activityCompaniesInput: ActivityCompanyInput;
};


export type MutationDeleteActivityCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationAddActivitySurveyArgs = {
  activityId: Scalars['Int'];
};


export type MutationDeleteActivitySurveyArgs = {
  id: Scalars['Int'];
};


export type MutationAddActivitySurveyItemArgs = {
  activitySurveyItemInput: ActivitySurveyItemInput;
};


export type MutationUpdateActivitySurveyItemArgs = {
  id: Scalars['Int'];
  activitySurveyItemInput: ActivitySurveyItemInput;
};


export type MutationUpdateActivitySurveyItemsArgs = {
  activitySurveyItemInputs: Array<ActivitySurveyItemInputs>;
};


export type MutationDeleteActivitySurveyItemArgs = {
  id: Scalars['Int'];
};


export type MutationAddActivitySurveyItemOptionArgs = {
  activitySurveyItemOptionInput: ActivitySurveyItemOptionInput;
};


export type MutationUpdateActivitySurveyItemOptionArgs = {
  id: Scalars['Int'];
  activitySurveyItemOptionInput: ActivitySurveyItemOptionInput;
};


export type MutationDeleteActivitySurveyItemOptionArgs = {
  id: Scalars['Int'];
};


export type MutationAddActivitySurveyCompanyArgs = {
  activitySurveyCompanyInput: ActivitySurveyCompanyInput;
};


export type MutationUpdateActivitySurveyCompanyAnswerArgs = {
  activitySurveyCompanyAnswerInput: ActivitySurveyCompanyAnswerInput;
};


export type MutationAddOrEditActivityActiveUserArgs = {
  addOrEditActivityActiveUserInput: ActivityActiveUserInput;
};


export type MutationDeleteActivityActiveUserArgs = {
  ActivityActiveUserId: Scalars['Int'];
};

export type OrderBy = {
  by: Scalars['String'];
  isDesc?: Maybe<Scalars['Boolean']>;
};

export type Pagination = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  getUser: User;
  getCompanyUsers: GetCompanyUsersOutput;
  getCompanies: GetCompaniesOutput;
  getCompaniesThatHasFileCount: Scalars['Int'];
  getCompaniesThatHasBidCount: Scalars['Int'];
  getActivities: GetActivitiesOutput;
  getActivity?: Maybe<Activity>;
  getDocumentFiles: Array<File>;
  getTemplateFiles: Array<File>;
  getSurveyFiles: Array<File>;
  getSurveyFile: FileIdName;
  getSurveyFileBySurveyItem: File;
  getLatestUpdatedFile: File;
  getActivityProducts: GetActivityProductsOutput;
  getCompaniesBidsheetProduct: GetCompaniesBidsheetProductOutput;
  getBidSheetProducts: Array<BidSheetProduct>;
  getLatestUpdatedBidSheetProduct: BidSheetProduct;
  getBidSheetAsCSV: Scalars['String'];
  getActivityCompanies: GetActivityCompanyOutput;
  getActivitySurveys: GetActivitySurveyOutput;
  getActivitySurveyExists: GetActivitySurveyExistsOutput;
  getActivitySurveyCompany?: Maybe<GetActivitySurveyCompanyOutput>;
  getActivitySurveyAnswers?: Maybe<Array<GetActivitySurveyCompanyAnswersOutput>>;
  getActivitySurveyAnswersAsCSV: Scalars['String'];
  getActivityActiveUsers: GetActivityActiveUsersOutput;
};


export type QueryGetCompanyUsersArgs = {
  getCompanyUsersInput?: Maybe<GetCompanyUsersInput>;
};


export type QueryGetCompaniesArgs = {
  getCompaniesInput?: Maybe<GetCompaniesInput>;
};


export type QueryGetCompaniesThatHasFileCountArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetCompaniesThatHasBidCountArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetActivitiesArgs = {
  input?: Maybe<GetActivitiesInput>;
};


export type QueryGetActivityArgs = {
  id: Scalars['Int'];
};


export type QueryGetDocumentFilesArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetTemplateFilesArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetSurveyFilesArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetSurveyFileArgs = {
  id: Scalars['Int'];
};


export type QueryGetSurveyFileBySurveyItemArgs = {
  surveyItemId: Scalars['Int'];
  surveyCompanyId: Scalars['Int'];
};


export type QueryGetLatestUpdatedFileArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetActivityProductsArgs = {
  getActivityProductsInput: GetActivityProductsInput;
};


export type QueryGetCompaniesBidsheetProductArgs = {
  getCompaniesBidsheetProductInput?: Maybe<GetCompaniesBidsheetProductInput>;
};


export type QueryGetBidSheetProductsArgs = {
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
};


export type QueryGetLatestUpdatedBidSheetProductArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetBidSheetAsCsvArgs = {
  input: GetBidSheetAsCsvInput;
};


export type QueryGetActivityCompaniesArgs = {
  activityId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
};


export type QueryGetActivitySurveysArgs = {
  activityId?: Maybe<Scalars['Int']>;
};


export type QueryGetActivitySurveyExistsArgs = {
  activityId?: Maybe<Scalars['Int']>;
};


export type QueryGetActivitySurveyCompanyArgs = {
  activitySurveyId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
};


export type QueryGetActivitySurveyAnswersArgs = {
  activityId?: Maybe<Scalars['Int']>;
};


export type QueryGetActivitySurveyAnswersAsCsvArgs = {
  activityId: Scalars['Int'];
};


export type QueryGetActivityActiveUsersArgs = {
  activityId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateCompanyUserInput = {
  companyId?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt?: Maybe<Scalars['Date']>;
  typeUserType: UserType;
  username: Scalars['String'];
  userCompanyUser?: Maybe<CompanyUser>;
};

export type UserType = {
  __typename?: 'UserType';
  name: UserTypeNames;
  description?: Maybe<Scalars['String']>;
};

export enum UserTypeNames {
  Admin = 'ADMIN',
  CompanyUser = 'COMPANY_USER'
}


export type ActivitySurveyCompanyAnswer = {
  __typename?: 'activitySurveyCompanyAnswer';
  id: Scalars['Int'];
  activitySurveyCompanyId: Scalars['Int'];
  activitySurveyItemId: Scalars['Int'];
  answer: Scalars['String'];
  answerComment: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['Int']>;
};

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'username'>
  & { typeUserType: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'name' | 'description'>
  ), userCompanyUser?: Maybe<(
    { __typename?: 'CompanyUser' }
    & Pick<CompanyUser, 'id' | 'name' | 'title'>
    & { company: (
      { __typename?: 'Company' }
      & CompanyFragment
    ) }
  )> }
);

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name'>
  & { typeCompanyType: (
    { __typename?: 'CompanyType' }
    & Pick<CompanyType, 'name' | 'description'>
  ) }
);

export type CompanySearchFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name'>
);

export type CompanyUserFragment = (
  { __typename?: 'CompanyUser' }
  & Pick<CompanyUser, 'id' | 'name' | 'title'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'username'>
    & { typeUserType: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'name' | 'description'>
    ) }
  ), company: (
    { __typename?: 'Company' }
    & CompanyFragment
  ) }
);

export type FileFragment = (
  { __typename?: 'File' }
  & Pick<File, 'id' | 'createdAt' | 'updatedAt' | 'name'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )>, activity: (
    { __typename?: 'Activity' }
    & ActivityFragment
  ), typeFileType: (
    { __typename?: 'FileType' }
    & Pick<FileType, 'name' | 'description'>
  ) }
);

export type FileIdNameFragment = (
  { __typename?: 'FileIdName' }
  & Pick<FileIdName, 'id' | 'name'>
);

export type ActivityFragment = (
  { __typename?: 'Activity' }
  & Pick<Activity, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'description' | 'instruction' | 'expiresAt' | 'activeAt' | 'hiddenColumns'>
);

export type BidSheetProductFragment = (
  { __typename?: 'BidSheetProduct' }
  & Pick<BidSheetProduct, 'id' | 'brand' | 'gtinNumber' | 'packSize' | 'isSepticSafe' | 'numberOfSheetsPerCase' | 'linearFtPerCase' | 'netWeight' | 'willProduceInCompanions' | 'notes' | 'createdAt' | 'updatedAt' | 'activityProductId' | 'netDeliveredCaseWest' | 'netDeliveredCaseEast' | 'manufName' | 'equivMfrNumber' | 'tier' | 'manufacturerProductDescription' | 'material' | 'gauge' | 'rollOrFlatPack' | 'other'>
);

export type ActivityProductFragment = (
  { __typename?: 'ActivityProduct' }
  & Pick<ActivityProduct, 'id' | 'total' | 'east' | 'west' | 'createdAt' | 'updatedAt' | 'activityId' | 'rfpItemNumber' | 'tier' | 'cat' | 'sub' | 'newCatColor' | 'type' | 'milMicGauge'>
);

export type ActivitySurveyFragment = (
  { __typename?: 'ActivitySurvey' }
  & Pick<ActivitySurvey, 'id' | 'activityId' | 'createdAt'>
  & { activitySurveyItems?: Maybe<Array<(
    { __typename?: 'ActivitySurveyItem' }
    & Pick<ActivitySurveyItem, 'id' | 'sortOrder' | 'section' | 'title' | 'description' | 'itemType' | 'required'>
    & { activitySurveyItemOptions?: Maybe<Array<(
      { __typename?: 'ActivitySurveyItemOptions' }
      & Pick<ActivitySurveyItemOptions, 'id' | 'optionName' | 'optionValue'>
    )>> }
  )>> }
);

export type ActivityCompanyFragment = (
  { __typename?: 'ActivityCompany' }
  & Pick<ActivityCompany, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'activityId' | 'companyId' | 'submittedAt'>
  & { company: (
    { __typename?: 'Company' }
    & Pick<Company, 'name'>
  ) }
);

export type ActivitySurveyCompanyFragment = (
  { __typename?: 'ActivitySurveyCompany' }
  & Pick<ActivitySurveyCompany, 'activitySurveyId'>
  & { activitySurveyCompanyAnswers?: Maybe<Array<(
    { __typename?: 'activitySurveyCompanyAnswer' }
    & Pick<ActivitySurveyCompanyAnswer, 'activitySurveyItemId' | 'activitySurveyCompanyId' | 'answer' | 'answerComment'>
  )>> }
);

export type LoginMutationVariables = Exact<{
  loginCredentials: LoginCredentials;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginOutput' }
    & Pick<LoginOutput, 'token' | 'userType'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResetTokenCheckMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ResetTokenCheckMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetTokenCheck'>
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type AddCompanyUserMutationVariables = Exact<{
  companyUserInput: CompanyUserInput;
}>;


export type AddCompanyUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCompanyUser'>
);

export type UpdateCompanyUserMutationVariables = Exact<{
  companyUserId: Scalars['Int'];
  updateCompanyUserInput: UpdateCompanyUserInput;
}>;


export type UpdateCompanyUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompanyUser'>
);

export type AddCompanyMutationVariables = Exact<{
  companyInput: CompanyInput;
}>;


export type AddCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCompany'>
);

export type UpdateCompanyMutationVariables = Exact<{
  companyId: Scalars['Int'];
  companyInput: CompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCompany'>
);

export type AddActivityMutationVariables = Exact<{
  activityInput: ActivityInput;
}>;


export type AddActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivity'>
);

export type UpdateActivityMutationVariables = Exact<{
  activityId: Scalars['Int'];
  activityInput: ActivityInput;
}>;


export type UpdateActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivity'>
);

export type UpdateActivityOnFinalizeMutationVariables = Exact<{
  activityId: Scalars['Int'];
  activityInput: ActivityInput;
}>;


export type UpdateActivityOnFinalizeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivityOnFinalize'>
);

export type DeleteActivityMutationVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type DeleteActivityMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivity'>
);

export type DeleteCompanyMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCompany'>
);

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteFileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteFile'>
);

export type AddActivityProductMutationVariables = Exact<{
  activityId: Scalars['Int'];
  activityProductInput: ActivityProductInput;
}>;


export type AddActivityProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivityProduct'>
);

export type UpdateActivityProductMutationVariables = Exact<{
  activityProductId: Scalars['Int'];
  activityProductInput: ActivityProductInput;
}>;


export type UpdateActivityProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivityProduct'>
);

export type DeleteActivityProductMutationVariables = Exact<{
  activityProductId: Scalars['Int'];
}>;


export type DeleteActivityProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivityProduct'>
);

export type AddActivitySurveyMutationVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type AddActivitySurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivitySurvey'>
);

export type DeleteActivitySurveyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteActivitySurveyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivitySurvey'>
);

export type AddActivitySurveyItemMutationVariables = Exact<{
  activitySurveyItemInput: ActivitySurveyItemInput;
}>;


export type AddActivitySurveyItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivitySurveyItem'>
);

export type UpdateActivitySurveyItemMutationVariables = Exact<{
  id: Scalars['Int'];
  activitySurveyItemInput: ActivitySurveyItemInput;
}>;


export type UpdateActivitySurveyItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivitySurveyItem'>
);

export type UpdateActivitySurveyItemsMutationVariables = Exact<{
  activitySurveyItemInputs: Array<ActivitySurveyItemInputs> | ActivitySurveyItemInputs;
}>;


export type UpdateActivitySurveyItemsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivitySurveyItems'>
);

export type DeleteActivitySurveyItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteActivitySurveyItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivitySurveyItem'>
);

export type AddActivitySurveyItemOptionMutationVariables = Exact<{
  activitySurveyItemOptionInput: ActivitySurveyItemOptionInput;
}>;


export type AddActivitySurveyItemOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivitySurveyItemOption'>
);

export type UpdateActivitySurveyItemOptionMutationVariables = Exact<{
  id: Scalars['Int'];
  activitySurveyItemOptionInput: ActivitySurveyItemOptionInput;
}>;


export type UpdateActivitySurveyItemOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivitySurveyItemOption'>
);

export type DeleteActivitySurveyItemOptionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteActivitySurveyItemOptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivitySurveyItemOption'>
);

export type AddOrUpdateBidSheetProductMutationVariables = Exact<{
  activityProductId: Scalars['Int'];
  bidSheetProductInput: BidSheetProductInput;
}>;


export type AddOrUpdateBidSheetProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addOrUpdateBidSheetProduct'>
);

export type AddActivityCompanyMutationVariables = Exact<{
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type AddActivityCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivityCompany'>
);

export type UpdateActivityCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
  activityCompaniesInput: ActivityCompanyInput;
}>;


export type UpdateActivityCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivityCompany'>
);

export type DeleteActivityCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteActivityCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivityCompany'>
);

export type AddActivitySurveyCompanyMutationVariables = Exact<{
  activitySurveyId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type AddActivitySurveyCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addActivitySurveyCompany'>
);

export type UpdateActivitySurveyCompanyAnswerMutationVariables = Exact<{
  activitySurveyCompanyAnswerInput: ActivitySurveyCompanyAnswerInput;
}>;


export type UpdateActivitySurveyCompanyAnswerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateActivitySurveyCompanyAnswer'>
);

export type AddOrEditActivityActiveUserMutationVariables = Exact<{
  addOrEditActivityActiveUserInput: ActivityActiveUserInput;
}>;


export type AddOrEditActivityActiveUserMutation = (
  { __typename?: 'Mutation' }
  & { addOrEditActivityActiveUser?: Maybe<(
    { __typename?: 'ActActiveUsers' }
    & Pick<ActActiveUsers, 'id' | 'activityId' | 'companyId' | 'updatedAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username'>
      & { userCompanyUser?: Maybe<(
        { __typename?: 'CompanyUser' }
        & Pick<CompanyUser, 'name'>
      )> }
    ) }
  )> }
);

export type DeleteActivityActiveUserMutationVariables = Exact<{
  ActivityActiveUserId: Scalars['Int'];
}>;


export type DeleteActivityActiveUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteActivityActiveUser'>
);

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type GetCompanyUsersQueryVariables = Exact<{
  getCompanyUsersInput?: Maybe<GetCompanyUsersInput>;
}>;


export type GetCompanyUsersQuery = (
  { __typename?: 'Query' }
  & { getCompanyUsers: (
    { __typename?: 'GetCompanyUsersOutput' }
    & Pick<GetCompanyUsersOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'CompanyUser' }
      & CompanyUserFragment
    )> }
  ) }
);

export type GetDocumentFilesQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetDocumentFilesQuery = (
  { __typename?: 'Query' }
  & { getDocumentFiles: Array<(
    { __typename?: 'File' }
    & FileFragment
  )> }
);

export type GetTemplateFilesQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetTemplateFilesQuery = (
  { __typename?: 'Query' }
  & { getTemplateFiles: Array<(
    { __typename?: 'File' }
    & FileFragment
  )> }
);

export type GetSurveyFilesQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetSurveyFilesQuery = (
  { __typename?: 'Query' }
  & { getSurveyFiles: Array<(
    { __typename?: 'File' }
    & FileFragment
  )> }
);

export type GetSurveyFileQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSurveyFileQuery = (
  { __typename?: 'Query' }
  & { getSurveyFile: (
    { __typename?: 'FileIdName' }
    & FileIdNameFragment
  ) }
);

export type GetSurveyFileBySurveyItemQueryVariables = Exact<{
  surveyItemId: Scalars['Int'];
  surveyCompanyId: Scalars['Int'];
}>;


export type GetSurveyFileBySurveyItemQuery = (
  { __typename?: 'Query' }
  & { getSurveyFileBySurveyItem: (
    { __typename?: 'File' }
    & Pick<File, 'id' | 'name'>
  ) }
);

export type GetCompaniesQueryVariables = Exact<{
  getCompaniesInput?: Maybe<GetCompaniesInput>;
}>;


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & { getCompanies: (
    { __typename?: 'GetCompaniesOutput' }
    & Pick<GetCompaniesOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Company' }
      & CompanyFragment
    )> }
  ) }
);

export type SearchCompaniesQueryVariables = Exact<{
  input?: Maybe<GetCompaniesInput>;
}>;


export type SearchCompaniesQuery = (
  { __typename?: 'Query' }
  & { getCompanies: (
    { __typename?: 'GetCompaniesOutput' }
    & Pick<GetCompaniesOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Company' }
      & CompanySearchFragment
    )> }
  ) }
);

export type GetCompaniesThatHasFileCountQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetCompaniesThatHasFileCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompaniesThatHasFileCount'>
);

export type GetCompaniesThatHasBidCountQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetCompaniesThatHasBidCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompaniesThatHasBidCount'>
);

export type GetActivitiesQueryVariables = Exact<{
  input?: Maybe<GetActivitiesInput>;
}>;


export type GetActivitiesQuery = (
  { __typename?: 'Query' }
  & { getActivities: (
    { __typename?: 'GetActivitiesOutput' }
    & Pick<GetActivitiesOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Activity' }
      & ActivityFragment
    )> }
  ) }
);

export type GetActivityQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetActivityQuery = (
  { __typename?: 'Query' }
  & { getActivity?: Maybe<(
    { __typename?: 'Activity' }
    & ActivityFragment
  )> }
);

export type GetActivityProductsQueryVariables = Exact<{
  getActivityProductsInput: GetActivityProductsInput;
}>;


export type GetActivityProductsQuery = (
  { __typename?: 'Query' }
  & { getActivityProducts: (
    { __typename?: 'GetActivityProductsOutput' }
    & Pick<GetActivityProductsOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ActivityProduct' }
      & ActivityProductFragment
    )> }
  ) }
);

export type GetActivitySurveyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetActivitySurveyQuery = (
  { __typename?: 'Query' }
  & { getActivitySurveys: (
    { __typename?: 'GetActivitySurveyOutput' }
    & Pick<GetActivitySurveyOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ActivitySurvey' }
      & ActivitySurveyFragment
    )> }
  ) }
);

export type GetActivitySurveyExistsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetActivitySurveyExistsQuery = (
  { __typename?: 'Query' }
  & { getActivitySurveyExists: (
    { __typename?: 'GetActivitySurveyExistsOutput' }
    & Pick<GetActivitySurveyExistsOutput, 'hasValue'>
  ) }
);

export type GetActivitySurveyCompanyQueryVariables = Exact<{
  activitySurveyId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type GetActivitySurveyCompanyQuery = (
  { __typename?: 'Query' }
  & { getActivitySurveyCompany?: Maybe<(
    { __typename?: 'GetActivitySurveyCompanyOutput' }
    & Pick<GetActivitySurveyCompanyOutput, 'activitySurveyCompanyId'>
    & { activitySurveyCompanyAnswers?: Maybe<Array<(
      { __typename?: 'ActivitySurveyCompanyAnswerOutput' }
      & Pick<ActivitySurveyCompanyAnswerOutput, 'activitySurveyCompanyId' | 'activitySurveyItemId' | 'answer' | 'answerComment'>
    )>> }
  )> }
);

export type GetActivitySurveyAnswersAsCsvQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetActivitySurveyAnswersAsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActivitySurveyAnswersAsCSV'>
);

export type GetActivitySurveyAnswersQueryVariables = Exact<{
  activityId: Scalars['Int'];
}>;


export type GetActivitySurveyAnswersQuery = (
  { __typename?: 'Query' }
  & { getActivitySurveyAnswers?: Maybe<Array<(
    { __typename?: 'GetActivitySurveyCompanyAnswersOutput' }
    & Pick<GetActivitySurveyCompanyAnswersOutput, 'activitySurveyCompanyId' | 'company' | 'questionsAndAnswers'>
  )>> }
);

export type GetBidSheetProductsQueryVariables = Exact<{
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type GetBidSheetProductsQuery = (
  { __typename?: 'Query' }
  & { getBidSheetProducts: Array<(
    { __typename?: 'BidSheetProduct' }
    & BidSheetProductFragment
  )> }
);

export type GetBidSheetQueryVariables = Exact<{
  activityId: Scalars['Int'];
  companyId: Scalars['Int'];
}>;


export type GetBidSheetQuery = (
  { __typename?: 'Query' }
  & { getActivityProducts: (
    { __typename?: 'GetActivityProductsOutput' }
    & { nodes: Array<(
      { __typename?: 'ActivityProduct' }
      & ActivityProductFragment
    )> }
  ), getBidSheetProducts: Array<(
    { __typename?: 'BidSheetProduct' }
    & BidSheetProductFragment
  )> }
);

export type GetBidSheetAsCsvQueryVariables = Exact<{
  input: GetBidSheetAsCsvInput;
}>;


export type GetBidSheetAsCsvQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBidSheetAsCSV'>
);

export type GetActivityCompaniesQueryVariables = Exact<{
  activityId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
}>;


export type GetActivityCompaniesQuery = (
  { __typename?: 'Query' }
  & { getActivityCompanies: (
    { __typename?: 'GetActivityCompanyOutput' }
    & Pick<GetActivityCompanyOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ActivityCompany' }
      & ActivityCompanyFragment
    )> }
  ) }
);

export type GetCompaniesBidsheetProductQueryVariables = Exact<{
  input?: Maybe<GetCompaniesBidsheetProductInput>;
}>;


export type GetCompaniesBidsheetProductQuery = (
  { __typename?: 'Query' }
  & { getCompaniesBidsheetProduct: (
    { __typename?: 'GetCompaniesBidsheetProductOutput' }
    & Pick<GetCompaniesBidsheetProductOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'CompaniesBidsheetProduct' }
      & Pick<CompaniesBidsheetProduct, 'id' | 'manufacturerProductDescription' | 'brand' | 'gtinNumber' | 'packSize' | 'isSepticSafe' | 'numberOfSheetsPerCase' | 'linearFtPerCase' | 'netWeight' | 'willProduceInCompanions' | 'notes' | 'createdAt' | 'updatedAt' | 'activityProductId' | 'companyId' | 'netDeliveredCaseWest' | 'netDeliveredCaseEast' | 'manufName' | 'equivMfrNumber' | 'tier' | 'material' | 'gauge' | 'rollOrFlatPack' | 'other'>
      & { company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'name'>
      )>, activityProduct?: Maybe<(
        { __typename?: 'ActivityProduct' }
        & Pick<ActivityProduct, 'total' | 'east' | 'west' | 'activityId' | 'rfpItemNumber' | 'tier' | 'cat' | 'sub' | 'type' | 'newCatColor' | 'milMicGauge'>
      )> }
    )> }
  ) }
);

export type GetActivityActiveUserQueryVariables = Exact<{
  activityId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
}>;


export type GetActivityActiveUserQuery = (
  { __typename?: 'Query' }
  & { getActivityActiveUsers: (
    { __typename?: 'GetActivityActiveUsersOutput' }
    & Pick<GetActivityActiveUsersOutput, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'ActActiveUsers' }
      & Pick<ActActiveUsers, 'id' | 'activityId' | 'companyId' | 'updatedAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'username'>
        & { userCompanyUser?: Maybe<(
          { __typename?: 'CompanyUser' }
          & Pick<CompanyUser, 'name'>
        )> }
      ) }
    )> }
  ) }
);

export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  createdAt
  updatedAt
  typeCompanyType {
    name
    description
  }
  name
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  createdAt
  updatedAt
  username
  typeUserType {
    name
    description
  }
  userCompanyUser {
    id
    company {
      ...Company
    }
    name
    title
  }
}
    ${CompanyFragmentDoc}`;
export const CompanySearchFragmentDoc = gql`
    fragment CompanySearch on Company {
  id
  name
}
    `;
export const CompanyUserFragmentDoc = gql`
    fragment CompanyUser on CompanyUser {
  id
  user {
    id
    createdAt
    updatedAt
    username
    typeUserType {
      name
      description
    }
  }
  company {
    ...Company
  }
  name
  title
}
    ${CompanyFragmentDoc}`;
export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  id
  createdAt
  updatedAt
  name
  description
  instruction
  expiresAt
  activeAt
  hiddenColumns
}
    `;
export const FileFragmentDoc = gql`
    fragment File on File {
  id
  createdAt
  updatedAt
  company {
    ...Company
  }
  activity {
    ...Activity
  }
  typeFileType {
    name
    description
  }
  name
}
    ${CompanyFragmentDoc}
${ActivityFragmentDoc}`;
export const FileIdNameFragmentDoc = gql`
    fragment FileIdName on FileIdName {
  id
  name
}
    `;
export const BidSheetProductFragmentDoc = gql`
    fragment BidSheetProduct on BidSheetProduct {
  id
  brand
  gtinNumber
  packSize
  isSepticSafe
  numberOfSheetsPerCase
  linearFtPerCase
  netWeight
  willProduceInCompanions
  notes
  createdAt
  updatedAt
  activityProductId
  netDeliveredCaseWest
  netDeliveredCaseEast
  manufName
  equivMfrNumber
  tier
  manufacturerProductDescription
  material
  gauge
  rollOrFlatPack
  other
}
    `;
export const ActivityProductFragmentDoc = gql`
    fragment ActivityProduct on ActivityProduct {
  id
  total
  east
  west
  createdAt
  updatedAt
  activityId
  rfpItemNumber
  tier
  cat
  sub
  newCatColor
  type
  milMicGauge
}
    `;
export const ActivitySurveyFragmentDoc = gql`
    fragment ActivitySurvey on ActivitySurvey {
  id
  activityId
  createdAt
  activitySurveyItems {
    id
    sortOrder
    section
    title
    description
    itemType
    required
    activitySurveyItemOptions {
      id
      optionName
      optionValue
    }
  }
}
    `;
export const ActivityCompanyFragmentDoc = gql`
    fragment ActivityCompany on ActivityCompany {
  id
  createdAt
  createdBy
  updatedAt
  updatedBy
  activityId
  companyId
  submittedAt
  company {
    name
  }
}
    `;
export const ActivitySurveyCompanyFragmentDoc = gql`
    fragment ActivitySurveyCompany on ActivitySurveyCompany {
  activitySurveyId
  activitySurveyCompanyAnswers {
    activitySurveyItemId
    activitySurveyCompanyId
    answer
    answerComment
  }
}
    `;
export const LoginDocument = gql`
    mutation login($loginCredentials: LoginCredentials!) {
  login(loginCredentials: $loginCredentials) {
    token
    userType
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginCredentials: // value for 'loginCredentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $resetPasswordInput)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResetTokenCheckDocument = gql`
    mutation resetTokenCheck($token: String!) {
  resetTokenCheck(token: $token)
}
    `;
export type ResetTokenCheckMutationFn = Apollo.MutationFunction<ResetTokenCheckMutation, ResetTokenCheckMutationVariables>;

/**
 * __useResetTokenCheckMutation__
 *
 * To run a mutation, you first call `useResetTokenCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTokenCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTokenCheckMutation, { data, loading, error }] = useResetTokenCheckMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetTokenCheckMutation(baseOptions?: Apollo.MutationHookOptions<ResetTokenCheckMutation, ResetTokenCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTokenCheckMutation, ResetTokenCheckMutationVariables>(ResetTokenCheckDocument, options);
      }
export type ResetTokenCheckMutationHookResult = ReturnType<typeof useResetTokenCheckMutation>;
export type ResetTokenCheckMutationResult = Apollo.MutationResult<ResetTokenCheckMutation>;
export type ResetTokenCheckMutationOptions = Apollo.BaseMutationOptions<ResetTokenCheckMutation, ResetTokenCheckMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: Int!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AddCompanyUserDocument = gql`
    mutation addCompanyUser($companyUserInput: CompanyUserInput!) {
  addCompanyUser(companyUserInput: $companyUserInput)
}
    `;
export type AddCompanyUserMutationFn = Apollo.MutationFunction<AddCompanyUserMutation, AddCompanyUserMutationVariables>;

/**
 * __useAddCompanyUserMutation__
 *
 * To run a mutation, you first call `useAddCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyUserMutation, { data, loading, error }] = useAddCompanyUserMutation({
 *   variables: {
 *      companyUserInput: // value for 'companyUserInput'
 *   },
 * });
 */
export function useAddCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyUserMutation, AddCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyUserMutation, AddCompanyUserMutationVariables>(AddCompanyUserDocument, options);
      }
export type AddCompanyUserMutationHookResult = ReturnType<typeof useAddCompanyUserMutation>;
export type AddCompanyUserMutationResult = Apollo.MutationResult<AddCompanyUserMutation>;
export type AddCompanyUserMutationOptions = Apollo.BaseMutationOptions<AddCompanyUserMutation, AddCompanyUserMutationVariables>;
export const UpdateCompanyUserDocument = gql`
    mutation updateCompanyUser($companyUserId: Int!, $updateCompanyUserInput: UpdateCompanyUserInput!) {
  updateCompanyUser(
    companyUserId: $companyUserId
    updateCompanyUserInput: $updateCompanyUserInput
  )
}
    `;
export type UpdateCompanyUserMutationFn = Apollo.MutationFunction<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;

/**
 * __useUpdateCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyUserMutation, { data, loading, error }] = useUpdateCompanyUserMutation({
 *   variables: {
 *      companyUserId: // value for 'companyUserId'
 *      updateCompanyUserInput: // value for 'updateCompanyUserInput'
 *   },
 * });
 */
export function useUpdateCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>(UpdateCompanyUserDocument, options);
      }
export type UpdateCompanyUserMutationHookResult = ReturnType<typeof useUpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationResult = Apollo.MutationResult<UpdateCompanyUserMutation>;
export type UpdateCompanyUserMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyUserMutation, UpdateCompanyUserMutationVariables>;
export const AddCompanyDocument = gql`
    mutation addCompany($companyInput: CompanyInput!) {
  addCompany(companyInput: $companyInput)
}
    `;
export type AddCompanyMutationFn = Apollo.MutationFunction<AddCompanyMutation, AddCompanyMutationVariables>;

/**
 * __useAddCompanyMutation__
 *
 * To run a mutation, you first call `useAddCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMutation, { data, loading, error }] = useAddCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useAddCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddCompanyMutation, AddCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompanyMutation, AddCompanyMutationVariables>(AddCompanyDocument, options);
      }
export type AddCompanyMutationHookResult = ReturnType<typeof useAddCompanyMutation>;
export type AddCompanyMutationResult = Apollo.MutationResult<AddCompanyMutation>;
export type AddCompanyMutationOptions = Apollo.BaseMutationOptions<AddCompanyMutation, AddCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($companyId: Int!, $companyInput: CompanyInput!) {
  updateCompany(companyId: $companyId, companyInput: $companyInput)
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const AddActivityDocument = gql`
    mutation addActivity($activityInput: ActivityInput!) {
  addActivity(activityInput: $activityInput)
}
    `;
export type AddActivityMutationFn = Apollo.MutationFunction<AddActivityMutation, AddActivityMutationVariables>;

/**
 * __useAddActivityMutation__
 *
 * To run a mutation, you first call `useAddActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityMutation, { data, loading, error }] = useAddActivityMutation({
 *   variables: {
 *      activityInput: // value for 'activityInput'
 *   },
 * });
 */
export function useAddActivityMutation(baseOptions?: Apollo.MutationHookOptions<AddActivityMutation, AddActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivityMutation, AddActivityMutationVariables>(AddActivityDocument, options);
      }
export type AddActivityMutationHookResult = ReturnType<typeof useAddActivityMutation>;
export type AddActivityMutationResult = Apollo.MutationResult<AddActivityMutation>;
export type AddActivityMutationOptions = Apollo.BaseMutationOptions<AddActivityMutation, AddActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($activityId: Int!, $activityInput: ActivityInput!) {
  updateActivity(activityId: $activityId, activityInput: $activityInput)
}
    `;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityInput: // value for 'activityInput'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const UpdateActivityOnFinalizeDocument = gql`
    mutation updateActivityOnFinalize($activityId: Int!, $activityInput: ActivityInput!) {
  updateActivityOnFinalize(activityId: $activityId, activityInput: $activityInput)
}
    `;
export type UpdateActivityOnFinalizeMutationFn = Apollo.MutationFunction<UpdateActivityOnFinalizeMutation, UpdateActivityOnFinalizeMutationVariables>;

/**
 * __useUpdateActivityOnFinalizeMutation__
 *
 * To run a mutation, you first call `useUpdateActivityOnFinalizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityOnFinalizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityOnFinalizeMutation, { data, loading, error }] = useUpdateActivityOnFinalizeMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityInput: // value for 'activityInput'
 *   },
 * });
 */
export function useUpdateActivityOnFinalizeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityOnFinalizeMutation, UpdateActivityOnFinalizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityOnFinalizeMutation, UpdateActivityOnFinalizeMutationVariables>(UpdateActivityOnFinalizeDocument, options);
      }
export type UpdateActivityOnFinalizeMutationHookResult = ReturnType<typeof useUpdateActivityOnFinalizeMutation>;
export type UpdateActivityOnFinalizeMutationResult = Apollo.MutationResult<UpdateActivityOnFinalizeMutation>;
export type UpdateActivityOnFinalizeMutationOptions = Apollo.BaseMutationOptions<UpdateActivityOnFinalizeMutation, UpdateActivityOnFinalizeMutationVariables>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($activityId: Int!) {
  deleteActivity(activityId: $activityId)
}
    `;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, options);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<DeleteActivityMutation, DeleteActivityMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($companyId: Int!) {
  deleteCompany(companyId: $companyId)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: Int!) {
  deleteFile(id: $id)
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<DeleteFileMutation, DeleteFileMutationVariables>;
export const AddActivityProductDocument = gql`
    mutation AddActivityProduct($activityId: Int!, $activityProductInput: ActivityProductInput!) {
  addActivityProduct(
    activityId: $activityId
    activityProductInput: $activityProductInput
  )
}
    `;
export type AddActivityProductMutationFn = Apollo.MutationFunction<AddActivityProductMutation, AddActivityProductMutationVariables>;

/**
 * __useAddActivityProductMutation__
 *
 * To run a mutation, you first call `useAddActivityProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityProductMutation, { data, loading, error }] = useAddActivityProductMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      activityProductInput: // value for 'activityProductInput'
 *   },
 * });
 */
export function useAddActivityProductMutation(baseOptions?: Apollo.MutationHookOptions<AddActivityProductMutation, AddActivityProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivityProductMutation, AddActivityProductMutationVariables>(AddActivityProductDocument, options);
      }
export type AddActivityProductMutationHookResult = ReturnType<typeof useAddActivityProductMutation>;
export type AddActivityProductMutationResult = Apollo.MutationResult<AddActivityProductMutation>;
export type AddActivityProductMutationOptions = Apollo.BaseMutationOptions<AddActivityProductMutation, AddActivityProductMutationVariables>;
export const UpdateActivityProductDocument = gql`
    mutation UpdateActivityProduct($activityProductId: Int!, $activityProductInput: ActivityProductInput!) {
  updateActivityProduct(
    activityProductId: $activityProductId
    activityProductInput: $activityProductInput
  )
}
    `;
export type UpdateActivityProductMutationFn = Apollo.MutationFunction<UpdateActivityProductMutation, UpdateActivityProductMutationVariables>;

/**
 * __useUpdateActivityProductMutation__
 *
 * To run a mutation, you first call `useUpdateActivityProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityProductMutation, { data, loading, error }] = useUpdateActivityProductMutation({
 *   variables: {
 *      activityProductId: // value for 'activityProductId'
 *      activityProductInput: // value for 'activityProductInput'
 *   },
 * });
 */
export function useUpdateActivityProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityProductMutation, UpdateActivityProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityProductMutation, UpdateActivityProductMutationVariables>(UpdateActivityProductDocument, options);
      }
export type UpdateActivityProductMutationHookResult = ReturnType<typeof useUpdateActivityProductMutation>;
export type UpdateActivityProductMutationResult = Apollo.MutationResult<UpdateActivityProductMutation>;
export type UpdateActivityProductMutationOptions = Apollo.BaseMutationOptions<UpdateActivityProductMutation, UpdateActivityProductMutationVariables>;
export const DeleteActivityProductDocument = gql`
    mutation DeleteActivityProduct($activityProductId: Int!) {
  deleteActivityProduct(activityProductId: $activityProductId)
}
    `;
export type DeleteActivityProductMutationFn = Apollo.MutationFunction<DeleteActivityProductMutation, DeleteActivityProductMutationVariables>;

/**
 * __useDeleteActivityProductMutation__
 *
 * To run a mutation, you first call `useDeleteActivityProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityProductMutation, { data, loading, error }] = useDeleteActivityProductMutation({
 *   variables: {
 *      activityProductId: // value for 'activityProductId'
 *   },
 * });
 */
export function useDeleteActivityProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityProductMutation, DeleteActivityProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityProductMutation, DeleteActivityProductMutationVariables>(DeleteActivityProductDocument, options);
      }
export type DeleteActivityProductMutationHookResult = ReturnType<typeof useDeleteActivityProductMutation>;
export type DeleteActivityProductMutationResult = Apollo.MutationResult<DeleteActivityProductMutation>;
export type DeleteActivityProductMutationOptions = Apollo.BaseMutationOptions<DeleteActivityProductMutation, DeleteActivityProductMutationVariables>;
export const AddActivitySurveyDocument = gql`
    mutation AddActivitySurvey($activityId: Int!) {
  addActivitySurvey(activityId: $activityId)
}
    `;
export type AddActivitySurveyMutationFn = Apollo.MutationFunction<AddActivitySurveyMutation, AddActivitySurveyMutationVariables>;

/**
 * __useAddActivitySurveyMutation__
 *
 * To run a mutation, you first call `useAddActivitySurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivitySurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivitySurveyMutation, { data, loading, error }] = useAddActivitySurveyMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useAddActivitySurveyMutation(baseOptions?: Apollo.MutationHookOptions<AddActivitySurveyMutation, AddActivitySurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivitySurveyMutation, AddActivitySurveyMutationVariables>(AddActivitySurveyDocument, options);
      }
export type AddActivitySurveyMutationHookResult = ReturnType<typeof useAddActivitySurveyMutation>;
export type AddActivitySurveyMutationResult = Apollo.MutationResult<AddActivitySurveyMutation>;
export type AddActivitySurveyMutationOptions = Apollo.BaseMutationOptions<AddActivitySurveyMutation, AddActivitySurveyMutationVariables>;
export const DeleteActivitySurveyDocument = gql`
    mutation deleteActivitySurvey($id: Int!) {
  deleteActivitySurvey(id: $id)
}
    `;
export type DeleteActivitySurveyMutationFn = Apollo.MutationFunction<DeleteActivitySurveyMutation, DeleteActivitySurveyMutationVariables>;

/**
 * __useDeleteActivitySurveyMutation__
 *
 * To run a mutation, you first call `useDeleteActivitySurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivitySurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivitySurveyMutation, { data, loading, error }] = useDeleteActivitySurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivitySurveyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivitySurveyMutation, DeleteActivitySurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivitySurveyMutation, DeleteActivitySurveyMutationVariables>(DeleteActivitySurveyDocument, options);
      }
export type DeleteActivitySurveyMutationHookResult = ReturnType<typeof useDeleteActivitySurveyMutation>;
export type DeleteActivitySurveyMutationResult = Apollo.MutationResult<DeleteActivitySurveyMutation>;
export type DeleteActivitySurveyMutationOptions = Apollo.BaseMutationOptions<DeleteActivitySurveyMutation, DeleteActivitySurveyMutationVariables>;
export const AddActivitySurveyItemDocument = gql`
    mutation addActivitySurveyItem($activitySurveyItemInput: ActivitySurveyItemInput!) {
  addActivitySurveyItem(activitySurveyItemInput: $activitySurveyItemInput)
}
    `;
export type AddActivitySurveyItemMutationFn = Apollo.MutationFunction<AddActivitySurveyItemMutation, AddActivitySurveyItemMutationVariables>;

/**
 * __useAddActivitySurveyItemMutation__
 *
 * To run a mutation, you first call `useAddActivitySurveyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivitySurveyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivitySurveyItemMutation, { data, loading, error }] = useAddActivitySurveyItemMutation({
 *   variables: {
 *      activitySurveyItemInput: // value for 'activitySurveyItemInput'
 *   },
 * });
 */
export function useAddActivitySurveyItemMutation(baseOptions?: Apollo.MutationHookOptions<AddActivitySurveyItemMutation, AddActivitySurveyItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivitySurveyItemMutation, AddActivitySurveyItemMutationVariables>(AddActivitySurveyItemDocument, options);
      }
export type AddActivitySurveyItemMutationHookResult = ReturnType<typeof useAddActivitySurveyItemMutation>;
export type AddActivitySurveyItemMutationResult = Apollo.MutationResult<AddActivitySurveyItemMutation>;
export type AddActivitySurveyItemMutationOptions = Apollo.BaseMutationOptions<AddActivitySurveyItemMutation, AddActivitySurveyItemMutationVariables>;
export const UpdateActivitySurveyItemDocument = gql`
    mutation updateActivitySurveyItem($id: Int!, $activitySurveyItemInput: ActivitySurveyItemInput!) {
  updateActivitySurveyItem(
    id: $id
    activitySurveyItemInput: $activitySurveyItemInput
  )
}
    `;
export type UpdateActivitySurveyItemMutationFn = Apollo.MutationFunction<UpdateActivitySurveyItemMutation, UpdateActivitySurveyItemMutationVariables>;

/**
 * __useUpdateActivitySurveyItemMutation__
 *
 * To run a mutation, you first call `useUpdateActivitySurveyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivitySurveyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivitySurveyItemMutation, { data, loading, error }] = useUpdateActivitySurveyItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      activitySurveyItemInput: // value for 'activitySurveyItemInput'
 *   },
 * });
 */
export function useUpdateActivitySurveyItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivitySurveyItemMutation, UpdateActivitySurveyItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivitySurveyItemMutation, UpdateActivitySurveyItemMutationVariables>(UpdateActivitySurveyItemDocument, options);
      }
export type UpdateActivitySurveyItemMutationHookResult = ReturnType<typeof useUpdateActivitySurveyItemMutation>;
export type UpdateActivitySurveyItemMutationResult = Apollo.MutationResult<UpdateActivitySurveyItemMutation>;
export type UpdateActivitySurveyItemMutationOptions = Apollo.BaseMutationOptions<UpdateActivitySurveyItemMutation, UpdateActivitySurveyItemMutationVariables>;
export const UpdateActivitySurveyItemsDocument = gql`
    mutation updateActivitySurveyItems($activitySurveyItemInputs: [ActivitySurveyItemInputs!]!) {
  updateActivitySurveyItems(activitySurveyItemInputs: $activitySurveyItemInputs)
}
    `;
export type UpdateActivitySurveyItemsMutationFn = Apollo.MutationFunction<UpdateActivitySurveyItemsMutation, UpdateActivitySurveyItemsMutationVariables>;

/**
 * __useUpdateActivitySurveyItemsMutation__
 *
 * To run a mutation, you first call `useUpdateActivitySurveyItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivitySurveyItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivitySurveyItemsMutation, { data, loading, error }] = useUpdateActivitySurveyItemsMutation({
 *   variables: {
 *      activitySurveyItemInputs: // value for 'activitySurveyItemInputs'
 *   },
 * });
 */
export function useUpdateActivitySurveyItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivitySurveyItemsMutation, UpdateActivitySurveyItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivitySurveyItemsMutation, UpdateActivitySurveyItemsMutationVariables>(UpdateActivitySurveyItemsDocument, options);
      }
export type UpdateActivitySurveyItemsMutationHookResult = ReturnType<typeof useUpdateActivitySurveyItemsMutation>;
export type UpdateActivitySurveyItemsMutationResult = Apollo.MutationResult<UpdateActivitySurveyItemsMutation>;
export type UpdateActivitySurveyItemsMutationOptions = Apollo.BaseMutationOptions<UpdateActivitySurveyItemsMutation, UpdateActivitySurveyItemsMutationVariables>;
export const DeleteActivitySurveyItemDocument = gql`
    mutation deleteActivitySurveyItem($id: Int!) {
  deleteActivitySurveyItem(id: $id)
}
    `;
export type DeleteActivitySurveyItemMutationFn = Apollo.MutationFunction<DeleteActivitySurveyItemMutation, DeleteActivitySurveyItemMutationVariables>;

/**
 * __useDeleteActivitySurveyItemMutation__
 *
 * To run a mutation, you first call `useDeleteActivitySurveyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivitySurveyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivitySurveyItemMutation, { data, loading, error }] = useDeleteActivitySurveyItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivitySurveyItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivitySurveyItemMutation, DeleteActivitySurveyItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivitySurveyItemMutation, DeleteActivitySurveyItemMutationVariables>(DeleteActivitySurveyItemDocument, options);
      }
export type DeleteActivitySurveyItemMutationHookResult = ReturnType<typeof useDeleteActivitySurveyItemMutation>;
export type DeleteActivitySurveyItemMutationResult = Apollo.MutationResult<DeleteActivitySurveyItemMutation>;
export type DeleteActivitySurveyItemMutationOptions = Apollo.BaseMutationOptions<DeleteActivitySurveyItemMutation, DeleteActivitySurveyItemMutationVariables>;
export const AddActivitySurveyItemOptionDocument = gql`
    mutation AddActivitySurveyItemOption($activitySurveyItemOptionInput: ActivitySurveyItemOptionInput!) {
  addActivitySurveyItemOption(
    activitySurveyItemOptionInput: $activitySurveyItemOptionInput
  )
}
    `;
export type AddActivitySurveyItemOptionMutationFn = Apollo.MutationFunction<AddActivitySurveyItemOptionMutation, AddActivitySurveyItemOptionMutationVariables>;

/**
 * __useAddActivitySurveyItemOptionMutation__
 *
 * To run a mutation, you first call `useAddActivitySurveyItemOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivitySurveyItemOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivitySurveyItemOptionMutation, { data, loading, error }] = useAddActivitySurveyItemOptionMutation({
 *   variables: {
 *      activitySurveyItemOptionInput: // value for 'activitySurveyItemOptionInput'
 *   },
 * });
 */
export function useAddActivitySurveyItemOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddActivitySurveyItemOptionMutation, AddActivitySurveyItemOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivitySurveyItemOptionMutation, AddActivitySurveyItemOptionMutationVariables>(AddActivitySurveyItemOptionDocument, options);
      }
export type AddActivitySurveyItemOptionMutationHookResult = ReturnType<typeof useAddActivitySurveyItemOptionMutation>;
export type AddActivitySurveyItemOptionMutationResult = Apollo.MutationResult<AddActivitySurveyItemOptionMutation>;
export type AddActivitySurveyItemOptionMutationOptions = Apollo.BaseMutationOptions<AddActivitySurveyItemOptionMutation, AddActivitySurveyItemOptionMutationVariables>;
export const UpdateActivitySurveyItemOptionDocument = gql`
    mutation updateActivitySurveyItemOption($id: Int!, $activitySurveyItemOptionInput: ActivitySurveyItemOptionInput!) {
  updateActivitySurveyItemOption(
    id: $id
    activitySurveyItemOptionInput: $activitySurveyItemOptionInput
  )
}
    `;
export type UpdateActivitySurveyItemOptionMutationFn = Apollo.MutationFunction<UpdateActivitySurveyItemOptionMutation, UpdateActivitySurveyItemOptionMutationVariables>;

/**
 * __useUpdateActivitySurveyItemOptionMutation__
 *
 * To run a mutation, you first call `useUpdateActivitySurveyItemOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivitySurveyItemOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivitySurveyItemOptionMutation, { data, loading, error }] = useUpdateActivitySurveyItemOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      activitySurveyItemOptionInput: // value for 'activitySurveyItemOptionInput'
 *   },
 * });
 */
export function useUpdateActivitySurveyItemOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivitySurveyItemOptionMutation, UpdateActivitySurveyItemOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivitySurveyItemOptionMutation, UpdateActivitySurveyItemOptionMutationVariables>(UpdateActivitySurveyItemOptionDocument, options);
      }
export type UpdateActivitySurveyItemOptionMutationHookResult = ReturnType<typeof useUpdateActivitySurveyItemOptionMutation>;
export type UpdateActivitySurveyItemOptionMutationResult = Apollo.MutationResult<UpdateActivitySurveyItemOptionMutation>;
export type UpdateActivitySurveyItemOptionMutationOptions = Apollo.BaseMutationOptions<UpdateActivitySurveyItemOptionMutation, UpdateActivitySurveyItemOptionMutationVariables>;
export const DeleteActivitySurveyItemOptionDocument = gql`
    mutation deleteActivitySurveyItemOption($id: Int!) {
  deleteActivitySurveyItemOption(id: $id)
}
    `;
export type DeleteActivitySurveyItemOptionMutationFn = Apollo.MutationFunction<DeleteActivitySurveyItemOptionMutation, DeleteActivitySurveyItemOptionMutationVariables>;

/**
 * __useDeleteActivitySurveyItemOptionMutation__
 *
 * To run a mutation, you first call `useDeleteActivitySurveyItemOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivitySurveyItemOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivitySurveyItemOptionMutation, { data, loading, error }] = useDeleteActivitySurveyItemOptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivitySurveyItemOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivitySurveyItemOptionMutation, DeleteActivitySurveyItemOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivitySurveyItemOptionMutation, DeleteActivitySurveyItemOptionMutationVariables>(DeleteActivitySurveyItemOptionDocument, options);
      }
export type DeleteActivitySurveyItemOptionMutationHookResult = ReturnType<typeof useDeleteActivitySurveyItemOptionMutation>;
export type DeleteActivitySurveyItemOptionMutationResult = Apollo.MutationResult<DeleteActivitySurveyItemOptionMutation>;
export type DeleteActivitySurveyItemOptionMutationOptions = Apollo.BaseMutationOptions<DeleteActivitySurveyItemOptionMutation, DeleteActivitySurveyItemOptionMutationVariables>;
export const AddOrUpdateBidSheetProductDocument = gql`
    mutation addOrUpdateBidSheetProduct($activityProductId: Int!, $bidSheetProductInput: BidSheetProductInput!) {
  addOrUpdateBidSheetProduct(
    activityProductId: $activityProductId
    bidSheetProductInput: $bidSheetProductInput
  )
}
    `;
export type AddOrUpdateBidSheetProductMutationFn = Apollo.MutationFunction<AddOrUpdateBidSheetProductMutation, AddOrUpdateBidSheetProductMutationVariables>;

/**
 * __useAddOrUpdateBidSheetProductMutation__
 *
 * To run a mutation, you first call `useAddOrUpdateBidSheetProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrUpdateBidSheetProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrUpdateBidSheetProductMutation, { data, loading, error }] = useAddOrUpdateBidSheetProductMutation({
 *   variables: {
 *      activityProductId: // value for 'activityProductId'
 *      bidSheetProductInput: // value for 'bidSheetProductInput'
 *   },
 * });
 */
export function useAddOrUpdateBidSheetProductMutation(baseOptions?: Apollo.MutationHookOptions<AddOrUpdateBidSheetProductMutation, AddOrUpdateBidSheetProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrUpdateBidSheetProductMutation, AddOrUpdateBidSheetProductMutationVariables>(AddOrUpdateBidSheetProductDocument, options);
      }
export type AddOrUpdateBidSheetProductMutationHookResult = ReturnType<typeof useAddOrUpdateBidSheetProductMutation>;
export type AddOrUpdateBidSheetProductMutationResult = Apollo.MutationResult<AddOrUpdateBidSheetProductMutation>;
export type AddOrUpdateBidSheetProductMutationOptions = Apollo.BaseMutationOptions<AddOrUpdateBidSheetProductMutation, AddOrUpdateBidSheetProductMutationVariables>;
export const AddActivityCompanyDocument = gql`
    mutation addActivityCompany($activityId: Int!, $companyId: Int!) {
  addActivityCompany(activityId: $activityId, companyId: $companyId)
}
    `;
export type AddActivityCompanyMutationFn = Apollo.MutationFunction<AddActivityCompanyMutation, AddActivityCompanyMutationVariables>;

/**
 * __useAddActivityCompanyMutation__
 *
 * To run a mutation, you first call `useAddActivityCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityCompanyMutation, { data, loading, error }] = useAddActivityCompanyMutation({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddActivityCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddActivityCompanyMutation, AddActivityCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivityCompanyMutation, AddActivityCompanyMutationVariables>(AddActivityCompanyDocument, options);
      }
export type AddActivityCompanyMutationHookResult = ReturnType<typeof useAddActivityCompanyMutation>;
export type AddActivityCompanyMutationResult = Apollo.MutationResult<AddActivityCompanyMutation>;
export type AddActivityCompanyMutationOptions = Apollo.BaseMutationOptions<AddActivityCompanyMutation, AddActivityCompanyMutationVariables>;
export const UpdateActivityCompanyDocument = gql`
    mutation updateActivityCompany($id: Int!, $activityCompaniesInput: ActivityCompanyInput!) {
  updateActivityCompany(id: $id, activityCompaniesInput: $activityCompaniesInput)
}
    `;
export type UpdateActivityCompanyMutationFn = Apollo.MutationFunction<UpdateActivityCompanyMutation, UpdateActivityCompanyMutationVariables>;

/**
 * __useUpdateActivityCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateActivityCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityCompanyMutation, { data, loading, error }] = useUpdateActivityCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      activityCompaniesInput: // value for 'activityCompaniesInput'
 *   },
 * });
 */
export function useUpdateActivityCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityCompanyMutation, UpdateActivityCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityCompanyMutation, UpdateActivityCompanyMutationVariables>(UpdateActivityCompanyDocument, options);
      }
export type UpdateActivityCompanyMutationHookResult = ReturnType<typeof useUpdateActivityCompanyMutation>;
export type UpdateActivityCompanyMutationResult = Apollo.MutationResult<UpdateActivityCompanyMutation>;
export type UpdateActivityCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateActivityCompanyMutation, UpdateActivityCompanyMutationVariables>;
export const DeleteActivityCompanyDocument = gql`
    mutation deleteActivityCompany($id: Int!) {
  deleteActivityCompany(id: $id)
}
    `;
export type DeleteActivityCompanyMutationFn = Apollo.MutationFunction<DeleteActivityCompanyMutation, DeleteActivityCompanyMutationVariables>;

/**
 * __useDeleteActivityCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteActivityCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityCompanyMutation, { data, loading, error }] = useDeleteActivityCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActivityCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityCompanyMutation, DeleteActivityCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityCompanyMutation, DeleteActivityCompanyMutationVariables>(DeleteActivityCompanyDocument, options);
      }
export type DeleteActivityCompanyMutationHookResult = ReturnType<typeof useDeleteActivityCompanyMutation>;
export type DeleteActivityCompanyMutationResult = Apollo.MutationResult<DeleteActivityCompanyMutation>;
export type DeleteActivityCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteActivityCompanyMutation, DeleteActivityCompanyMutationVariables>;
export const AddActivitySurveyCompanyDocument = gql`
    mutation addActivitySurveyCompany($activitySurveyId: Int!, $companyId: Int!) {
  addActivitySurveyCompany(
    activitySurveyCompanyInput: {activitySurveyId: $activitySurveyId, companyId: $companyId}
  )
}
    `;
export type AddActivitySurveyCompanyMutationFn = Apollo.MutationFunction<AddActivitySurveyCompanyMutation, AddActivitySurveyCompanyMutationVariables>;

/**
 * __useAddActivitySurveyCompanyMutation__
 *
 * To run a mutation, you first call `useAddActivitySurveyCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivitySurveyCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivitySurveyCompanyMutation, { data, loading, error }] = useAddActivitySurveyCompanyMutation({
 *   variables: {
 *      activitySurveyId: // value for 'activitySurveyId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddActivitySurveyCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddActivitySurveyCompanyMutation, AddActivitySurveyCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddActivitySurveyCompanyMutation, AddActivitySurveyCompanyMutationVariables>(AddActivitySurveyCompanyDocument, options);
      }
export type AddActivitySurveyCompanyMutationHookResult = ReturnType<typeof useAddActivitySurveyCompanyMutation>;
export type AddActivitySurveyCompanyMutationResult = Apollo.MutationResult<AddActivitySurveyCompanyMutation>;
export type AddActivitySurveyCompanyMutationOptions = Apollo.BaseMutationOptions<AddActivitySurveyCompanyMutation, AddActivitySurveyCompanyMutationVariables>;
export const UpdateActivitySurveyCompanyAnswerDocument = gql`
    mutation updateActivitySurveyCompanyAnswer($activitySurveyCompanyAnswerInput: ActivitySurveyCompanyAnswerInput!) {
  updateActivitySurveyCompanyAnswer(
    activitySurveyCompanyAnswerInput: $activitySurveyCompanyAnswerInput
  )
}
    `;
export type UpdateActivitySurveyCompanyAnswerMutationFn = Apollo.MutationFunction<UpdateActivitySurveyCompanyAnswerMutation, UpdateActivitySurveyCompanyAnswerMutationVariables>;

/**
 * __useUpdateActivitySurveyCompanyAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateActivitySurveyCompanyAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivitySurveyCompanyAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivitySurveyCompanyAnswerMutation, { data, loading, error }] = useUpdateActivitySurveyCompanyAnswerMutation({
 *   variables: {
 *      activitySurveyCompanyAnswerInput: // value for 'activitySurveyCompanyAnswerInput'
 *   },
 * });
 */
export function useUpdateActivitySurveyCompanyAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivitySurveyCompanyAnswerMutation, UpdateActivitySurveyCompanyAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivitySurveyCompanyAnswerMutation, UpdateActivitySurveyCompanyAnswerMutationVariables>(UpdateActivitySurveyCompanyAnswerDocument, options);
      }
export type UpdateActivitySurveyCompanyAnswerMutationHookResult = ReturnType<typeof useUpdateActivitySurveyCompanyAnswerMutation>;
export type UpdateActivitySurveyCompanyAnswerMutationResult = Apollo.MutationResult<UpdateActivitySurveyCompanyAnswerMutation>;
export type UpdateActivitySurveyCompanyAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateActivitySurveyCompanyAnswerMutation, UpdateActivitySurveyCompanyAnswerMutationVariables>;
export const AddOrEditActivityActiveUserDocument = gql`
    mutation addOrEditActivityActiveUser($addOrEditActivityActiveUserInput: ActivityActiveUserInput!) {
  addOrEditActivityActiveUser(
    addOrEditActivityActiveUserInput: $addOrEditActivityActiveUserInput
  ) {
    id
    activityId
    companyId
    updatedAt
    user {
      id
      username
      userCompanyUser {
        name
      }
    }
  }
}
    `;
export type AddOrEditActivityActiveUserMutationFn = Apollo.MutationFunction<AddOrEditActivityActiveUserMutation, AddOrEditActivityActiveUserMutationVariables>;

/**
 * __useAddOrEditActivityActiveUserMutation__
 *
 * To run a mutation, you first call `useAddOrEditActivityActiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditActivityActiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditActivityActiveUserMutation, { data, loading, error }] = useAddOrEditActivityActiveUserMutation({
 *   variables: {
 *      addOrEditActivityActiveUserInput: // value for 'addOrEditActivityActiveUserInput'
 *   },
 * });
 */
export function useAddOrEditActivityActiveUserMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditActivityActiveUserMutation, AddOrEditActivityActiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditActivityActiveUserMutation, AddOrEditActivityActiveUserMutationVariables>(AddOrEditActivityActiveUserDocument, options);
      }
export type AddOrEditActivityActiveUserMutationHookResult = ReturnType<typeof useAddOrEditActivityActiveUserMutation>;
export type AddOrEditActivityActiveUserMutationResult = Apollo.MutationResult<AddOrEditActivityActiveUserMutation>;
export type AddOrEditActivityActiveUserMutationOptions = Apollo.BaseMutationOptions<AddOrEditActivityActiveUserMutation, AddOrEditActivityActiveUserMutationVariables>;
export const DeleteActivityActiveUserDocument = gql`
    mutation deleteActivityActiveUser($ActivityActiveUserId: Int!) {
  deleteActivityActiveUser(ActivityActiveUserId: $ActivityActiveUserId)
}
    `;
export type DeleteActivityActiveUserMutationFn = Apollo.MutationFunction<DeleteActivityActiveUserMutation, DeleteActivityActiveUserMutationVariables>;

/**
 * __useDeleteActivityActiveUserMutation__
 *
 * To run a mutation, you first call `useDeleteActivityActiveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityActiveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityActiveUserMutation, { data, loading, error }] = useDeleteActivityActiveUserMutation({
 *   variables: {
 *      ActivityActiveUserId: // value for 'ActivityActiveUserId'
 *   },
 * });
 */
export function useDeleteActivityActiveUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityActiveUserMutation, DeleteActivityActiveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityActiveUserMutation, DeleteActivityActiveUserMutationVariables>(DeleteActivityActiveUserDocument, options);
      }
export type DeleteActivityActiveUserMutationHookResult = ReturnType<typeof useDeleteActivityActiveUserMutation>;
export type DeleteActivityActiveUserMutationResult = Apollo.MutationResult<DeleteActivityActiveUserMutation>;
export type DeleteActivityActiveUserMutationOptions = Apollo.BaseMutationOptions<DeleteActivityActiveUserMutation, DeleteActivityActiveUserMutationVariables>;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetCompanyUsersDocument = gql`
    query getCompanyUsers($getCompanyUsersInput: GetCompanyUsersInput) {
  getCompanyUsers(getCompanyUsersInput: $getCompanyUsersInput) {
    totalCount
    nodes {
      ...CompanyUser
    }
  }
}
    ${CompanyUserFragmentDoc}`;

/**
 * __useGetCompanyUsersQuery__
 *
 * To run a query within a React component, call `useGetCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyUsersQuery({
 *   variables: {
 *      getCompanyUsersInput: // value for 'getCompanyUsersInput'
 *   },
 * });
 */
export function useGetCompanyUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options);
      }
export function useGetCompanyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>(GetCompanyUsersDocument, options);
        }
export type GetCompanyUsersQueryHookResult = ReturnType<typeof useGetCompanyUsersQuery>;
export type GetCompanyUsersLazyQueryHookResult = ReturnType<typeof useGetCompanyUsersLazyQuery>;
export type GetCompanyUsersQueryResult = Apollo.QueryResult<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>;
export const GetDocumentFilesDocument = gql`
    query getDocumentFiles($activityId: Int!) {
  getDocumentFiles(activityId: $activityId) {
    ...File
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetDocumentFilesQuery__
 *
 * To run a query within a React component, call `useGetDocumentFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentFilesQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetDocumentFilesQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentFilesQuery, GetDocumentFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentFilesQuery, GetDocumentFilesQueryVariables>(GetDocumentFilesDocument, options);
      }
export function useGetDocumentFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentFilesQuery, GetDocumentFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentFilesQuery, GetDocumentFilesQueryVariables>(GetDocumentFilesDocument, options);
        }
export type GetDocumentFilesQueryHookResult = ReturnType<typeof useGetDocumentFilesQuery>;
export type GetDocumentFilesLazyQueryHookResult = ReturnType<typeof useGetDocumentFilesLazyQuery>;
export type GetDocumentFilesQueryResult = Apollo.QueryResult<GetDocumentFilesQuery, GetDocumentFilesQueryVariables>;
export const GetTemplateFilesDocument = gql`
    query getTemplateFiles($activityId: Int!) {
  getTemplateFiles(activityId: $activityId) {
    ...File
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetTemplateFilesQuery__
 *
 * To run a query within a React component, call `useGetTemplateFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateFilesQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetTemplateFilesQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateFilesQuery, GetTemplateFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateFilesQuery, GetTemplateFilesQueryVariables>(GetTemplateFilesDocument, options);
      }
export function useGetTemplateFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateFilesQuery, GetTemplateFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateFilesQuery, GetTemplateFilesQueryVariables>(GetTemplateFilesDocument, options);
        }
export type GetTemplateFilesQueryHookResult = ReturnType<typeof useGetTemplateFilesQuery>;
export type GetTemplateFilesLazyQueryHookResult = ReturnType<typeof useGetTemplateFilesLazyQuery>;
export type GetTemplateFilesQueryResult = Apollo.QueryResult<GetTemplateFilesQuery, GetTemplateFilesQueryVariables>;
export const GetSurveyFilesDocument = gql`
    query getSurveyFiles($activityId: Int!) {
  getSurveyFiles(activityId: $activityId) {
    ...File
  }
}
    ${FileFragmentDoc}`;

/**
 * __useGetSurveyFilesQuery__
 *
 * To run a query within a React component, call `useGetSurveyFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyFilesQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetSurveyFilesQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyFilesQuery, GetSurveyFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyFilesQuery, GetSurveyFilesQueryVariables>(GetSurveyFilesDocument, options);
      }
export function useGetSurveyFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyFilesQuery, GetSurveyFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyFilesQuery, GetSurveyFilesQueryVariables>(GetSurveyFilesDocument, options);
        }
export type GetSurveyFilesQueryHookResult = ReturnType<typeof useGetSurveyFilesQuery>;
export type GetSurveyFilesLazyQueryHookResult = ReturnType<typeof useGetSurveyFilesLazyQuery>;
export type GetSurveyFilesQueryResult = Apollo.QueryResult<GetSurveyFilesQuery, GetSurveyFilesQueryVariables>;
export const GetSurveyFileDocument = gql`
    query getSurveyFile($id: Int!) {
  getSurveyFile(id: $id) {
    ...FileIdName
  }
}
    ${FileIdNameFragmentDoc}`;

/**
 * __useGetSurveyFileQuery__
 *
 * To run a query within a React component, call `useGetSurveyFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSurveyFileQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyFileQuery, GetSurveyFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyFileQuery, GetSurveyFileQueryVariables>(GetSurveyFileDocument, options);
      }
export function useGetSurveyFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyFileQuery, GetSurveyFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyFileQuery, GetSurveyFileQueryVariables>(GetSurveyFileDocument, options);
        }
export type GetSurveyFileQueryHookResult = ReturnType<typeof useGetSurveyFileQuery>;
export type GetSurveyFileLazyQueryHookResult = ReturnType<typeof useGetSurveyFileLazyQuery>;
export type GetSurveyFileQueryResult = Apollo.QueryResult<GetSurveyFileQuery, GetSurveyFileQueryVariables>;
export const GetSurveyFileBySurveyItemDocument = gql`
    query getSurveyFileBySurveyItem($surveyItemId: Int!, $surveyCompanyId: Int!) {
  getSurveyFileBySurveyItem(
    surveyItemId: $surveyItemId
    surveyCompanyId: $surveyCompanyId
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetSurveyFileBySurveyItemQuery__
 *
 * To run a query within a React component, call `useGetSurveyFileBySurveyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyFileBySurveyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyFileBySurveyItemQuery({
 *   variables: {
 *      surveyItemId: // value for 'surveyItemId'
 *      surveyCompanyId: // value for 'surveyCompanyId'
 *   },
 * });
 */
export function useGetSurveyFileBySurveyItemQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyFileBySurveyItemQuery, GetSurveyFileBySurveyItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSurveyFileBySurveyItemQuery, GetSurveyFileBySurveyItemQueryVariables>(GetSurveyFileBySurveyItemDocument, options);
      }
export function useGetSurveyFileBySurveyItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyFileBySurveyItemQuery, GetSurveyFileBySurveyItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSurveyFileBySurveyItemQuery, GetSurveyFileBySurveyItemQueryVariables>(GetSurveyFileBySurveyItemDocument, options);
        }
export type GetSurveyFileBySurveyItemQueryHookResult = ReturnType<typeof useGetSurveyFileBySurveyItemQuery>;
export type GetSurveyFileBySurveyItemLazyQueryHookResult = ReturnType<typeof useGetSurveyFileBySurveyItemLazyQuery>;
export type GetSurveyFileBySurveyItemQueryResult = Apollo.QueryResult<GetSurveyFileBySurveyItemQuery, GetSurveyFileBySurveyItemQueryVariables>;
export const GetCompaniesDocument = gql`
    query getCompanies($getCompaniesInput: GetCompaniesInput) {
  getCompanies(getCompaniesInput: $getCompaniesInput) {
    totalCount
    nodes {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      getCompaniesInput: // value for 'getCompaniesInput'
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
      }
export function useGetCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const SearchCompaniesDocument = gql`
    query searchCompanies($input: GetCompaniesInput) {
  getCompanies(getCompaniesInput: $input) {
    totalCount
    nodes {
      ...CompanySearch
    }
  }
}
    ${CompanySearchFragmentDoc}`;

/**
 * __useSearchCompaniesQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<SearchCompaniesQuery, SearchCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCompaniesQuery, SearchCompaniesQueryVariables>(SearchCompaniesDocument, options);
      }
export function useSearchCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCompaniesQuery, SearchCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCompaniesQuery, SearchCompaniesQueryVariables>(SearchCompaniesDocument, options);
        }
export type SearchCompaniesQueryHookResult = ReturnType<typeof useSearchCompaniesQuery>;
export type SearchCompaniesLazyQueryHookResult = ReturnType<typeof useSearchCompaniesLazyQuery>;
export type SearchCompaniesQueryResult = Apollo.QueryResult<SearchCompaniesQuery, SearchCompaniesQueryVariables>;
export const GetCompaniesThatHasFileCountDocument = gql`
    query getCompaniesThatHasFileCount($activityId: Int!) {
  getCompaniesThatHasFileCount(activityId: $activityId)
}
    `;

/**
 * __useGetCompaniesThatHasFileCountQuery__
 *
 * To run a query within a React component, call `useGetCompaniesThatHasFileCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesThatHasFileCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesThatHasFileCountQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetCompaniesThatHasFileCountQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesThatHasFileCountQuery, GetCompaniesThatHasFileCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesThatHasFileCountQuery, GetCompaniesThatHasFileCountQueryVariables>(GetCompaniesThatHasFileCountDocument, options);
      }
export function useGetCompaniesThatHasFileCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesThatHasFileCountQuery, GetCompaniesThatHasFileCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesThatHasFileCountQuery, GetCompaniesThatHasFileCountQueryVariables>(GetCompaniesThatHasFileCountDocument, options);
        }
export type GetCompaniesThatHasFileCountQueryHookResult = ReturnType<typeof useGetCompaniesThatHasFileCountQuery>;
export type GetCompaniesThatHasFileCountLazyQueryHookResult = ReturnType<typeof useGetCompaniesThatHasFileCountLazyQuery>;
export type GetCompaniesThatHasFileCountQueryResult = Apollo.QueryResult<GetCompaniesThatHasFileCountQuery, GetCompaniesThatHasFileCountQueryVariables>;
export const GetCompaniesThatHasBidCountDocument = gql`
    query getCompaniesThatHasBidCount($activityId: Int!) {
  getCompaniesThatHasBidCount(activityId: $activityId)
}
    `;

/**
 * __useGetCompaniesThatHasBidCountQuery__
 *
 * To run a query within a React component, call `useGetCompaniesThatHasBidCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesThatHasBidCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesThatHasBidCountQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetCompaniesThatHasBidCountQuery(baseOptions: Apollo.QueryHookOptions<GetCompaniesThatHasBidCountQuery, GetCompaniesThatHasBidCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesThatHasBidCountQuery, GetCompaniesThatHasBidCountQueryVariables>(GetCompaniesThatHasBidCountDocument, options);
      }
export function useGetCompaniesThatHasBidCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesThatHasBidCountQuery, GetCompaniesThatHasBidCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesThatHasBidCountQuery, GetCompaniesThatHasBidCountQueryVariables>(GetCompaniesThatHasBidCountDocument, options);
        }
export type GetCompaniesThatHasBidCountQueryHookResult = ReturnType<typeof useGetCompaniesThatHasBidCountQuery>;
export type GetCompaniesThatHasBidCountLazyQueryHookResult = ReturnType<typeof useGetCompaniesThatHasBidCountLazyQuery>;
export type GetCompaniesThatHasBidCountQueryResult = Apollo.QueryResult<GetCompaniesThatHasBidCountQuery, GetCompaniesThatHasBidCountQueryVariables>;
export const GetActivitiesDocument = gql`
    query getActivities($input: GetActivitiesInput) {
  getActivities(input: $input) {
    totalCount
    nodes {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useGetActivitiesQuery__
 *
 * To run a query within a React component, call `useGetActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
      }
export function useGetActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitiesQuery, GetActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitiesQuery, GetActivitiesQueryVariables>(GetActivitiesDocument, options);
        }
export type GetActivitiesQueryHookResult = ReturnType<typeof useGetActivitiesQuery>;
export type GetActivitiesLazyQueryHookResult = ReturnType<typeof useGetActivitiesLazyQuery>;
export type GetActivitiesQueryResult = Apollo.QueryResult<GetActivitiesQuery, GetActivitiesQueryVariables>;
export const GetActivityDocument = gql`
    query getActivity($id: Int!) {
  getActivity(id: $id) {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;

/**
 * __useGetActivityQuery__
 *
 * To run a query within a React component, call `useGetActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityQuery(baseOptions: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export const GetActivityProductsDocument = gql`
    query getActivityProducts($getActivityProductsInput: GetActivityProductsInput!) {
  getActivityProducts(getActivityProductsInput: $getActivityProductsInput) {
    totalCount
    nodes {
      ...ActivityProduct
    }
  }
}
    ${ActivityProductFragmentDoc}`;

/**
 * __useGetActivityProductsQuery__
 *
 * To run a query within a React component, call `useGetActivityProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityProductsQuery({
 *   variables: {
 *      getActivityProductsInput: // value for 'getActivityProductsInput'
 *   },
 * });
 */
export function useGetActivityProductsQuery(baseOptions: Apollo.QueryHookOptions<GetActivityProductsQuery, GetActivityProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityProductsQuery, GetActivityProductsQueryVariables>(GetActivityProductsDocument, options);
      }
export function useGetActivityProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityProductsQuery, GetActivityProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityProductsQuery, GetActivityProductsQueryVariables>(GetActivityProductsDocument, options);
        }
export type GetActivityProductsQueryHookResult = ReturnType<typeof useGetActivityProductsQuery>;
export type GetActivityProductsLazyQueryHookResult = ReturnType<typeof useGetActivityProductsLazyQuery>;
export type GetActivityProductsQueryResult = Apollo.QueryResult<GetActivityProductsQuery, GetActivityProductsQueryVariables>;
export const GetActivitySurveyDocument = gql`
    query getActivitySurvey($id: Int!) {
  getActivitySurveys(activityId: $id) {
    totalCount
    nodes {
      ...ActivitySurvey
    }
  }
}
    ${ActivitySurveyFragmentDoc}`;

/**
 * __useGetActivitySurveyQuery__
 *
 * To run a query within a React component, call `useGetActivitySurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivitySurveyQuery(baseOptions: Apollo.QueryHookOptions<GetActivitySurveyQuery, GetActivitySurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitySurveyQuery, GetActivitySurveyQueryVariables>(GetActivitySurveyDocument, options);
      }
export function useGetActivitySurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySurveyQuery, GetActivitySurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitySurveyQuery, GetActivitySurveyQueryVariables>(GetActivitySurveyDocument, options);
        }
export type GetActivitySurveyQueryHookResult = ReturnType<typeof useGetActivitySurveyQuery>;
export type GetActivitySurveyLazyQueryHookResult = ReturnType<typeof useGetActivitySurveyLazyQuery>;
export type GetActivitySurveyQueryResult = Apollo.QueryResult<GetActivitySurveyQuery, GetActivitySurveyQueryVariables>;
export const GetActivitySurveyExistsDocument = gql`
    query getActivitySurveyExists($id: Int!) {
  getActivitySurveyExists(activityId: $id) {
    hasValue
  }
}
    `;

/**
 * __useGetActivitySurveyExistsQuery__
 *
 * To run a query within a React component, call `useGetActivitySurveyExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySurveyExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySurveyExistsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivitySurveyExistsQuery(baseOptions: Apollo.QueryHookOptions<GetActivitySurveyExistsQuery, GetActivitySurveyExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitySurveyExistsQuery, GetActivitySurveyExistsQueryVariables>(GetActivitySurveyExistsDocument, options);
      }
export function useGetActivitySurveyExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySurveyExistsQuery, GetActivitySurveyExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitySurveyExistsQuery, GetActivitySurveyExistsQueryVariables>(GetActivitySurveyExistsDocument, options);
        }
export type GetActivitySurveyExistsQueryHookResult = ReturnType<typeof useGetActivitySurveyExistsQuery>;
export type GetActivitySurveyExistsLazyQueryHookResult = ReturnType<typeof useGetActivitySurveyExistsLazyQuery>;
export type GetActivitySurveyExistsQueryResult = Apollo.QueryResult<GetActivitySurveyExistsQuery, GetActivitySurveyExistsQueryVariables>;
export const GetActivitySurveyCompanyDocument = gql`
    query getActivitySurveyCompany($activitySurveyId: Int!, $companyId: Int!) {
  getActivitySurveyCompany(
    activitySurveyId: $activitySurveyId
    companyId: $companyId
  ) {
    activitySurveyCompanyId
    activitySurveyCompanyAnswers {
      activitySurveyCompanyId
      activitySurveyItemId
      answer
      answerComment
    }
  }
}
    `;

/**
 * __useGetActivitySurveyCompanyQuery__
 *
 * To run a query within a React component, call `useGetActivitySurveyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySurveyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySurveyCompanyQuery({
 *   variables: {
 *      activitySurveyId: // value for 'activitySurveyId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetActivitySurveyCompanyQuery(baseOptions: Apollo.QueryHookOptions<GetActivitySurveyCompanyQuery, GetActivitySurveyCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitySurveyCompanyQuery, GetActivitySurveyCompanyQueryVariables>(GetActivitySurveyCompanyDocument, options);
      }
export function useGetActivitySurveyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySurveyCompanyQuery, GetActivitySurveyCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitySurveyCompanyQuery, GetActivitySurveyCompanyQueryVariables>(GetActivitySurveyCompanyDocument, options);
        }
export type GetActivitySurveyCompanyQueryHookResult = ReturnType<typeof useGetActivitySurveyCompanyQuery>;
export type GetActivitySurveyCompanyLazyQueryHookResult = ReturnType<typeof useGetActivitySurveyCompanyLazyQuery>;
export type GetActivitySurveyCompanyQueryResult = Apollo.QueryResult<GetActivitySurveyCompanyQuery, GetActivitySurveyCompanyQueryVariables>;
export const GetActivitySurveyAnswersAsCsvDocument = gql`
    query getActivitySurveyAnswersAsCSV($id: Int!) {
  getActivitySurveyAnswersAsCSV(activityId: $id)
}
    `;

/**
 * __useGetActivitySurveyAnswersAsCsvQuery__
 *
 * To run a query within a React component, call `useGetActivitySurveyAnswersAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySurveyAnswersAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySurveyAnswersAsCsvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivitySurveyAnswersAsCsvQuery(baseOptions: Apollo.QueryHookOptions<GetActivitySurveyAnswersAsCsvQuery, GetActivitySurveyAnswersAsCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitySurveyAnswersAsCsvQuery, GetActivitySurveyAnswersAsCsvQueryVariables>(GetActivitySurveyAnswersAsCsvDocument, options);
      }
export function useGetActivitySurveyAnswersAsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySurveyAnswersAsCsvQuery, GetActivitySurveyAnswersAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitySurveyAnswersAsCsvQuery, GetActivitySurveyAnswersAsCsvQueryVariables>(GetActivitySurveyAnswersAsCsvDocument, options);
        }
export type GetActivitySurveyAnswersAsCsvQueryHookResult = ReturnType<typeof useGetActivitySurveyAnswersAsCsvQuery>;
export type GetActivitySurveyAnswersAsCsvLazyQueryHookResult = ReturnType<typeof useGetActivitySurveyAnswersAsCsvLazyQuery>;
export type GetActivitySurveyAnswersAsCsvQueryResult = Apollo.QueryResult<GetActivitySurveyAnswersAsCsvQuery, GetActivitySurveyAnswersAsCsvQueryVariables>;
export const GetActivitySurveyAnswersDocument = gql`
    query getActivitySurveyAnswers($activityId: Int!) {
  getActivitySurveyAnswers(activityId: $activityId) {
    activitySurveyCompanyId
    company
    questionsAndAnswers
  }
}
    `;

/**
 * __useGetActivitySurveyAnswersQuery__
 *
 * To run a query within a React component, call `useGetActivitySurveyAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivitySurveyAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivitySurveyAnswersQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetActivitySurveyAnswersQuery(baseOptions: Apollo.QueryHookOptions<GetActivitySurveyAnswersQuery, GetActivitySurveyAnswersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivitySurveyAnswersQuery, GetActivitySurveyAnswersQueryVariables>(GetActivitySurveyAnswersDocument, options);
      }
export function useGetActivitySurveyAnswersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivitySurveyAnswersQuery, GetActivitySurveyAnswersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivitySurveyAnswersQuery, GetActivitySurveyAnswersQueryVariables>(GetActivitySurveyAnswersDocument, options);
        }
export type GetActivitySurveyAnswersQueryHookResult = ReturnType<typeof useGetActivitySurveyAnswersQuery>;
export type GetActivitySurveyAnswersLazyQueryHookResult = ReturnType<typeof useGetActivitySurveyAnswersLazyQuery>;
export type GetActivitySurveyAnswersQueryResult = Apollo.QueryResult<GetActivitySurveyAnswersQuery, GetActivitySurveyAnswersQueryVariables>;
export const GetBidSheetProductsDocument = gql`
    query getBidSheetProducts($activityId: Int!, $companyId: Int!) {
  getBidSheetProducts(activityId: $activityId, companyId: $companyId) {
    ...BidSheetProduct
  }
}
    ${BidSheetProductFragmentDoc}`;

/**
 * __useGetBidSheetProductsQuery__
 *
 * To run a query within a React component, call `useGetBidSheetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidSheetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidSheetProductsQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBidSheetProductsQuery(baseOptions: Apollo.QueryHookOptions<GetBidSheetProductsQuery, GetBidSheetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidSheetProductsQuery, GetBidSheetProductsQueryVariables>(GetBidSheetProductsDocument, options);
      }
export function useGetBidSheetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidSheetProductsQuery, GetBidSheetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidSheetProductsQuery, GetBidSheetProductsQueryVariables>(GetBidSheetProductsDocument, options);
        }
export type GetBidSheetProductsQueryHookResult = ReturnType<typeof useGetBidSheetProductsQuery>;
export type GetBidSheetProductsLazyQueryHookResult = ReturnType<typeof useGetBidSheetProductsLazyQuery>;
export type GetBidSheetProductsQueryResult = Apollo.QueryResult<GetBidSheetProductsQuery, GetBidSheetProductsQueryVariables>;
export const GetBidSheetDocument = gql`
    query getBidSheet($activityId: Int!, $companyId: Int!) {
  getActivityProducts(getActivityProductsInput: {activityId: $activityId}) {
    nodes {
      ...ActivityProduct
    }
  }
  getBidSheetProducts(activityId: $activityId, companyId: $companyId) {
    ...BidSheetProduct
  }
}
    ${ActivityProductFragmentDoc}
${BidSheetProductFragmentDoc}`;

/**
 * __useGetBidSheetQuery__
 *
 * To run a query within a React component, call `useGetBidSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidSheetQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetBidSheetQuery(baseOptions: Apollo.QueryHookOptions<GetBidSheetQuery, GetBidSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidSheetQuery, GetBidSheetQueryVariables>(GetBidSheetDocument, options);
      }
export function useGetBidSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidSheetQuery, GetBidSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidSheetQuery, GetBidSheetQueryVariables>(GetBidSheetDocument, options);
        }
export type GetBidSheetQueryHookResult = ReturnType<typeof useGetBidSheetQuery>;
export type GetBidSheetLazyQueryHookResult = ReturnType<typeof useGetBidSheetLazyQuery>;
export type GetBidSheetQueryResult = Apollo.QueryResult<GetBidSheetQuery, GetBidSheetQueryVariables>;
export const GetBidSheetAsCsvDocument = gql`
    query getBidSheetAsCSV($input: GetBidSheetAsCSVInput!) {
  getBidSheetAsCSV(input: $input)
}
    `;

/**
 * __useGetBidSheetAsCsvQuery__
 *
 * To run a query within a React component, call `useGetBidSheetAsCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidSheetAsCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidSheetAsCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBidSheetAsCsvQuery(baseOptions: Apollo.QueryHookOptions<GetBidSheetAsCsvQuery, GetBidSheetAsCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidSheetAsCsvQuery, GetBidSheetAsCsvQueryVariables>(GetBidSheetAsCsvDocument, options);
      }
export function useGetBidSheetAsCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidSheetAsCsvQuery, GetBidSheetAsCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidSheetAsCsvQuery, GetBidSheetAsCsvQueryVariables>(GetBidSheetAsCsvDocument, options);
        }
export type GetBidSheetAsCsvQueryHookResult = ReturnType<typeof useGetBidSheetAsCsvQuery>;
export type GetBidSheetAsCsvLazyQueryHookResult = ReturnType<typeof useGetBidSheetAsCsvLazyQuery>;
export type GetBidSheetAsCsvQueryResult = Apollo.QueryResult<GetBidSheetAsCsvQuery, GetBidSheetAsCsvQueryVariables>;
export const GetActivityCompaniesDocument = gql`
    query getActivityCompanies($activityId: Int, $companyId: Int) {
  getActivityCompanies(activityId: $activityId, companyId: $companyId) {
    totalCount
    nodes {
      ...ActivityCompany
    }
  }
}
    ${ActivityCompanyFragmentDoc}`;

/**
 * __useGetActivityCompaniesQuery__
 *
 * To run a query within a React component, call `useGetActivityCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityCompaniesQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetActivityCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityCompaniesQuery, GetActivityCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityCompaniesQuery, GetActivityCompaniesQueryVariables>(GetActivityCompaniesDocument, options);
      }
export function useGetActivityCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityCompaniesQuery, GetActivityCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityCompaniesQuery, GetActivityCompaniesQueryVariables>(GetActivityCompaniesDocument, options);
        }
export type GetActivityCompaniesQueryHookResult = ReturnType<typeof useGetActivityCompaniesQuery>;
export type GetActivityCompaniesLazyQueryHookResult = ReturnType<typeof useGetActivityCompaniesLazyQuery>;
export type GetActivityCompaniesQueryResult = Apollo.QueryResult<GetActivityCompaniesQuery, GetActivityCompaniesQueryVariables>;
export const GetCompaniesBidsheetProductDocument = gql`
    query getCompaniesBidsheetProduct($input: GetCompaniesBidsheetProductInput) {
  getCompaniesBidsheetProduct(getCompaniesBidsheetProductInput: $input) {
    totalCount
    nodes {
      id
      manufacturerProductDescription
      brand
      gtinNumber
      packSize
      isSepticSafe
      numberOfSheetsPerCase
      linearFtPerCase
      netWeight
      willProduceInCompanions
      notes
      createdAt
      updatedAt
      activityProductId
      companyId
      netDeliveredCaseWest
      netDeliveredCaseEast
      manufName
      equivMfrNumber
      tier
      material
      gauge
      rollOrFlatPack
      other
      company {
        name
      }
      activityProduct {
        total
        east
        west
        activityId
        rfpItemNumber
        tier
        cat
        sub
        type
        newCatColor
        milMicGauge
      }
    }
  }
}
    `;

/**
 * __useGetCompaniesBidsheetProductQuery__
 *
 * To run a query within a React component, call `useGetCompaniesBidsheetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesBidsheetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesBidsheetProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompaniesBidsheetProductQuery(baseOptions?: Apollo.QueryHookOptions<GetCompaniesBidsheetProductQuery, GetCompaniesBidsheetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompaniesBidsheetProductQuery, GetCompaniesBidsheetProductQueryVariables>(GetCompaniesBidsheetProductDocument, options);
      }
export function useGetCompaniesBidsheetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesBidsheetProductQuery, GetCompaniesBidsheetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompaniesBidsheetProductQuery, GetCompaniesBidsheetProductQueryVariables>(GetCompaniesBidsheetProductDocument, options);
        }
export type GetCompaniesBidsheetProductQueryHookResult = ReturnType<typeof useGetCompaniesBidsheetProductQuery>;
export type GetCompaniesBidsheetProductLazyQueryHookResult = ReturnType<typeof useGetCompaniesBidsheetProductLazyQuery>;
export type GetCompaniesBidsheetProductQueryResult = Apollo.QueryResult<GetCompaniesBidsheetProductQuery, GetCompaniesBidsheetProductQueryVariables>;
export const GetActivityActiveUserDocument = gql`
    query getActivityActiveUser($activityId: Int, $companyId: Int) {
  getActivityActiveUsers(activityId: $activityId, companyId: $companyId) {
    totalCount
    nodes {
      id
      activityId
      companyId
      updatedAt
      user {
        id
        username
        userCompanyUser {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetActivityActiveUserQuery__
 *
 * To run a query within a React component, call `useGetActivityActiveUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityActiveUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityActiveUserQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetActivityActiveUserQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityActiveUserQuery, GetActivityActiveUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityActiveUserQuery, GetActivityActiveUserQueryVariables>(GetActivityActiveUserDocument, options);
      }
export function useGetActivityActiveUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityActiveUserQuery, GetActivityActiveUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityActiveUserQuery, GetActivityActiveUserQueryVariables>(GetActivityActiveUserDocument, options);
        }
export type GetActivityActiveUserQueryHookResult = ReturnType<typeof useGetActivityActiveUserQuery>;
export type GetActivityActiveUserLazyQueryHookResult = ReturnType<typeof useGetActivityActiveUserLazyQuery>;
export type GetActivityActiveUserQueryResult = Apollo.QueryResult<GetActivityActiveUserQuery, GetActivityActiveUserQueryVariables>;