import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { PathName } from "../constants/PathName";
import useLoginWithLocalToken from "../hooks/useLoginWithLocalToken";
import { isLoggedInSelector } from "../state/user.recoil";

export interface Props {
	exact?: boolean;
	path: string;
	render?: (routeProps: RouteComponentProps) => JSX.Element;
	component?: JSX.Element;
}

const PrivateRoute: React.FC<Props> = ({ exact, path, render, component }) => {
	const isLoggedIn = useRecoilValue(isLoggedInSelector);

	const isLoggingIn = useLoginWithLocalToken(isLoggedIn);

	return (
		<Route
			exact={exact}
			path={path}
			render={(routeProps) => {
				if (isLoggingIn) {
					return <div></div>;
				} else if (isLoggedIn) {
					return render ? render(routeProps) : component;
				} else {
					return <Redirect to={PathName.DEFAULT} />;
				}
			}}
		/>
	);
};

export default PrivateRoute;
