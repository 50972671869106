import { EyeIcon, EyeOffIcon, PlusIcon } from "@heroicons/react/solid";
import { useEffect } from "react";
import { GrEdit } from "react-icons/gr";
import { IoTrashOutline } from "react-icons/io5";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "../../components/Button";
import Table from "../../components/Table";
import {
	useDeleteActivityProductMutation,
	useGetActivityProductsLazyQuery,
} from "../../generated/graphql";
import { useStates } from "../../hooks/useStates";
import { activityAtom } from "../../state/activityRecoil";
import { loadingMessageAtom } from "../../state/loading.recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import { formatLocaleNumber } from "../../utils/utils";
import ActivityHiddenColumnsForm from "./ActivityHiddenColumnsForm";
import ActivityProductForm from "./ActivityProductForm";

const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
};

const ActivityProducts: React.FC = () => {
	const [{ currentPage }, setState] = useStates<State>({
		currentPage: 0,
	});
	const activity = useRecoilValue(activityAtom)!;
	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const [getActivityProducts, getActivityProductsRes] = useGetActivityProductsLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [deleteActivityProduct] = useDeleteActivityProductMutation();

	const getActivityProductsByPage = (page: number) => {
		getActivityProducts({
			variables: {
				getActivityProductsInput: {
					activityId: activity.id,
					pagination: {
						offset: page * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
					orderBys: [
						{
							by: "id",
						},
					],
				},
			},
		});
	};

	const resetActivityProducts = () => {
		if (currentPage === 0) {
			getActivityProductsByPage(0);
		} else {
			setState({ currentPage: 0 });
		}
	};

	const _deleteActivityProduct = (id: number) => {
		setLoadingMessage("Deleting Product");
		deleteActivityProduct({
			variables: {
				activityProductId: id,
			},
		})
			.then(() => {
				resetActivityProducts();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
				setModalRender(undefined);
			});
	};

	const deletePrompt = (id: number) => {
		return (
			<div className="w-85 flex flex-col justify-center items-center self-center mb-7">
				<div className="my-5">Are you sure you want to delete this product?</div>
				<div className="flex gap-4">
					<button
						className="w-max px-4 py-1 rounded border border-gray-300 hover:bg-gray-400"
						onClick={() => setModalRender(undefined)}
					>
						No
					</button>
					<button
						className="w-max px-4 py-1 rounded bg-primary hover:bg-red-900 text-white"
						onClick={() => _deleteActivityProduct(id)}
					>
						Yes
					</button>
				</div>
			</div>
		);
	};

	const isHidden = (field: any) => {
		const hiddenColsArray = activity.hiddenColumns?.split(",");
		return hiddenColsArray?.includes(field) || false;
	};

	useEffect(() => {
		getActivityProductsByPage(currentPage);
	}, [currentPage]);

	useEffect(() => {
		if (getActivityProductsRes.called) {
			if (getActivityProductsRes.loading) {
				setLoadingMessage("Loading Products");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getActivityProductsRes.called, getActivityProductsRes.loading, getActivityProductsRes.data]);

	const activityProducts = getActivityProductsRes.data?.getActivityProducts.nodes || [];
	const pages =
		(getActivityProductsRes.data?.getActivityProducts?.totalCount || 0) / PAGE_ITEM_COUNT;

	return (
		<div>
			<div className="flex justify-between">
				<Button
					onClick={() =>
						setModalRender(<ActivityProductForm onSuccess={() => resetActivityProducts()} />)
					}
				>
					<PlusIcon className="w-5 h-5" />
					Add Product
				</Button>
				<Button onClick={() => setModalRender(<ActivityHiddenColumnsForm />)}>
					<EyeOffIcon className="w-5 h-5" />
					Hidden Columns
				</Button>
			</div>

			<br />
			<Table
				columns={[
					{
						name: "RFP ITEM #",
						field: "rfpItemNumber",
						hidden: isHidden("rfpItemNumber"),
					},
					{
						name: "TIER",
						field: "tier",
						hidden: isHidden("tier"),
					},
					{
						name: "CAT",
						field: "cat",
						hidden: isHidden("cat"),
					},
					{
						name: "SUB",
						field: "sub",
						hidden: isHidden("sub"),
					},
					{
						name: "TYPE",
						field: "type",
						hidden: isHidden("type"),
					},
					{
						name: "MIL/MIC/GAUGE",
						field: "milMicGauge",
						hidden: isHidden("milMicGauge"),
					},
					{
						name: "NEW CAT/COLOR",
						field: "newCatColor",
						hidden: isHidden("newCatColor"),
					},
					{
						name: "TOTAL",
						field: "total",
						hidden: isHidden("total"),
						formattedField: (row) => {
							return <div>{formatLocaleNumber(row.total)}</div>;
						},
					},
					{
						name: "EAST",
						field: "east",
						hidden: isHidden("east"),
						formattedField: (row) => {
							return <div>{formatLocaleNumber(row.east)}</div>;
						},
					},
					{
						name: "WEST",
						field: "west",
						hidden: isHidden("west"),
						formattedField: (row) => {
							return <div>{formatLocaleNumber(row.west)}</div>;
						},
					},
					{
						name: "Update",
						formattedField: (row) => {
							return (
								<button
									className="flex text-primary m-auto"
									onClick={() =>
										setModalRender(
											<ActivityProductForm
												activityProduct={row}
												onSuccess={() => resetActivityProducts()}
											/>,
										)
									}
								>
									<GrEdit className="w-5 h-5 text-primary" />
								</button>
							);
						},
					},
					{
						name: "Delete",
						formattedField: (row) => {
							return (
								<button
									className="flex text-primary m-auto"
									onClick={() => setModalRender(deletePrompt(row.id))}
								>
									<IoTrashOutline className="w-5 h-5 text-primary" />
								</button>
							);
						},
					},
				]}
				rows={activityProducts}
				pagination={{
					pages,
					currentPage,
					onPageClick: (page: number) => {
						setState({
							currentPage: page,
						});
					},
				}}
			/>
		</div>
	);
};

export default ActivityProducts;
