import React, { useState } from "react";

interface Props {
	onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
	className?: String;
}

const FileInput: React.FC<Props> = (props) => {
	const [fileName, setFileName] = useState("");
	const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			setFileName(e.target.files![0].name);
		} else {
			setFileName("");
		}

		if (props.onChange) {
			props.onChange(e);
		}
	};

	return (
		<div className="flex relative w-80 h-16 pointer-events-all">
			<div className="absolute w-full h-full border-2 border-dashed border-primary rounded hover:bg-gray-200 leading-4 text-gray-400 font-normal flex items-center justify-center">
				<div className="text-center">
					{fileName === "" ? (
						<div>
							Drop File Here <br /> or <br />
							Click To Browse
						</div>
					) : (
						fileName
					)}
				</div>
			</div>
			<input
				className=" w-full h-full opacity-0 cursor-pointer"
				type="file"
				onChange={onFileChange}
			/>
		</div>
	);
};

export default FileInput;
