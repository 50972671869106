const Footer: React.FC = () => {
	return (
		<div className="container relative mx-auto flex justify-center border-T border-gray-300 h-full mt-28">
			<div className="absolute shadow-md top-0 w-full h-1"></div>

			<div className="flex items-center mt-3">
				<div className="w-40 h-14 mr-4 flex">
					<img src="/img/tcm.jpg" alt="" />
				</div>
			</div>
		</div>
	);
};

export default Footer;
