import { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Redirect } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import Footer from "../../components/Footer";
import {
	ActActiveUsers,
	Activity,
	ActivityFragment,
	ActivityStatus,
	OrderBy,
	useDeleteActivityActiveUserMutation,
	useDeleteActivityMutation,
	useGetActivitiesLazyQuery,
	useGetActivityActiveUserLazyQuery,
	UserTypeNames,
} from "../../generated/graphql";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalRenderAtom } from "../../state/modal.recoil";
import ActivityForm from "./ActivityForm";
import { userAtom } from "../../state/user.recoil";
import {
	PlusIcon,
	SearchIcon,
	SortAscendingIcon,
	SortDescendingIcon,
} from "@heroicons/react/solid";
import { loadingMessageAtom } from "../../state/loading.recoil";
import ActivityCard from "./ActivityCard";
import { useStates } from "../../hooks/useStates";
import { pushToOrderBys } from "../../utils/GraphQLUtils";
import { ONE_HOUR } from "../../constants/General";

export interface Pagination {
	pages: number;
	currentPage: number;
	onPageClick: (page: number) => void;
}
const PAGE_ITEM_COUNT = 10;
type State = {
	currentPage: number;
	orderBys: OrderBy[];
};
const Activities: React.FC = () => {
	const user = useRecoilValue(userAtom);
	const isAdmin = user?.typeUserType.name === UserTypeNames.Admin;
	const [redirectTo, setPath] = useState<string | null>(null);
	const [shouldExpandCardMap, setShouldExpandCardMap] = useState<{
		[index: number]: boolean | undefined;
	}>({});

	const [{ currentPage, orderBys }, setState] = useStates<State>({
		currentPage: 0,
		orderBys: [],
	});
	const [filter, setFilter] = useState("");
	const [activityStatus, setActivityStatus] = useState<ActivityStatus | undefined>();
	const [sortField, setSortField] = useState<keyof Activity>("createdAt");
	const [isDesc, setIsDesc] = useState<boolean>(true);
	const [otherActivityActiveUser, setOtherActivityActiveUser] = useState<ActActiveUsers[]>([]);

	const setLoadingMessage = useSetRecoilState(loadingMessageAtom);
	const setModalRender = useSetRecoilState(modalRenderAtom);

	const [getActivities, getActivitiesRes] = useGetActivitiesLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [getActivityActiveUsers, getActivityActiveUsersRes] = useGetActivityActiveUserLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [deleteActivityActiveUser] = useDeleteActivityActiveUserMutation();

	const [deleteActivityMutation] = useDeleteActivityMutation();

	const getActivitiesByPage = (page: number) => {
		getActivities({
			variables: {
				input: {
					pagination: {
						offset: page * PAGE_ITEM_COUNT,
						limit: PAGE_ITEM_COUNT,
					},
					orderBys,
					filter: {
						likeName: filter,
						companyId: !isAdmin ? user?.userCompanyUser?.company.id : undefined,
						status: !isAdmin ? ActivityStatus.Active : activityStatus || undefined,
					},
				},
			},
		});
	};

	const deleteActivity = (id: number) => {
		setLoadingMessage("Deleting File");
		deleteActivityMutation({
			variables: {
				activityId: id,
			},
		})
			.then(() => {
				resetActivities();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {
				setLoadingMessage(undefined);
				setModalRender(undefined);
			});
	};

	const resetActivities = () => {
		if (currentPage === 0) {
			getActivitiesByPage(0);
		} else {
			setState({ currentPage: 0 });
		}
	};

	const onSort = (field: any, isDesc: boolean) => {
		if (field) {
			setState({
				orderBys: pushToOrderBys(orderBys, field, isDesc),
			});
		}
	};

	const expandCard = (index: number) => {
		const shouldExpandCardMapTemp = {
			...shouldExpandCardMap,
		};
		shouldExpandCardMapTemp[index] = !shouldExpandCardMapTemp[index];
		setShouldExpandCardMap(shouldExpandCardMapTemp);
	};

	const activityActiveUsers = (activityId: number) => {
		if (otherActivityActiveUser.length === 0) {
			return [];
		}
		const filteredActivityUsers = otherActivityActiveUser.filter(
			(activeUsers) => activeUsers.activityId === activityId,
		);

		return filteredActivityUsers;
	};

	useEffect(() => {
		getActivitiesByPage(currentPage);
	}, [currentPage]);

	useEffect(() => {
		resetActivities();
	}, [filter, orderBys, activityStatus]);

	useEffect(() => {
		setState({
			orderBys: pushToOrderBys(orderBys, sortField, isDesc),
		});
	}, [sortField, isDesc]);

	useEffect(() => {
		if (getActivitiesRes.called) {
			if (getActivitiesRes.loading) {
				setLoadingMessage("Loading Activities");
			} else {
				setLoadingMessage(undefined);
			}
		}
	}, [getActivitiesRes.called, getActivitiesRes.loading, getActivitiesRes.data]);

	useEffect(() => {
		getActivityActiveUsers({
			variables: {
				companyId: user?.userCompanyUser?.company.id,
			},
		});
	}, []);

	useEffect(() => {
		//SET OTHER USERS RECOIL
		if (getActivityActiveUsersRes.data && !getActivityActiveUsersRes.loading) {
			const otherUsers = getActivityActiveUsersRes.data.getActivityActiveUsers.nodes.filter(
				(el) => {
					//skip if self
					if (el.user.id === user?.id) {
						return false;
					}
					//check if a date is far away
					const timeDiff = new Date().getTime() - new Date(el.updatedAt).getTime();
					if (timeDiff > ONE_HOUR) {
						deleteActivityActiveUser({
							variables: {
								ActivityActiveUserId: el.id,
							},
						});
						return false;
					}
					return el.user.id !== user?.id;
				},
			);
			if (otherUsers.length > 0) {
				setOtherActivityActiveUser(otherUsers as ActActiveUsers[]);
			}
		}

		return () => {
			setOtherActivityActiveUser([]);
		};
	}, [
		getActivityActiveUsersRes.data,
		getActivityActiveUsersRes.loading,
		user?.id,
		setOtherActivityActiveUser,
		deleteActivityActiveUser,
	]);

	// @TODO make this a Link
	if (redirectTo) {
		return <Redirect push to={redirectTo} />;
	}

	const activities = getActivitiesRes.data?.getActivities.nodes || [];
	const pages = (getActivitiesRes.data?.getActivities?.totalCount || 0) / PAGE_ITEM_COUNT;

	const pagination: Pagination = {
		pages,
		currentPage,
		onPageClick: (page: number) => {
			setState({
				currentPage: page,
			});
		},
	};

	return (
		<div className="container mx-auto p-3">
			<Header />
			<div className="my-10">
				<b>
					Welcome to the UniPro Foodservice / R3 Total Category Management - Companions RFP Tool!
				</b>
				<br />
				Please click on the desired activity below for more details:
			</div>

			{user!.typeUserType.name === UserTypeNames.Admin && (
				<button
					onClick={() =>
						setModalRender(
							<ActivityForm
								onSuccess={() => {
									resetActivities();
									setTimeout(() => {
										setModalRender(undefined);
									}, 1000);
								}}
							/>,
						)
					}
					className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center"
					type="submit"
				>
					<PlusIcon className="w-5 h-5 mr-1" />
					Add Activity
				</button>
			)}

			<div className="sm:flex sm:flex-wrap gap-5">
				<div className="w-80 my-3">
					<div className="text-primary text-sm font-bold">Search</div>
					<div className="relative">
						<input
							className="border border-gray-400 rounded focus:outline-none focus:border-primary h-10 pl-10 w-80"
							onChange={(e) => setFilter(e.target.value)}
							value={filter}
							placeholder="Activity Name"
						></input>
						<SearchIcon className="h-5 w-5 absolute left-2 top-3 text-gray-400" />
					</div>
				</div>

				<div className="w-80 my-3">
					<div className="text-primary text-sm  font-bold">Sort by</div>
					<div className="flex items-center gap-2">
						<select
							onChange={(e) => setSortField(e.target.value as keyof Activity)}
							value={sortField}
							className="block appearance-none w-full h-10 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
						>
							<option value={"name"}>Activity Name</option>
							<option value={"createdAt"}>Created Date</option>
							<option value={"activeAt"}>Active Date</option>
							<option value={"expiresAt"}>Expire Date</option>
						</select>
						<div
							className="flex justify-center items-center rounded-full cursor-pointer hover:bg-gray-200 h-8 w-8"
							onClick={() => setIsDesc(!isDesc)}
						>
							{isDesc ? (
								<SortAscendingIcon className="h-5 w-5 text-gray-400" />
							) : (
								<SortDescendingIcon className="h-5 w-5 text-gray-400" />
							)}
						</div>
					</div>
				</div>

				{isAdmin && (
					<div className="w-80 my-3">
						<div className="text-primary text-sm font-bold">Status</div>
						<select
							onChange={(e) => setActivityStatus(e.currentTarget.value as any)}
							value={activityStatus}
							className="block appearance-none w-full h-10 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
						>
							<option value={""}>All</option>
							<option value={ActivityStatus.Active}>Active</option>
							<option value={ActivityStatus.Pending}>Pending</option>
							<option value={ActivityStatus.Expired}>Expired</option>
						</select>
					</div>
				)}
			</div>

			{activities.map((activity, i) => {
				const shouldExpand = !!shouldExpandCardMap[i];

				return (
					<ActivityCard
						key={i}
						activity={activity}
						onReloadActivities={() => {
							resetActivities();
							setTimeout(() => {
								setModalRender(undefined);
							}, 1000);
						}}
						shouldExpand={shouldExpand}
						onClickCard={() => {
							if (shouldExpand) {
								setPath(PathName.ACTIVITY("/" + activity.id));
							}
						}}
						onClickExpand={() => expandCard(i)}
						onDeleteActivity={() => deleteActivity(activity.id)}
						activityActiveUsers={activityActiveUsers(activity.id)}
					/>
				);
			})}
			<div className="py-6 ">
				{pagination &&
					(() => {
						const paginationButtons: JSX.Element[] = [];
						for (let i = 0; i < pagination.pages; ++i) {
							paginationButtons.push(
								<button
									type="button"
									className="px-3 py-1 rounded-2xl"
									style={{
										backgroundColor: i === pagination.currentPage ? "#a29f9f" : "white",
										color: i === pagination.currentPage ? "white" : "gray",
									}}
									key={i}
									onClick={() => {
										pagination.onPageClick(i);
									}}
								>
									{i + 1}
								</button>,
							);
						}
						return paginationButtons;
					})()}
			</div>
			<Footer />
		</div>
	);
};

export default Activities;
