import { atom } from "recoil";

type BidSheetLastUpdated = {
	isUpdating: boolean;
	lastUpdatedDate?: Date;
	errorMsg?: JSX.Element;
};

type BidSheetCount = {
	errorCount?: number;
	warningCount?: number;
	completedPercent?: string;
};

export const bidSheetLastUpdatedAtom = atom<BidSheetLastUpdated>({
	key: "bidSheetLastUpdated",
	default: {
		isUpdating: false,
	},
});

export const bidSheetCountAtom = atom<BidSheetCount>({
	key: "bidSheetCount",
	default: {},
});
