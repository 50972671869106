import { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { LocalStorageKey } from "../constants/LocalStorageKey";
import { useGetUserLazyQuery, useLoginMutation } from "../generated/graphql";
import { userAtom } from "../state/user.recoil";

export const useLogin = () => {
	const [login, loginRes] = useLoginMutation();
	const [getUser, getUserRes] = useGetUserLazyQuery();
	const [user, setUser] = useRecoilState(userAtom);

	const isWaitingForGetUserLoading = useRef(false);

	useEffect(() => {
		if (!loginRes.loading) {
			if (loginRes.data) {
				const { token } = loginRes.data.login;
				localStorage.setItem(LocalStorageKey.TOKEN, token);
				isWaitingForGetUserLoading.current = true;
				getUser();
			}
		}
	}, [loginRes.loading, loginRes.data, getUser]);

	useEffect(() => {
		if (!getUserRes.loading) {
			if (getUserRes.data) {
				setUser(getUserRes.data.getUser);
			}
		} else {
			isWaitingForGetUserLoading.current = false;
		}
	}, [getUserRes.loading, getUserRes.data, setUser]);

	let loginError = loginRes.error || getUserRes.error;

	if (loginError) {
		// @TODO if the token is invalid then we have to clear it, if not then leave it
		// localStorage.removeItem(LocalStorageKey.TOKEN);
	}

	const isGettingUser =
		isWaitingForGetUserLoading.current || getUserRes.loading || (!!getUserRes.data && !user);

	const isActuallyLoggingIn = loginRes.loading || isGettingUser;

	return { login, isActuallyLoggingIn, loginError };
};

export default useLogin;
