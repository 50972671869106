import React, { useState } from "react";
import { PlusIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import Button from "../../components/Button";
import { useStates } from "../../hooks/useStates";
import { useEffect } from "react";
import {
	ActivitySurveyItem,
	ActivitySurveyItemInput,
	ActivitySurveyItemInputs,
	ActivitySurveyItemOptionInput,
	ActivitySurveyCompanyAnswer,
	MutationUpdateActivitySurveyItemsArgs,
	useAddActivitySurveyItemMutation,
	useUpdateActivitySurveyItemMutation,
	useUpdateActivitySurveyItemsMutation,
	useDeleteActivitySurveyItemMutation,
	useAddActivitySurveyItemOptionMutation,
	useUpdateActivitySurveyItemOptionMutation,
	useDeleteActivitySurveyItemOptionMutation,
	// useAddActivitySurveyCompanyMutation,
	useUpdateActivitySurveyCompanyAnswerMutation,
	//TODO MOVE SURVEY
	FileTypeNames,
	// useDeleteFileMutation,
	// useGetSurveyFileBySurveyItemLazyQuery,
	// useGetSurveyFileLazyQuery,
} from "../../generated/graphql";
//TODO MOVE SURVEY
import useFiles from "../../hooks/useFiles";
import Items from "./components/items";
import { EnumActivitySurveyItemType } from "./components/enum";
import "./surveys.scss";
import { BsCursorText, BsListUl, BsTextareaT } from "react-icons/bs";
import {
	MdDateRange,
	MdRadioButtonChecked,
	MdCheckBox,
	MdFileUpload,
	MdTextFields,
} from "react-icons/md";
import { TiSortNumerically } from "react-icons/ti";
import { IoMdArrowDropdown } from "react-icons/io";
import { SurveyTabsNames } from "./SurveyTabs";
import { modalRenderAtom } from "../../state/modal.recoil";
import { useSetRecoilState } from "recoil";
import { id } from "date-fns/locale";

interface Props {
	isAdmin: boolean;
	surveyId: number;
	surveyCompanyId?: number | null;
	activitySurveyItems?: ActivitySurveyItem[];
	activitySurveyItemAnswers?: ActivitySurveyCompanyAnswer[];
	// getActivity?: (options: any) => void;
	getActivity?: any;
	isPreview: boolean;
	updateAnswers?: () => void;
}
// type State = {
// 	preview: boolean;
// };

const Survey: React.FC<Props> = (props) => {
	const setModalRender = useSetRecoilState(modalRenderAtom);
	// const [{ preview }, setState] = useStates<State>({
	// 	preview: props.isAdmin ? false : true,
	// });
	const preview = props.isPreview;
	// Add btn open and close
	const [showOptions, setShowOptions] = useState(false);
	const [addActivitySurveyItem] = useAddActivitySurveyItemMutation();
	const [deleteActivitySurveyItem] = useDeleteActivitySurveyItemMutation();
	const [updateActivitySurveyItem] = useUpdateActivitySurveyItemMutation();
	const [updateActivitySurveyItems] = useUpdateActivitySurveyItemsMutation();
	const [addActivitySurveyItemOption] = useAddActivitySurveyItemOptionMutation();
	const [updateActivitySurveyItemOption] = useUpdateActivitySurveyItemOptionMutation();
	const [deleteActivitySurveyItemOption] = useDeleteActivitySurveyItemOptionMutation();
	const [updateActivitySurveyCompanyAnswer] = useUpdateActivitySurveyCompanyAnswerMutation();

	const addToActivitySurveyItemAdditionFunction = (
		activitySurveyItem: ActivitySurveyItem,
		itemType: EnumActivitySurveyItemType = 1,
	) => {
		props.surveyId &&
			addActivitySurveyItem({
				variables: {
					activitySurveyItemInput: {
						activitySurveyId: props.surveyId,
						section: activitySurveyItem.section,
						itemType: itemType,
						title: `${activitySurveyItem.title} addition`,
						description: "",
						sortOrder: activitySurveyItem.sortOrder,
						required: false,
						parentActivitySurveyItemId: activitySurveyItem.id,
					},
				},
			}).then(() => {
				props.getActivity();
			});
	};
	const addToActivitySurveyItemFunction = (itemType: EnumActivitySurveyItemType = 1) => {
		props.surveyId &&
			addActivitySurveyItem({
				variables: {
					activitySurveyItemInput: {
						activitySurveyId: props.surveyId,
						section: 1,
						itemType: itemType,
						title: "",
						description: "",
						sortOrder:
							props.activitySurveyItems && props.activitySurveyItems.length > 0
								? props.activitySurveyItems.length
								: 0,
						required: true,
					},
				},
			}).then(() => {
				props.getActivity();
			});
	};
	const updateActivitySurveyItemFunction = (
		id: number,
		activitySurveyItemInput: ActivitySurveyItemInput,
	) => {
		props.surveyId &&
			updateActivitySurveyItem({
				variables: {
					id: id,
					activitySurveyItemInput: activitySurveyItemInput,
				},
			}).then(() => {
				props.getActivity();
			});
	};

	const updateActivitySurveyItemsFunction = (
		activitySurveyItemInputs: Array<ActivitySurveyItemInputs> | ActivitySurveyItemInputs,
	) => {
		props.surveyId &&
			updateActivitySurveyItems({
				variables: {
					activitySurveyItemInputs: activitySurveyItemInputs,
				},
			}).then(() => {
				props.getActivity();
			});
	};

	const getMainArray = () => {
		return props.activitySurveyItems != undefined
			? props.activitySurveyItems.map((item) => {
					return {
						activitySurveyId: item.activitySurveyId,
						createdAt: item.createdAt,
						id: item.id,
						section: item.section,
						itemType: item.itemType,
						title: item.title,
						description: item.description,
						sortOrder: item.sortOrder,
						required: item.required,
						activitySurveyItemOptions:
							item.activitySurveyItemOptions && Array.from(item.activitySurveyItemOptions),
					};
			  })
			: [];
	};
	const updateSortOrderOnSortChange = (indexChange: number, direction?: number) => {
		let fixArray = getMainArray();
		if (direction == 0) {
			fixArray[indexChange].sortOrder = fixArray[indexChange].sortOrder - 1.5;
		} else {
			fixArray[indexChange].sortOrder = fixArray[indexChange].sortOrder + 1.5;
		}
		updateSortOrderSave(fixArray);
	};
	const updateSortOrderOnDelete = (removeIndex?: number, removeOptionIndex?: number) => {
		let fixArray = getMainArray();
		if (removeIndex && removeOptionIndex == null) {
			fixArray?.splice(removeIndex, 1);
		}
		if (removeIndex && removeOptionIndex) {
			let fixArrayOptions: any = [];
			if (
				fixArray &&
				fixArray.length > 0 &&
				fixArray[removeIndex].activitySurveyItemOptions &&
				fixArray[removeIndex].activitySurveyItemOptions != undefined &&
				fixArray[removeIndex].activitySurveyItemOptions &&
				fixArray[removeIndex].activitySurveyItemOptions!.length > 0
			) {
				fixArray[removeIndex].activitySurveyItemOptions?.map((item2) => {
					fixArrayOptions.push(item2);
				});
				fixArrayOptions && fixArrayOptions.splice(removeOptionIndex, 1);
				fixArray[removeIndex].activitySurveyItemOptions = fixArrayOptions;
			}
		}
		updateSortOrderSave(fixArray);
	};

	const updateSortOrderSave = (fixedArray: ActivitySurveyItem[]) => {
		let fixArraySorted = fixedArray.sort(function (a, b) {
			if (a.sortOrder < b.sortOrder) return -1;
			if (a.sortOrder > b.sortOrder) return 1;
			else return 0;
		});
		const mappedArray: ActivitySurveyItemInputs[] = fixArraySorted.map((item, index) => {
			let activitySurveyItemOptionsFixed: any = [];
			if (item.activitySurveyItemOptions != undefined) {
				activitySurveyItemOptionsFixed = item.activitySurveyItemOptions?.map(
					(itemOption, indexOption) => {
						return {
							id: itemOption.id,
							activitySurveyItemOption: {
								activitySurveyItemId: item.id,
								optionName: itemOption.optionName,
								optionValue: indexOption.toString(),
							},
						};
					},
				);
			}
			return {
				id: item.id,
				activitySurveyItemInputWithOptions: {
					activitySurveyId: props.surveyId,
					section: item.section,
					itemType: item.itemType,
					title: item.title,
					description: item.description,
					sortOrder: index,
					required: item.required,
					activitySurveyItemOptions:
						activitySurveyItemOptionsFixed?.length > 0 ? activitySurveyItemOptionsFixed : undefined,
				},
			};
		});
		updateActivitySurveyItemsFunction(mappedArray);
		props.getActivity();
	};
	const deleteActivitySurveyItemFunction = (id: number, index: number) => {
		deleteActivitySurveyItem({
			variables: {
				id: id,
			},
		}).then(() => {
			// replaces 1 element at index 4
			updateSortOrderOnDelete(index);
		});
	};

	const addToActivitySurveyItemOptionFunction = (
		activitySurveyItemOptionInput: ActivitySurveyItemOptionInput,
	) => {
		props.surveyId &&
			addActivitySurveyItemOption({
				variables: {
					activitySurveyItemOptionInput: activitySurveyItemOptionInput,
				},
			}).then(() => {
				props.getActivity();
			});
	};
	const updateActivitySurveyItemOptionFunction = (
		id: number,
		activitySurveyItemOptionInput: ActivitySurveyItemOptionInput,
	) => {
		props.surveyId &&
			updateActivitySurveyItemOption({
				variables: {
					id: id,
					activitySurveyItemOptionInput: activitySurveyItemOptionInput,
				},
			}).then(() => {
				props.getActivity();
			});
	};
	const deleteActivitySurveyItemOptionFunction = (
		id: number,
		index?: number,
		parentIndex?: number,
	) => {
		deleteActivitySurveyItemOption({
			variables: {
				id: id,
			},
		}).then(() => {
			updateSortOrderOnDelete(parentIndex, index);
			// props.getActivity();
		});
	};
	const updateActivitySurveyCompanyAnswerValueFunction = (
		id: number,
		updateValue: string,
		updateComment: string,
	) => {
		props.surveyCompanyId &&
			updateActivitySurveyCompanyAnswer({
				variables: {
					activitySurveyCompanyAnswerInput: {
						activitySurveyCompanyId: props.surveyCompanyId,
						activitySurveyItemId: id,
						answer: updateValue,
						answerComment: updateComment,
					},
				},
			}).then(() => {
				props.getActivity();
			});
	};

	const deleteItemPrompt = (id: number, index: number) => {
		setModalRender(
			<div className="w-85 flex flex-col justify-center items-center self-center mb-7">
				<div className="my-5">Are you sure you want to delete this Item?</div>
				<div className="flex gap-4">
					<button
						className="w-max px-4 py-1 rounded border border-gray-300 hover:bg-gray-400"
						onClick={() => setModalRender(undefined)}
					>
						No
					</button>
					<button
						className="w-max px-4 py-1 rounded bg-primary hover:bg-red-900 text-white"
						onClick={() => {
							deleteActivitySurveyItemFunction(id, index);
							setModalRender(undefined);
						}}
					>
						Yes
					</button>
				</div>
			</div>,
		);
	};

	const getValueForItem = (activitySurveyItem: ActivitySurveyItem) => {
		let activitySurveyItemAnswers: ActivitySurveyCompanyAnswer[] = props.activitySurveyItemAnswers
			? props.activitySurveyItemAnswers
			: [];
		const __FOUND = activitySurveyItemAnswers.find((answers, index) => {
			if (answers.activitySurveyItemId == activitySurveyItem.id) return true;
		});
		return __FOUND?.answer ?? "";
	};
	const getValueForItemComment = (activitySurveyItem: ActivitySurveyItem) => {
		let activitySurveyItemAnswers: ActivitySurveyCompanyAnswer[] = props.activitySurveyItemAnswers
			? props.activitySurveyItemAnswers
			: [];
		const __FOUND = activitySurveyItemAnswers.find((answers, index) => {
			if (answers.activitySurveyItemId == activitySurveyItem.id) return true;
		});
		return __FOUND?.answerComment ?? "";
	};
	const { addFile } = useFiles();

	const updateFileAndSurveyAnswer = async (
		file: Blob | null,
		activitySurveyItemId: number,
		activitySurveyItemInput: ActivitySurveyItemInput,
	) => {
		if (file) {
			addFile(file, FileTypeNames.Survey)
				.then((fileId) => {
					updatePropCompany(fileId, activitySurveyItemId, activitySurveyItemInput);
					// props.updateAnswers && props.updateAnswers();
				})
				.catch(() => {
					// @TODO error
				})
				.finally(() => {
					props.updateAnswers && props.updateAnswers();
				});
		}
	};

	const updatePropCompany = (
		fileId: number,
		activitySurveyItemId: number,
		activitySurveyItemInput: ActivitySurveyItemInput,
	) => {
		updateActivitySurveyCompanyAnswerValueFunction(activitySurveyItemId, fileId.toString(), "");
	};

	return (
		<div className="animate__animated animate__fadeInLeft animate__faster mt-5 survey-inn-cont">
			{props.isAdmin ? (
				<>
					<div className="survey-inner-cont">
						{props.activitySurveyItems &&
							props.activitySurveyItems.length > 0 &&
							props.activitySurveyItems.map((item, i) => (
								<Items
									key={`key-item-${item && item.id}`}
									keyIndex={i}
									preview={preview}
									surveyId={props.surveyId}
									activitySurveyItem={item}
									update={updateActivitySurveyItemFunction}
									delete={deleteItemPrompt}
									addOption={addToActivitySurveyItemOptionFunction}
									updateOption={updateActivitySurveyItemOptionFunction}
									deleteOption={deleteActivitySurveyItemOptionFunction}
									sortChange={updateSortOrderOnSortChange}
									addAddition={addToActivitySurveyItemAdditionFunction}
								/>
							))}
					</div>
				</>
			) : (
				<div className="survey-inner-cont">
					{props.activitySurveyItems &&
						props.activitySurveyItems.length > 0 &&
						props.activitySurveyItems.map((item, i) => (
							<Items
								key={`key-item-${item && item.id}`}
								keyIndex={i}
								preview={preview}
								surveyId={props.surveyId}
								activitySurveyItem={item}
								updateAnswer={updateActivitySurveyCompanyAnswerValueFunction}
								answer={getValueForItem(item)}
								answerComment={getValueForItemComment(item)}
								updateFile={updateFileAndSurveyAnswer}
								getValueForItem={getValueForItem}
								// getFileName={getSurveyFileName}
								// fileName={null}
							/>
						))}
				</div>
			)}
			{!preview && (
				<div className="add-options-cont">
					<Button onClick={() => setShowOptions(!showOptions)}>
						<PlusIcon className="w-5 h-5" />
						{showOptions ? "" : "Add New"}
					</Button>

					{showOptions && (
						<>
							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Textbox);
								}}
							>
								<BsCursorText className="w-5 h-5" />
								Text
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Textarea);
								}}
							>
								<BsTextareaT className="w-5 h-5" />
								Textarea
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Date);
								}}
							>
								<MdDateRange className="w-5 h-5" />
								Date
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Numeric);
								}}
							>
								<TiSortNumerically className="w-5 h-5" />
								Number
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Dropdown);
								}}
							>
								<IoMdArrowDropdown className="w-5 h-5" />
								Dropdown
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Radio);
								}}
							>
								<MdRadioButtonChecked className="w-5 h-5" />
								Radio
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Checkbox);
								}}
							>
								<MdCheckBox className="w-5 h-5" />
								Checkbox
							</Button>
							{/* @TODO need to figure out what is multiselect */}
							{/* <Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Multiselect);
								}}
							>
								<BsListUl className="w-5 h-5" />
								Multiselect
							</Button> */}

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.File_upload);
								}}
							>
								<MdFileUpload className="w-5 h-5" />
								File Upload
							</Button>

							<Button
								className="outline-group-btn"
								onClick={() => {
									addToActivitySurveyItemFunction(EnumActivitySurveyItemType.Label);
								}}
							>
								<MdTextFields className="w-5 h-5" />
								Label
							</Button>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default Survey;
