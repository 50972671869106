import React, { useState, useEffect, useRef } from "react";

export interface Column<T extends { [key: string]: any }> {
	name: string;
	field?: keyof T;
	formattedField?: (row: T, index: number) => JSX.Element;
	isSortable?: boolean;
	columnWidth?: string;
	hidden?: boolean;
}

export interface Pagination {
	pages: number;
	currentPage: number;
	onPageClick: (page: number) => void;
}

interface Props<T extends { [key: string]: any }> {
	className?: string;
	columns: Array<Column<T> | undefined>;
	rows: T[];
	onSort?: (field: keyof T | undefined, isDesc: boolean) => void;
	defaultSortField?: keyof T;
	defaultIsSortDesc?: boolean;
	extraRow?: JSX.Element;
	pagination?: Pagination;
	ascIcon?: JSX.Element;
	descIcon?: JSX.Element;
	render?: () => JSX.Element; // render this instead of tbody, good for when loading
}

const Table = <T extends { [key: string]: any }>({
	className,
	columns,
	rows,
	onSort,
	defaultSortField,
	defaultIsSortDesc,
	extraRow,
	pagination,
	ascIcon,
	descIcon,
	render,
}: Props<T>): React.FunctionComponentElement<Props<T>> => {
	const [sortField, setSortField] = useState<keyof T | undefined>(defaultSortField);
	const [isDesc, setIsDesc] = useState(defaultIsSortDesc || false);

	const canSort = useRef(false);

	const onColumnHeaderClick = (field: keyof T | undefined) => {
		setSortField(field);
		if (field !== sortField) {
			setIsDesc(defaultIsSortDesc || false);
		} else {
			setIsDesc(!isDesc);
		}
	};

	useEffect(() => {
		if (canSort.current) {
			if (onSort) {
				onSort(sortField, isDesc);
			}
		} else {
			canSort.current = true;
		}
	}, [sortField, isDesc]);

	return (
		<>
			<table className={className}>
				<thead>
					<tr>
						{columns.map((column, i) => {
							if (!column) {
								return undefined;
							}

							return (
								<th
									key={i}
									onClick={() => (column.isSortable ? onColumnHeaderClick(column.field) : null)}
									style={{ width: column.columnWidth !== undefined ? column.columnWidth : "auto" }}
									className={
										(column.isSortable ? "sortable " : "") + (column.hidden ? "hidden " : "")
									}
								>
									<div className="sorting-icons" style={{ display: "inline-flex" }}>
										{column.name}
										{sortField !== undefined && sortField === column.field
											? isDesc
												? descIcon
												: ascIcon
											: ""}
									</div>
								</th>
							);
						})}
					</tr>
				</thead>
				{!render && (
					<tbody>
						{rows.map((row, i) => {
							return (
								<tr key={i}>
									{columns.map((column, j) => {
										if (!column) {
											return undefined;
										}

										let value;
										if (column.formattedField) {
											value = column.formattedField(row, i);
										} else {
											value = row[column.field!];
										}
										return (
											<td key={j} className={column.hidden ? "hidden " : ""}>
												{value}
											</td>
										);
									})}
								</tr>
							);
						})}
						{extraRow}
					</tbody>
				)}
			</table>
			{render && render()}
			{!render && (
				<div className="py-6 flex sticky left-0">
					{pagination &&
						(() => {
							const paginationButtons: JSX.Element[] = [];
							for (let i = 0; i < pagination.pages; ++i) {
								paginationButtons.push(
									<div
										className="px-3 py-1 rounded-2xl cursor-pointer w-max"
										style={{
											backgroundColor: i === pagination.currentPage ? "#a29f9f" : "white",
											color: i === pagination.currentPage ? "white" : "gray",
										}}
										key={i}
										onClick={() => {
											pagination.onPageClick(i);
										}}
									>
										{i + 1}
									</div>,
								);
							}
							return paginationButtons;
						})()}
				</div>
			)}
		</>
	);
};

export default Table;
