import { useState } from "react";
import { useRecoilValue } from "recoil";
import env from "../env";
import { FileTypeNames } from "../generated/graphql";
import { activityAtom } from "../state/activityRecoil";
import BaseRequestInfo from "../types/BaseRequestInfo";
import useAuthAxios from "./useAuthAxios";

export const useFiles = () => {
	const authAxios = useAuthAxios();
	const [downloadFileRequestInfo, setDownloadFileRequestInfo] = useState<BaseRequestInfo>({
		isRequesting: false,
	});
	const downloadFile = async (fileId: number, fileName?: string | null) => {
		setDownloadFileRequestInfo({
			isRequesting: true,
		});

		const url = env.restAPIUrl + "/files/download/" + fileId;
		const res = await authAxios.get(url, {
			responseType: "arraybuffer",
		});

		const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
		const link = document.createElement("a");
		link.href = downloadUrl;
		link.setAttribute("download", fileName || "file");
		document.body.appendChild(link);
		link.click();

		setDownloadFileRequestInfo({
			isRequesting: false,
		});
	};

	const activity = useRecoilValue(activityAtom);

	const [downloadActivityFilesRequestInfo, setDownloadActivityFilesRequestInfo] =
		useState<BaseRequestInfo>({
			isRequesting: false,
		});

	const downloadActivityFiles = async (type: FileTypeNames) => {
		setDownloadActivityFilesRequestInfo({
			isRequesting: true,
		});

		const url =
			env.restAPIUrl + "/files/download-activity-files/" + activity!.id + "/?type=" + type;
		const res = await authAxios.get(url, {
			responseType: "arraybuffer",
		});

		const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
		const link = document.createElement("a");
		link.href = downloadUrl;
		link.setAttribute("download", activity!.name + " files.zip");
		document.body.appendChild(link);
		link.click();

		setDownloadActivityFilesRequestInfo({
			isRequesting: false,
		});
	};

	const [addFileRequestInfo, setAddFileRequestInfo] = useState<BaseRequestInfo>({
		isRequesting: false,
	});
	const addFile = async (blob: Blob, type: FileTypeNames) => {
		setAddFileRequestInfo({
			isRequesting: true,
		});

		const url = env.restAPIUrl + "/files/upload";
		const form = new FormData();
		form.append("file", blob);
		form.append("activityId", activity?.id + "");
		form.append("type", type);
		const result = await authAxios.post(url, form);

		setAddFileRequestInfo({
			isRequesting: false,
		});
		if (result.data.fileId) {
			return result.data.fileId;
		}
	};

	return {
		downloadFileRequestInfo,
		downloadFile,
		downloadActivityFilesRequestInfo,
		downloadActivityFiles,
		addFileRequestInfo,
		addFile,
	};
};

export default useFiles;
