import { FormEvent } from "react";
import { useFormState } from "../../hooks/useFormState";
import { useResetPasswordMutation, useResetTokenCheckMutation } from "../../generated/graphql";
import useQueryParams from "../../hooks/useQueryParams";
import { Link, Redirect } from "react-router-dom";
import { PathName } from "../../constants/PathName";
import { useStates } from "../../hooks/useStates";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";

type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
	hasReset: boolean;
	isValidToken: boolean;
	checkedToken: boolean;
};

const ResetPassword: React.FC = () => {
	const query = useQueryParams();
	const token = query.get("reset_password_token");
	const [{ isStatusMessageError, statusMessage, hasReset, isValidToken, checkedToken }, setState] = useStates<State>({
		isStatusMessageError: false,
		hasReset: false,
		isValidToken: false,
		checkedToken: false
	});
	const [{ password, confirmPassword }, setFormState] = useFormState({
		password: "",
		confirmPassword: "",
	});
	// MutationResetTokenCheckArgs
	const [resetTokenCheck, resetTokenCheckRes] = useResetTokenCheckMutation();
	const [resetPassword, resetPasswordRes] = useResetPasswordMutation();

	if (!token) {
		return <Redirect to={PathName.DEFAULT} />;
	}

	if (!checkedToken) {
		setState({
			checkedToken: true,
		});
		resetTokenCheck({
			variables: {
				token,
			},
		})
			.then(() => {
				setState({
					isValidToken: true,
				});
			})
			.catch(() => {
				setState({
					isValidToken: false,
				});
			});
	}
	const submit = (e: FormEvent) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setState({
				isStatusMessageError: true,
				statusMessage: "Password must match",
			});

			return;
		}

		setState({
			statusMessage: undefined,
		});
		resetPassword({
			variables: {
				resetPasswordInput: {
					password,
					token,
				},
			},
		})
			.then(() => {
				setState({
					isStatusMessageError: false,
					statusMessage: "Password reset successful",
					hasReset: true,
				});
			})
			.catch(() => {
				setState({
					isStatusMessageError: true,
					statusMessage: "Failed to reset password",
				});
			});
	};

	return (
			<div className="container flex flex-col mx-auto items-center">
				<div className="flex w-full max-w-lg my-14 gap-5">
					<div className="w-full">
						<img src="/img/unipro.svg" alt="" />
					</div>
					<div className="w-full">
						<img src="/img/tcm.jpg" alt="" />
					</div>
				</div>				
				{isValidToken ? (
				<div className="flex flex-col p-4 border border-gray-400 rounded max-w-md mx-auto w-full">
					<form className="flex flex-col" onSubmit={(e) => submit(e)}>
						{/* Show fields */}
						{!hasReset && (
							<>
								<label className="text-gray-400">New Password</label>
								<div className="relative w-full mb-3">
									<input
										type="password"
										className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-1 w-full"
										onChange={(e) => setFormState({ password: e.target.value })}
										value={password}
									></input>
								</div>

								<label className="text-gray-400">Confirm Password</label>
								<div className="relative w-full mb-3">
									<input
										type="password"
										className="border border-gray-400 rounded focus:outline-none focus:border-primary h-8 pl-1 w-full"
										onChange={(e) => setFormState({ confirmPassword: e.target.value })}
										value={confirmPassword}
									></input>
								</div>
							</>
						)}

						{/* Status messages */}
						{hasReset && (
							<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
								<CheckCircleIcon className="h-5 w-5" />
								{statusMessage}
							</div>
						)}

						{isStatusMessageError && (
							<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
								<ExclamationIcon className="h-5 w-5" />
								{statusMessage}
							</div>
						)}

						{/* Buttons */}
						{hasReset ? (
							<Link
								to={PathName.LOGIN}
								className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center"
							>
								<div className="text-white ">Go to login</div>
							</Link>
						) : (
							<button
								className="flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center"
								type="submit"
							>
								{resetPasswordRes.loading && (
									<div className="rounded-2xl border-t-4 border-b-4 border-white animate-spin h-5 w-5 mr-3"></div>
								)}
								Create new password
							</button>
						)}
					</form>
				</div>
				) : 
				<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
					<ExclamationIcon className="h-5 w-5" />
					Not a valid token, please request token again. If this keeps happening please contact us at R3TCM@R3Redistribution.com
				</div>}
			</div>
	);
};

export default ResetPassword;
