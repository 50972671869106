export enum EnumActivitySurveyItemType {
	None,
	Textbox,
	Textarea,
	Dropdown,
	Checkbox,
	Multiselect,
	Numeric,
	Date,
	Radio,
	File_upload,
	Label,
}
