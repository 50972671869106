import { useMemo, useState } from "react";
import Header from "../components/Header";
import { Redirect } from "react-router-dom";
import Footer from "../components/Footer";
import {
	UserGroupIcon,
	PresentationChartBarIcon,
	CubeTransparentIcon,
} from "@heroicons/react/outline";
import Card from "../components/Card";
import { PathName } from "../constants/PathName";

const AdminDashboard: React.FC = () => {
	const [redirectTo, setPath] = useState<string | null>(null);

	if (redirectTo) {
		return <Redirect push to={redirectTo} />;
	}

	return (
		<div className="container mx-auto p-3">
			<Header />

			<div className="p-10 my-10 bg-priMoreLight rounded-lg t admin-welcome">
				<b className="text-4xl text-gray-500">Welcome!</b>
				<br />
				<div className="text-gray-500">What would you like to manage?</div>
			</div>
			<div className="grid grid-cols-3 gap-7">
				<Card
					className="rfp-item flex flex-col justify-center items-center"
					onClick={() => setPath(PathName.USERS)}
				>
					<UserGroupIcon className="h-40 w-40 text-gray-400" />
					<div className="text-lg font-bold px-3 mb-3 text-primary">Users</div>
					{/* <UserGroupIcon className="h-5 w-5 absolute left-2 top-1.5 text-gray-400" /> */}

					{/* <div className="border-b-2 border-gray-300  "></div> */}
					<br />
				</Card>
				<Card
					className="rfp-item flex flex-col justify-center items-center"
					onClick={() => setPath(PathName.COMPANIES)}
				>
					<PresentationChartBarIcon className="h-40 w-40 text-gray-400" />

					<div className="text-lg font-bold px-3 mb-3 text-primary">Companies</div>
					{/* <div className="border-b-2 border-gray-300  "></div> */}
					<br />
				</Card>
				<Card
					className="rfp-item flex flex-col justify-center items-center"
					onClick={() => setPath(PathName.ACTIVITIES)}
				>
					<CubeTransparentIcon className="h-40 w-40 text-gray-400" />
					<div className="text-lg font-bold px-3 mb-3 text-primary">Activities</div>
					{/* <div className="border-b-2 border-gray-300  "></div> */}
					<br />
				</Card>
			</div>

			{/* {activities.map((activity, i) => {
				return (
					<Card
						className="rfp-item animate__animated animate__fadeInLeft animate__faster"
						onClick={() => setPath(PathName.ACTIVITY("/" + activity.id))}
					>
						<div className="text-gray-500 text-lg font-bold px-3 mb-3">{activity.name}</div>
						<div className="py-1" dangerouslySetInnerHTML={createMarkup(activity.description!)} />
					</Card>
				);
			})} */}
			<Footer />
		</div>
	);
};

export default AdminDashboard;
