import React from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { TrashIcon } from "@heroicons/react/solid";
import Button from "../../../components/Button";
import {
	ActivitySurveyItem,
	FileTypeNames,
	useGetSurveyFileLazyQuery,
	useDeleteFileMutation,
} from "../../../generated/graphql";
import { useStates } from "../../../hooks/useStates";
import { ActivitySurveyItemInput } from "../../../generated/graphql";
import { EnumActivitySurveyItemType } from "./enum";
import FileInput from "./FileInput";
import fi from "date-fns/esm/locale/fi/index.js";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";
import { MdDateRange, MdFileUpload } from "react-icons/md";
interface Props {
	preview: boolean;
	surveyId: number;
	keyIndex: number;
	inputType: string;
	answer?: string;
	activitySurveyItem: ActivitySurveyItem;
	delete: (id: number, index: number) => void;
	update: (id: number, activitySurveyItemInput: ActivitySurveyItemInput) => void;
	updateFile: (
		file: Blob | null,
		activitySurveyItemId: number,
		activitySurveyItemInput: ActivitySurveyItemInput,
	) => void;
	updateAnswer: (id: number, updateValue: string, updateComment: string) => void;
	sortChange: (indexChange: number, direction?: number) => void;
}

type State = {
	title: string;
	description: string;
	saveValue: string;
	required: boolean;
	file: Blob | null;
	fileId: number;
};
const SurveyItemFileUpload: React.FC<Props> = (props) => {
	const [{ title, description, required, saveValue, file, fileId }, setState] = useStates<State>({
		title: props.activitySurveyItem.title,
		description: props.activitySurveyItem.description ?? "",
		required: props.activitySurveyItem.required,
		saveValue: "",
		file: null,
		fileId: 0,
	});
	useEffect(() => {
		props.answer &&
			setState({
				fileId: parseInt(props.answer),
			});
	}, [props.answer]);

	const onChange = (val: string) => {
		setState({
			title: val,
		});
	};
	const onChange2 = (val: string) => {
		setState({
			description: val,
		});
	};
	const onChangeRequired = () => {
		setState({
			required: !required,
		});
	};
	const onBlur = () => {
		updateItem();
	};

	const updateItem = () => {
		props.update(props.activitySurveyItem.id, {
			activitySurveyId: props.surveyId,
			section: props.activitySurveyItem.section,
			itemType: props.activitySurveyItem.itemType,
			title: title,
			description: description,
			sortOrder: props.activitySurveyItem.sortOrder,
			required: required,
		});
	};

	const uploadFile = async () => {
		if (file) {
			props.updateFile(file, props.activitySurveyItem.id, {
				activitySurveyId: props.surveyId,
				section: props.activitySurveyItem.section,
				itemType: props.activitySurveyItem.itemType,
				title: "",
				description: "",
				sortOrder: props.activitySurveyItem.sortOrder,
				required: props.activitySurveyItem.required,
			});
		}
	};

	const [getSurveyFile, getSurveyFileRes] = useGetSurveyFileLazyQuery({
		variables: {
			id: fileId,
		},
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		if (!getSurveyFileRes.loading) {
			const dataRes = getSurveyFileRes.data?.getSurveyFile;
			if (dataRes) {
				dataRes.name &&
					setState({
						saveValue: dataRes.name,
					});
			}
		}
	}, [getSurveyFileRes.loading, getSurveyFileRes.data, getSurveyFileRes.called]);

	useEffect(() => {
		if (props.answer != null || props.answer != "") {
			getSurveyFile();
		}
	}, [props.answer]);

	const [deleteFileMutation] = useDeleteFileMutation();
	const deleteFile = (id: number) => {
		deleteFileMutation({
			variables: {
				id,
			},
		})
			.then(() => {
				setState({
					fileId: 0,
				});
			})
			.then(() => {
				getSurveyFile();
			})
			.catch(() => {
				// @TODO error
			})
			.finally(() => {});
	};

	return (
		<>
			{props.preview ? (
				<div className="survey-card flex gap-3 w-full">
					<div className="number-tag h-10">{props.keyIndex + 1}</div>
					<div className="prev-question">
						<div className="question">{props.activitySurveyItem.title}</div>
						<div className="text-sm text-gray-400">{props.activitySurveyItem.description}</div>
						<>
							{fileId === 0 ? (
								<div className="my-5 flex gap-6">
									<FileInput
										onChange={(e) =>
											setState({
												file: e.target.files![0],
											})
										}
									></FileInput>
									<button
										className="bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800"
										onClick={() => uploadFile()}
									>
										Upload
									</button>
								</div>
							) : (
								<div>
									{saveValue}
									<button
										className="bg-primary text-white rounded w-max px-3 py-1.5 self-end hover:bg-red-800"
										onClick={() => deleteFile(fileId)}
									>
										Delete
									</button>
								</div>
							)}
						</>
					</div>
				</div>
			) : (
				<div className="survey-card">
					<div className="question-header">
						<div className="flex items-center">
							<div className="number-tag-type">{props.keyIndex + 1}</div>
							<div className="question-type-tag">
								{props.activitySurveyItem.itemType === EnumActivitySurveyItemType.File_upload && (
									<MdFileUpload className="w-5 h-5 text-gray-600" />
								)}
								<div className="text-gray-600 pl-2">{props.inputType}</div>
							</div>
						</div>

						<div className="flex gap-3">
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 1)}
							>
								<HiOutlineArrowDown className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-gray-600"
								onClick={() => props.sortChange(props.keyIndex, 0)}
							>
								<HiOutlineArrowUp className="w-5 h-5" />
							</div>
							<div
								className="question-top-options text-primary"
								onClick={() => {
									props.delete(props.activitySurveyItem.id, props.keyIndex);
								}}
							>
								<TrashIcon className="w-5 h-5" />
							</div>
						</div>
					</div>

					<div className="">
						<input
							onChange={(e) => onChange(e.target.value)}
							onBlur={(e) => onBlur()}
							type="Text"
							placeholder="What's your question?"
							value={title}
						></input>

						<textarea
							onChange={(e) => onChange2(e.target.value)}
							onBlur={(e) => onBlur()}
							placeholder="Describe if necessary"
							value={description}
							rows={2}
						></textarea>
					</div>

					<br />
					<div className="question-footer">
						<div className="flex mt-4">
							<label className="switch switch-left-right">
								<input
									className="switch-input"
									type="checkbox"
									checked={required}
									onChange={(e) => onChangeRequired()}
									onBlur={(e) => onBlur()}
								/>
								<span className="switch-label" data-on="" data-off=""></span>
								<span className="switch-handle"></span>
							</label>
							<div> Required</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyItemFileUpload;
