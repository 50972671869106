import { CubeIcon, TableIcon } from "@heroicons/react/solid";
import { useState } from "react";
import ActivityBidsheet from "./ActivityBidsheet";
import ActivityProducts from "./ActivityProducts";

enum Tabs {
	Products = "products",
	Bidsheets = "bidsheets",
}

const ActivityTabs = () => {
	//state hooks
	const [tabSelected, setTabSelected] = useState(Tabs.Products);

	return (
		<div className={tabSelected + " container mt-9"}>
			<div className="flex border-b border-gray-300 mb-10">
				<div
					className={(tabSelected === Tabs.Products) + " tab "}
					onClick={() => setTabSelected(Tabs.Products)}
				>
					<CubeIcon className="w-5 h-5 mr-1" /> Setup
				</div>
				<div
					className={(tabSelected === Tabs.Bidsheets) + " tab "}
					onClick={() => setTabSelected(Tabs.Bidsheets)}
				>
					<TableIcon className="w-5 h-5 mr-1" /> Results
				</div>
			</div>
			{tabSelected === Tabs.Products ? <ActivityProducts /> : <ActivityBidsheet />}
		</div>
	);
};

export default ActivityTabs;
