import React, { InputHTMLAttributes } from "react";
import { useStates } from "../../hooks/useStates";
import "./inputSearch.scss";

export interface InputSearchResult<TValue> {
	value: TValue;
	resultLabel: string | JSX.Element; // label for each result
	selectedLabel: string; // when you click the result, the input becomes this value
	isNotSelectable?: boolean;
}

interface Props<TSearchResultValue> extends InputHTMLAttributes<HTMLInputElement> {
	dataTestId?: string;
	isDisabled?: boolean;
	searchResults: InputSearchResult<TSearchResultValue>[];
	onSelectSearchResult?: (searchResult: InputSearchResult<TSearchResultValue>) => void;
}

type State = {
	isFocused: boolean;
};

const InputSearch = <T extends any>({
	dataTestId,
	isDisabled,
	searchResults,
	onSelectSearchResult,
	onFocus,
	onBlur,
	...inputProps
}: Props<T>) => {
	const [state, setState] = useStates<State>({
		isFocused: false,
	});

	const shouldShowSearchOptions = () => {
		return !isDisabled && searchResults.length !== 0 && state.isFocused;
	};
	const createResultDivs = () => {
		const resultDivs: JSX.Element[] = [];

		for (let i = 0; i < Math.min(searchResults.length, 10); ++i) {
			const row = searchResults[i];
			resultDivs.push(
				<div
					data-testid={`${dataTestId}.${row.value}`}
					className={"option " + (typeof row.resultLabel === "string" ? "label" : "")}
					onMouseDown={() => (row.isNotSelectable ? undefined : onSelectSearchResult?.(row))}
					key={i}
				>
					{row.resultLabel}
				</div>,
			);
		}
		return resultDivs;
	};

	return (
		<>
			<input
				{...inputProps}
				disabled={isDisabled}
				onFocus={(e) => {
					setState({
						isFocused: true,
					});
					onFocus?.(e);
				}}
				onBlur={(e) => {
					setState({
						isFocused: false,
					});
					onBlur?.(e);
				}}
			/>
			{shouldShowSearchOptions() && (
				<div className="items-cont">
					<div className={"items-sub-cont has-content"}>{createResultDivs()}</div>
				</div>
			)}
		</>
	);
};

export default InputSearch;
