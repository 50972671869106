import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import { FormEvent } from "react";
import { useRecoilState } from "recoil";
import {
	CompanyUserFragment,
	CompanyUserInput,
	useAddCompanyUserMutation,
	useGetCompaniesQuery,
	useUpdateCompanyUserMutation,
} from "../../generated/graphql";
import { useFormState } from "../../hooks/useFormState";
import { useStates } from "../../hooks/useStates";
import { loadingMessageAtom } from "../../state/loading.recoil";

export interface Props {
	companyUser?: CompanyUserFragment;
	onSuccess: () => void;
}
type State = {
	isStatusMessageError: boolean;
	statusMessage?: string;
};
type FormState = CompanyUserInput;

const UserForm: React.FC<Props> = ({ companyUser, onSuccess }) => {
	const [{ isStatusMessageError, statusMessage }, setState] = useStates<State>({
		isStatusMessageError: false,
	});
	const [formState, setFormState] = useFormState<FormState>({
		title: companyUser?.title || "",
		name: companyUser?.name || "",
		email: companyUser?.user.username || "",
		companyId: companyUser?.company.id || 0,
	});

	const [loadingMessage, setLoadingMessage] = useRecoilState(loadingMessageAtom);

	const getCompaniesRes = useGetCompaniesQuery({
		variables: {
			getCompaniesInput: {
				orderBys: [
					{
						by: "name",
						isDesc: false,
					},
				],
			},
		},
	});
	const [addCompanyUser] = useAddCompanyUserMutation();
	const [updateCompanyUser] = useUpdateCompanyUserMutation();

	const submit = (e: FormEvent) => {
		e.preventDefault();

		setState({
			isStatusMessageError: false,
			statusMessage: undefined,
		});

		if (companyUser) {
			setLoadingMessage("Updating User");
			updateCompanyUser({
				variables: {
					companyUserId: companyUser.id,
					updateCompanyUserInput: {
						...{
							...formState,
						},
					},
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "User updated successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to update user",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		} else {
			setLoadingMessage("Adding User");
			addCompanyUser({
				variables: {
					companyUserInput: formState,
				},
			})
				.then(() => {
					setState({
						isStatusMessageError: false,
						statusMessage: "User added successfully",
					});
					onSuccess();
				})
				.catch(() => {
					setState({
						isStatusMessageError: true,
						statusMessage: "Failed to add user",
					});
				})
				.finally(() => {
					setLoadingMessage(undefined);
				});
		}
	};

	const companies = getCompaniesRes?.data?.getCompanies?.nodes || [];

	return (
		<div className="users-add-user-modal">
			<div className="font-bold my-5">
				{companyUser ? "Update user" : "Complete form to add a new user"}
			</div>
			<form className="flex flex-col" onSubmit={(e) => submit(e)}>
				<label className="text-gray-400 text-left">Position </label>
				<div className="relative w-full mb-3">
					<input
						onChange={(e) => setFormState({ title: e.target.value })}
						value={formState.title || ""}
						required={true}
					></input>
				</div>

				<label className="text-gray-400 text-left">Name </label>
				<div className="relative w-full mb-3">
					<input
						onChange={(e) => setFormState({ name: e.target.value })}
						value={formState.name}
						required={true}
					></input>
				</div>

				<label className="text-gray-400 text-left">Email </label>
				<div className="relative w-full mb-3">
					<input
						disabled={!!companyUser}
						onChange={(e) => setFormState({ email: e.target.value })}
						value={formState.email}
						required={true}
					></input>
				</div>
				<div className="h-4"></div>
				<div className="inline-block relative ">
					<select
						onChange={(e) => setFormState({ companyId: Number(e.target.value) })}
						value={formState.companyId}
						required
						className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
					>
						{formState.companyId === 0 && <option>Choose company name</option>}
						{companies.map((company, i) => {
							return (
								<option key={i} value={company.id}>
									{company.name + " - (" + company.id + ")"}
								</option>
							);
						})}
					</select>
				</div>
				<div className="h-6"></div>
				{/* Status messages */}
				{statusMessage &&
					(isStatusMessageError ? (
						<div className="flex items-center gap-1 border-2 border-red-600 bg-red-400 rounded text-white py-1 px-1 my-3">
							<ExclamationIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					) : (
						<div className="flex items-center gap-1 border-2 border-green-600 bg-green-400 rounded text-white py-1 px-1 my-3">
							<CheckCircleIcon className="h-5 w-5" />
							{statusMessage}
						</div>
					))}

				<button
					className={
						"flex bg-primary text-white rounded w-max px-3 py-1.5 self-end items-center mt-10 hover:bg-red-800" +
						(loadingMessage || (statusMessage && !isStatusMessageError)
							? "pointer-events-none bg-opacity-70"
							: "")
					}
					type="submit"
				>
					{companyUser ? "Update User" : "Add User"}
				</button>
			</form>
		</div>
	);
};

export default UserForm;
