import React from "react";
import { TrashIcon } from "@heroicons/react/outline";
import { ActivitySurveyItem } from "../../../generated/graphql";
import { BiQuestionMark } from "react-icons/bi";

interface Props {
	preview: boolean;
	surveyId: number;
	keyIndex: number;
	activitySurveyItem: ActivitySurveyItem;
	delete: (id: number, index: number) => void;
}

const SurveyItemMissing: React.FC<Props> = (props) => {
	return (
		<>
			{props.preview ? (
				<>
					<div></div>
				</>
			) : (
				<div className="survey-card">
					<div className="question-header">
						<div className="flex items-center">
							<div className="label-type-tag">
								<BiQuestionMark className="w-5 h-5 text-gray-600" />

								<div className="text-gray-600 pl-2">Missing Option</div>
							</div>
						</div>

						<div
							className="question-top-options"
							onClick={() => {
								props.delete(props.activitySurveyItem.id, props.keyIndex);
							}}
						>
							<TrashIcon className="w-5 h-5 text-primary" />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SurveyItemMissing;
